import { CardMessage, CometChatMessageEvents, CometChatUIKitConstants, CustomInteractiveMessage, FormMessage } from "@cometchat/uikit-resources";

import { ChatConfigurator } from "../Shared/Framework/ChatConfigurator";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { Subscription } from 'rxjs';

export class MessageListManager {
    messagesRequest: CometChat.MessagesRequest | null = null;
    static groupListenerId: string = "group_" + new Date().getTime();
    static callListenerId: string = "call_" + new Date().getTime();
    static connectionListenerId: string = "MessageList_connection_" + String(Date.now());
    static onTextMessageReceived:Subscription;
    static onMediaMessageReceived:Subscription;
    static onCustomMessageReceived:Subscription;
    static onFormMessageReceived:Subscription;
    static onCardMessageReceived:Subscription;
    static onCustomInteractiveMessageReceived:Subscription;
    static onMessagesDelivered:Subscription;
    static onMessagesRead:Subscription;
    static onMessageDeleted:Subscription;
    static onMessageEdited:Subscription;
    static onTransientMessageReceived:Subscription;
    static onInteractionGoalCompleted: Subscription;


    constructor(messagesRequestBuilder?: CometChat.MessagesRequestBuilder, user?: CometChat.User, group?: CometChat.Group, messageId?: number) {
        if (messagesRequestBuilder) {
            this.messagesRequest = messagesRequestBuilder.build();
            if (messageId) {
                this.messagesRequest = messagesRequestBuilder.setMessageId(messageId).build();
            }
        } else {
            if (messageId) {
                if (user) {
                    this.messagesRequest = new CometChat.MessagesRequestBuilder()
                        .setUID(user.getUid())
                        .setTypes(ChatConfigurator.dataSource.getAllMessageTypes())
                        .setCategories(ChatConfigurator.dataSource.getAllMessageCategories())
                        .hideReplies(true)
                        .setMessageId(messageId)
                        .setLimit(30)
                        .build();
                }
                if (group) {
                    this.messagesRequest = new CometChat.MessagesRequestBuilder()
                        .setGUID(group.getGuid())
                        .setTypes(ChatConfigurator.dataSource.getAllMessageTypes())
                        .setCategories(ChatConfigurator.dataSource.getAllMessageCategories())
                        .hideReplies(true)
                        .setMessageId(messageId)
                        .setLimit(30)
                        .build();
                }
            } else {
                if (user) {
                    this.messagesRequest = new CometChat.MessagesRequestBuilder()
                        .setUID(user.getUid())
                        .setTypes(ChatConfigurator.dataSource.getAllMessageTypes())
                        .setCategories(ChatConfigurator.dataSource.getAllMessageCategories())
                        .hideReplies(true)
                        .setLimit(30)
                        .build();
                }
                if (group) {
                    this.messagesRequest = new CometChat.MessagesRequestBuilder()
                        .setGUID(group.getGuid())
                        .setTypes(ChatConfigurator.dataSource.getAllMessageTypes())
                        .setCategories(ChatConfigurator.dataSource.getAllMessageCategories())
                        .hideReplies(true)
                        .setLimit(30)
                        .build();
                }
            }
        }
    }

    fetchNextMessages = () => {
        return this.messagesRequest?.fetchNext();
    };

    fetchPreviousMessages = () => {
        return this.messagesRequest?.fetchPrevious();
    }

    static attachListeners = (callback: any) => {

        this.onTextMessageReceived = CometChatMessageEvents.onTextMessageReceived.subscribe((textMessage: CometChat.TextMessage) => {
            callback(CometChatUIKitConstants.messages.TEXT_MESSAGE_RECEIVED, textMessage);
        });
        this.onMediaMessageReceived = CometChatMessageEvents.onMediaMessageReceived.subscribe((mediaMessage: CometChat.MediaMessage) => {
            callback(CometChatUIKitConstants.messages.MEDIA_MESSAGE_RECEIVED, mediaMessage);
        });
        this.onCustomMessageReceived = CometChatMessageEvents.onCustomMessageReceived.subscribe((customMessage: CometChat.CustomMessage) => {
            callback(CometChatUIKitConstants.messages.CUSTOM_MESSAGE_RECEIVED, customMessage);
        });
        this.onFormMessageReceived = CometChatMessageEvents.onFormMessageReceived.subscribe((formMessage: FormMessage) => {
            callback(CometChatUIKitConstants.messages.INTERACTIVE_MESSAGE_RECEIVED, formMessage);
        });
        this.onCardMessageReceived = CometChatMessageEvents.onCardMessageReceived.subscribe((cardMessage: CardMessage) => {
            callback(CometChatUIKitConstants.messages.INTERACTIVE_MESSAGE_RECEIVED, cardMessage);
        });
        this.onCustomInteractiveMessageReceived = CometChatMessageEvents.onCustomInteractiveMessageReceived.subscribe((customInteractiveMessage: CustomInteractiveMessage) => {
            callback(CometChatUIKitConstants.messages.INTERACTIVE_MESSAGE_RECEIVED, customInteractiveMessage);
        });
        this.onMessagesDelivered = CometChatMessageEvents.onMessagesDelivered.subscribe((messageReceipt: CometChat.MessageReceipt) => {
            callback(CometChatUIKitConstants.messages.MESSAGE_DELIVERED, messageReceipt);
        });
        this.onMessagesRead = CometChatMessageEvents.onMessagesRead.subscribe((messageReceipt: CometChat.MessageReceipt) => {
            callback(CometChatUIKitConstants.messages.MESSAGE_READ, messageReceipt);
        });
        this.onMessageDeleted = CometChatMessageEvents.onMessageDeleted.subscribe((deletedMessage: CometChat.BaseMessage) => {
            callback(CometChatUIKitConstants.messages.MESSAGE_DELETED, deletedMessage);
        });
        this.onMessageEdited = CometChatMessageEvents.onMessageEdited.subscribe((editedMessage: CometChat.BaseMessage) => {
            callback(CometChatUIKitConstants.messages.MESSAGE_EDITED, editedMessage);
        });
        this.onTransientMessageReceived = CometChatMessageEvents.onTransientMessageReceived.subscribe((transientMessage: CometChat.TransientMessage) => {
            callback(CometChatUIKitConstants.messages.TRANSIENT_MESSAGE_RECEIVED, transientMessage);
        });
        this.onInteractionGoalCompleted = CometChatMessageEvents.onInteractionGoalCompleted.subscribe((receipt: CometChat.InteractionReceipt) => {
            callback(CometChatUIKitConstants.messages.INTERACTION_GOAL_COMPLETED, receipt);
        });

        /** Add Group Listener to listen to group action messages */
        CometChat.addGroupListener(
            this.groupListenerId,
            new CometChat.GroupListener({
                onGroupMemberScopeChanged: (message: CometChat.BaseMessage, changedUser: CometChat.User, newScope: CometChat.GroupMemberScope, oldScope: CometChat.GroupMemberScope, changedGroup: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE, message, changedGroup);
                },
                onGroupMemberKicked: (message: CometChat.BaseMessage, kickedUser: CometChat.User, kickedBy: CometChat.User, kickedFrom: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.KICKED, message, kickedFrom);
                },
                onGroupMemberBanned: (message: CometChat.BaseMessage, bannedUser: CometChat.User, bannedBy: CometChat.User, bannedFrom: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.BANNED, message, bannedFrom);
                },
                onGroupMemberUnbanned: (message: CometChat.BaseMessage, unbannedUser: CometChat.User, unbannedBy: CometChat.User, unbannedFrom: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.UNBANNED, message, unbannedFrom);
                },
                onMemberAddedToGroup: (message: CometChat.BaseMessage, userAdded: CometChat.User, userAddedBy: CometChat.User, userAddedIn: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.ADDED, message, userAddedIn);
                },
                onGroupMemberLeft: (message: CometChat.BaseMessage, leavingUser: CometChat.GroupMember, group: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.LEFT, message, group);
                },
                onGroupMemberJoined: (message: CometChat.BaseMessage, joinedUser: CometChat.GroupMember, joinedGroup: CometChat.Group) => {
                    callback(CometChatUIKitConstants.groupMemberAction.JOINED, message, joinedGroup);
                },
            })
        );

        if (ChatConfigurator.names.includes("calling")) {
            CometChat.addCallListener(
                this.callListenerId,
                new CometChat.CallListener({
                    onIncomingCallReceived: (call: CometChat.Call) => {
                        callback('incoming', call);
                    },
                    onIncomingCallCancelled: (call: CometChat.Call) => {
                        callback('cancelled', call);
                    },
                    onOutgoingCallRejected: (call: CometChat.Call) => {
                        callback('rejected', call);
                    },
                    onOutgoingCallAccepted: (call: CometChat.Call) => {
                        callback('accepted', call);
                    },
                })
            );
        }
    };

    static removeListeners() {
        this.onTextMessageReceived?.unsubscribe();
        this.onMediaMessageReceived?.unsubscribe();
        this.onCustomMessageReceived?.unsubscribe();
        this.onFormMessageReceived?.unsubscribe();
        this.onCardMessageReceived?.unsubscribe();
        this.onCustomInteractiveMessageReceived?.unsubscribe();
        this.onMessagesDelivered?.unsubscribe();
        this.onMessagesRead?.unsubscribe();
        this.onMessageDeleted?.unsubscribe();
        this.onMessageEdited?.unsubscribe();
        this.onTransientMessageReceived?.unsubscribe();
        this.onInteractionGoalCompleted?.unsubscribe();
        CometChat.removeGroupListener(this.groupListenerId);
        CometChat.removeConnectionListener(this.connectionListenerId)
        if (ChatConfigurator.names.includes("calling")) {
            CometChat.removeCallListener(this.callListenerId);
        }
    }
                   /**
     * Attaches an SDK websocket  listener
     *
     * @returns - Function to remove the added SDK websocket listener
     */
                   static attachConnestionListener(callback : () => void) {
                    const listenerId = "MessageList_connection_" + String(Date.now());
                    CometChat.addConnectionListener(
                        listenerId,
                        new CometChat.ConnectionListener({
                        onConnected: () => {
                        console.log("ConnectionListener =>connected");
                         if(callback){
                            callback()
                         }
                        },
                        onDisconnected: () => {
                            console.log("ConnectionListener => On Disconnected");
                          }
                    })
                  );
                }
}

