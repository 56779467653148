import { BaseStyle, CheckboxStyle, CometChatAudioBubble, CometChatFileBubble, CometChatFullScreenViewer, CometChatImageBubble, CometChatTextBubble, CometChatVideoBubble, DropdownStyle, FileBubbleStyle, ImageBubbleStyle, InputStyle, LabelStyle, QuickViewStyle, RadioButtonStyle, SingleSelectStyle, TextBubbleStyle } from "@cometchat/uikit-elements";
import { CardBubbleStyle, CometChatCardBubble, CometChatFormBubble, ConversationUtils, DetailsUtils, FormBubbleStyle } from "@cometchat/uikit-shared";
import { CardMessage, CometChatActionsIcon, CometChatActionsView, CometChatDetailsTemplate, CometChatMessageComposerAction, CometChatMessageTemplate, CometChatTheme, CometChatUIEvents, CometChatUIKitConstants, FormMessage, MessageBubbleAlignment, fontHelper, localize } from "@cometchat/uikit-resources";

import AudioIcon from './assets/audio-file.svg';
import { ChatConfigurator } from '../Framework/ChatConfigurator';
import Close2xIcon from './assets/close2x.svg';
import CopyIcon from './assets/Copy.svg';
import { DataSource } from "../Framework/DataSource";
import DeleteIcon from './assets/deleteicon.svg';
import DownloadIcon from './assets/download.svg';
import EditIcon from './assets/editicon.svg';
import FileIcon from './assets/attachment-file.svg';
import ImageIcon from './assets/photolibrary.svg';
import InformationIcon from "./assets/Info.svg"
import PlaceholderImage from './assets/placeholder.png';
import PrivateMessageIcon from "./assets/send-message-in-private.svg"
import React from "react";
import ThreadIcon from './assets/threadicon.svg';
import VideoIcon from './assets/video.svg';
import { createComponent } from "@lit-labs/react";

const TextMessageBubble = createComponent({
    tagName: 'cometchat-text-bubble',
    elementClass: CometChatTextBubble,
    react: React
});

const ImageMessageBubble = createComponent({
    tagName: 'cometchat-image-bubble',
    elementClass: CometChatImageBubble,
    react: React,
    events: {
        'ccImageClicked': 'cc-image-clicked'
    }
});

const AudioMessageBubble = createComponent({
    tagName: 'cometchat-audio-bubble',
    elementClass: CometChatAudioBubble,
    react: React
});

const VideoMessageBubble = createComponent({
    tagName: 'cometchat-video-bubble',
    elementClass: CometChatVideoBubble,
    react: React
});

const FileMessageBubble = createComponent({
    tagName: 'cometchat-file-bubble',
    elementClass: CometChatFileBubble,
    react: React
});

const FormMessageBubble = createComponent({
    tagName: 'cometchat-form-bubble',
    elementClass: CometChatFormBubble,
    react: React,
    events: {
        'ccSubmitClicked': 'cc-submit-clicked'
    }
});

const CardMessageBubble = createComponent({
    tagName: 'cometchat-card-bubble',
    elementClass: CometChatCardBubble,
    react: React
});

const FullScreenImageViewer = createComponent({
    tagName: 'cometchat-full-screen-viewer',
    elementClass: CometChatFullScreenViewer,
    react: React,
    events: {
        'ccCloseClicked': 'cc-close-clicked'
    }
});

const defaultTheme: CometChatTheme = new CometChatTheme({});
export class MessagesDataSource implements DataSource {
    getEditOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.editMessage,
            title: localize("EDIT_MESSAGE"),
            iconURL: EditIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600(),
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }

    getDeleteOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.deleteMessage,
            title: localize("DELETE"),
            iconURL: DeleteIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600()!,
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }

    getReplyInThreadOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.replyInThread,
            title: localize("REPLY_IN_THREAD"),
            iconURL: ThreadIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600(),
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }
    
    getSendMessagePrivatelyOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.sendMessagePrivately,
            title: localize("SEND_MESSAGE_IN_PRIVATE"),
            iconURL: PrivateMessageIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600(),
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }

    getCopyOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.copyMessage,
            title: localize("COPY_MESSAGE"),
            iconURL: CopyIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600()!,
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }

    getMessageInfoOption(theme: CometChatTheme): CometChatActionsIcon {
        return new CometChatActionsIcon({
            id: CometChatUIKitConstants.MessageOption.messageInformation,
            title: localize("MESSAGE_INFORMATION"),
            iconURL: InformationIcon,
            onClick: undefined as any,
            iconTint: theme.palette.getAccent600(),
            backgroundColor: "transparent",
            titleFont: fontHelper(theme.typography.subtitle1),
            titleColor: theme.palette.getAccent600()
        });
    }

    isSentByMe(loggedInUser: CometChat.User, message: CometChat.BaseMessage): boolean {
        return !message.getSender() || loggedInUser.getUid() === message.getSender().getUid();
    }

    getTextMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let _isSentByMe: boolean = this.isSentByMe(loggedInUser, messageObject);
        let _isModerator: boolean = false;

        if (group?.getScope() === CometChatUIKitConstants.groupMemberScope.moderator) {
            _isModerator = true;
        }

        let messageOptionList: Array<CometChatActionsIcon | CometChatActionsView> = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
        messageOptionList.push(this.getCopyOption(theme));
        if (_isSentByMe || _isModerator) {
            messageOptionList.push(this.getEditOption(theme));
        }
       
        return messageOptionList;
    }

    getImageMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let messageOptionList: Array<CometChatActionsIcon | CometChatActionsView> = [];
        messageOptionList = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
       
        return messageOptionList;
    }

    getVideoMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let messageOptionList: Array<CometChatActionsIcon | CometChatActionsView> = [];
        messageOptionList = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
        
        return messageOptionList;
    }

    getAudioMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let messageOptionList: Array<CometChatActionsIcon | CometChatActionsView> = [];
        messageOptionList = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
        
        return messageOptionList;
    }

    getFileMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let messageOptionList: Array<CometChatActionsIcon | CometChatActionsView> = [];
        messageOptionList = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
        
        return messageOptionList;
    }

    getBottomView(_messageObject: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) {
        return null;
    }

    getTextMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.text,
            category: CometChatUIKitConstants.MessageCategory.message,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                let textMessage: CometChat.TextMessage = message as CometChat.TextMessage;
                if (textMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(textMessage, theme);
                }
                return ChatConfigurator.getDataSource().getTextMessageContentView(textMessage, _alignment, theme);
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (_message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return ChatConfigurator.getDataSource().getBottomView(_message, _alignment);
            }
        });
    }

    getAudioMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.audio,
            category: CometChatUIKitConstants.MessageCategory.message,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                let audioMessage: CometChat.MediaMessage = message as CometChat.MediaMessage;
                if (audioMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }
                return ChatConfigurator.getDataSource().getAudioMessageContentView(audioMessage, _alignment, theme);
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (_message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return ChatConfigurator.getDataSource().getBottomView(_message, _alignment);
            }
        });
    }

    getVideoMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.video,
            category: CometChatUIKitConstants.MessageCategory.message,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                let videoMessage: CometChat.MediaMessage = message as CometChat.MediaMessage;
                if (videoMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }
                return ChatConfigurator.getDataSource().getVideoMessageContentView(videoMessage, _alignment, theme);
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (_message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return ChatConfigurator.getDataSource().getBottomView(_message, _alignment);
            }
        });
    }

    getImageMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.image,
            category: CometChatUIKitConstants.MessageCategory.message,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                let imageMessage: CometChat.MediaMessage = message as CometChat.MediaMessage;
                if (imageMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }

                return ChatConfigurator.getDataSource().getImageMessageContentView(imageMessage, _alignment, theme);
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (_message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return ChatConfigurator.getDataSource().getBottomView(_message, _alignment);
            }
        });
    }

    getGroupActionTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.groupMember,
            category: CometChatUIKitConstants.MessageCategory.action,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return this.getGroupActionBubble(message, theme);
            }
        });
    }

    getFileMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.file,
            category: CometChatUIKitConstants.MessageCategory.message,
            contentView: (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                let fileMessage: CometChat.MediaMessage = message as CometChat.MediaMessage;
                if (fileMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }

                return ChatConfigurator.getDataSource().getFileMessageContentView(fileMessage, _alignment, theme);
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (_message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
                return ChatConfigurator.getDataSource().getBottomView(_message, _alignment);
            }
        });
    }

    getFormMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.form,
            category: CometChatUIKitConstants.MessageCategory.interactive,
            contentView: (
                message: CometChat.InteractiveMessage,
                _alignment: MessageBubbleAlignment
            ) => {
                const formMessage: FormMessage =
                    message as FormMessage;
                if (formMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }
                return ChatConfigurator.getDataSource().getFormMessageContentView(
                    formMessage,
                    _alignment,
                    theme
                );
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (
                _message: CometChat.BaseMessage,
                _alignment: MessageBubbleAlignment
            ) => {
                return ChatConfigurator.getDataSource().getBottomView(
                    _message,
                    _alignment
                );
            },
        });
    }

    getCardMessageTemplate(theme: CometChatTheme): CometChatMessageTemplate {
        return new CometChatMessageTemplate({
            type: CometChatUIKitConstants.MessageTypes.card,
            category: CometChatUIKitConstants.MessageCategory.interactive,
            contentView: (
                message: CometChat.InteractiveMessage,
                _alignment: MessageBubbleAlignment
            ) => {
                const cardMessage: CardMessage =
                    message as CardMessage;
                if (cardMessage.getDeletedAt() != null) {
                    return this.getDeleteMessageBubble(message, theme);
                }
                return ChatConfigurator.getDataSource().getCardMessageContentView(
                    cardMessage,
                    _alignment,
                    theme
                );
            },
            options: ChatConfigurator.getDataSource().getMessageOptions,
            bottomView: (
                _message: CometChat.BaseMessage,
                _alignment: MessageBubbleAlignment
            ) => {
                return ChatConfigurator.getDataSource().getBottomView(
                    _message,
                    _alignment
                );
            },
        });
    }

    getAllMessageTemplates(theme?: CometChatTheme): Array<CometChatMessageTemplate> {
        let _theme: CometChatTheme = theme ?? defaultTheme;
        return [
            ChatConfigurator.getDataSource().getTextMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getImageMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getVideoMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getAudioMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getFileMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getGroupActionTemplate(_theme),
            ChatConfigurator.getDataSource().getFormMessageTemplate(_theme),
            ChatConfigurator.getDataSource().getCardMessageTemplate(_theme),
        ];
    }

    getMessageTemplate(messageType: string, messageCategory: string, theme?: CometChatTheme): CometChatMessageTemplate | null {
        let _theme: CometChatTheme = theme ?? defaultTheme;

        let _template: CometChatMessageTemplate | null = null;
        if (messageCategory !== CometChatUIKitConstants.MessageCategory.call) {
            switch (messageType) {
                case CometChatUIKitConstants.MessageTypes.text:
                    _template = ChatConfigurator.getDataSource().getTextMessageTemplate(_theme);
                    break;

                case CometChatUIKitConstants.MessageTypes.image:
                    _template = ChatConfigurator.getDataSource().getImageMessageTemplate(_theme);
                    break;

                case CometChatUIKitConstants.MessageTypes.video:
                    _template = ChatConfigurator.getDataSource().getVideoMessageTemplate(_theme);
                    break;

                case CometChatUIKitConstants.MessageTypes.groupMember:
                    _template = ChatConfigurator.getDataSource().getGroupActionTemplate(_theme);
                    break;

                case CometChatUIKitConstants.MessageTypes.file:
                    _template = ChatConfigurator.getDataSource().getFileMessageTemplate(_theme);
                    break;

                case CometChatUIKitConstants.MessageTypes.audio:
                    _template = ChatConfigurator.getDataSource().getAudioMessageTemplate(_theme);
                    break;
                case CometChatUIKitConstants.MessageTypes.form:
                    _template =
                        ChatConfigurator.getDataSource().getFormMessageTemplate(_theme);
                    break;
                case CometChatUIKitConstants.MessageTypes.card:
                    _template =
                        ChatConfigurator.getDataSource().getCardMessageTemplate(_theme);
                    break;
            }
        }
        return _template;
    }

    getMessageOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let _optionList: Array<CometChatActionsIcon | CometChatActionsView> = [];

        if (messageObject.getCategory() === CometChatUIKitConstants.MessageCategory.message) {
            switch (messageObject.getType()) {
                case CometChatUIKitConstants.MessageTypes.text:
                    _optionList = ChatConfigurator.getDataSource().getTextMessageOptions(loggedInUser, messageObject, theme, group);
                    break;
                case CometChatUIKitConstants.MessageTypes.image:
                    _optionList = ChatConfigurator.getDataSource().getImageMessageOptions(loggedInUser, messageObject, theme, group);
                    break;
                case CometChatUIKitConstants.MessageTypes.video:
                    _optionList = ChatConfigurator.getDataSource().getVideoMessageOptions(loggedInUser, messageObject, theme, group);
                    break;
                case CometChatUIKitConstants.MessageTypes.groupMember:
                    _optionList = [];
                    break;
                case CometChatUIKitConstants.MessageTypes.file:
                    _optionList = ChatConfigurator.getDataSource().getFileMessageOptions(loggedInUser, messageObject, theme, group);
                    break;
                case CometChatUIKitConstants.MessageTypes.audio:
                    _optionList = ChatConfigurator.getDataSource().getAudioMessageOptions(loggedInUser, messageObject, theme, group);
                    break;
            }
        } else if (
            (messageObject.getCategory() === CometChatUIKitConstants.MessageCategory.custom )||
            (messageObject.getCategory() === CometChatUIKitConstants.MessageCategory.interactive)
            ) {
            _optionList = ChatConfigurator.getDataSource().getCommonOptions(loggedInUser, messageObject, theme, group);
        }
        return _optionList;
    }

    getCommonOptions(loggedInUser: CometChat.User, messageObject: CometChat.BaseMessage, theme: CometChatTheme, group?: CometChat.Group): Array<CometChatActionsIcon | CometChatActionsView> {
        let _isSentByMe: boolean = this.isSentByMe(loggedInUser, messageObject);
        let _isModerator: boolean = false;
        if (group?.getScope() === CometChatUIKitConstants.groupMemberScope.moderator) _isModerator = true;

        let messageOptionList: Array<CometChatActionsIcon> = [];

        if (!messageObject.getParentMessageId()) {
            messageOptionList.push(this.getReplyInThreadOption(theme));
        }
        if(_isSentByMe)
        {
            messageOptionList.push(this.getMessageInfoOption(theme)); 
        }
        if (group?.getGuid() && !_isSentByMe) {
            messageOptionList.push(this.getSendMessagePrivatelyOption(theme));
        }
        if (_isSentByMe === true || _isModerator === true)
            messageOptionList.push(this.getDeleteOption(theme));
        return messageOptionList;
    }

    getAllMessageTypes(): Array<string> {
        return [
            CometChatUIKitConstants.MessageTypes.text,
            CometChatUIKitConstants.MessageTypes.image,
            CometChatUIKitConstants.MessageTypes.audio,
            CometChatUIKitConstants.MessageTypes.video,
            CometChatUIKitConstants.MessageTypes.file,
            CometChatUIKitConstants.MessageTypes.groupMember,
            CometChatUIKitConstants.MessageTypes.form,
            CometChatUIKitConstants.MessageTypes.card,
        ];
    }

    addList(): string {
        return "<Message Utils>";
    }

    getAllMessageCategories(): Array<string> {
        return [CometChatUIKitConstants.MessageCategory.message, CometChatUIKitConstants.MessageCategory.action, CometChatUIKitConstants.MessageCategory.interactive];
    }

    getAuxiliaryOptions(id: Map<String, any>, theme: CometChatTheme, user?: CometChat.User, group?: CometChat.Group): any {
        return [];
    }

    getId(): string {
        return "messageUtils";
    }

    getTextMessageContentView(message: CometChat.TextMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme) {
        return ChatConfigurator.getDataSource().getTextMessageBubble(message.getText(), message, _alignment, theme);
    }

    getAudioMessageContentView(message: CometChat.MediaMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {
        return ChatConfigurator.getDataSource().getAudioMessageBubble(message?.getAttachments()[0]?.getUrl(), message, theme, message?.getAttachments()[0]?.getName());
    }

    getFileMessageContentView(message: CometChat.MediaMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {
        return ChatConfigurator.getDataSource().getFileMessageBubble(message?.getAttachments()[0]?.getUrl(), message, theme, message?.getAttachments()[0]?.getName());
    }

    getFormMessageContentView( message: FormMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {    
        return ChatConfigurator.getDataSource().getFormMessageBubble(message, theme);
    }

    getCardMessageContentView( message: CardMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {    
        return ChatConfigurator.getDataSource().getCardMessageBubble( message, theme );
    }

    getImageMessageContentView(message: CometChat.MediaMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {

        let imageUrl = message?.getAttachments()[0]?.getUrl() || "";
        return ChatConfigurator.getDataSource().getImageMessageBubble(imageUrl, PlaceholderImage, message, theme);
    }

    getVideoMessageContentView(message: CometChat.MediaMessage, _alignment: MessageBubbleAlignment, theme: CometChatTheme): any {
        return ChatConfigurator.getDataSource().getVideoMessageBubble(message?.getAttachments()[0]?.getUrl(), message, theme);
    }

    getActionMessage(message: any): string {
        let actionMessage = '';
        if (message.hasOwnProperty("actionBy") === false || message.hasOwnProperty("actionOn") === false) {
            return actionMessage;
        }
        if (message.action !== CometChatUIKitConstants.groupMemberAction.JOINED && message.action !== CometChatUIKitConstants.groupMemberAction.LEFT && (message.actionBy.hasOwnProperty("name") === false || message.actionOn.hasOwnProperty("name") === false)) {
            return actionMessage;
        }
        if (message.action === CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE) {
            if (message.hasOwnProperty("data") && message.data.hasOwnProperty("extras")) {
                if (message.data.extras.hasOwnProperty("scope")) {
                    if (message.data.extras.scope.hasOwnProperty("new") === false) {
                        return actionMessage;
                    }
                } else {
                    return actionMessage;
                }
            } else {
                return actionMessage;
            }
        }
        if (message.action === CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE && message.data.extras.hasOwnProperty("scope") === false) {
            return actionMessage;
        }
        if (message.action === CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE && message.data.extras.scope.hasOwnProperty("new") === false) {
            return actionMessage;
        }
        const byEntity = message.actionBy;
        const onEntity = message.actionOn;
        const byString = byEntity.name;
        const forString = message.action !== CometChatUIKitConstants.groupMemberAction.JOINED && message.action !== CometChatUIKitConstants.groupMemberAction.LEFT ? onEntity.name : "";
        switch (message.action) {
            case CometChatUIKitConstants.groupMemberAction.ADDED:
                actionMessage = `${byString} ${localize("ADDED")} ${forString}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.JOINED:
                actionMessage = `${byString} ${localize("JOINED")}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.LEFT:
                actionMessage = `${byString} ${localize("LEFT")}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.KICKED:
                actionMessage = `${byString} ${localize("KICKED")} ${forString}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.BANNED:
                actionMessage = `${byString} ${localize("BANNED")} ${forString}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.UNBANNED:
                actionMessage = `${byString} ${localize("UNBANNED")} ${forString}`;
                break;
            case CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE: {
                const newScope = message["data"]["extras"]["scope"]["new"];
                actionMessage = `${byString} ${localize("MADE")} ${forString} ${newScope}`;
                break;
            }
            default:
                break;
        }
        return actionMessage;
    }

    getDeleteMessageBubble(message: CometChat.BaseMessage, theme: CometChatTheme, style?: TextBubbleStyle) {
        let defaultStyle = style;
        let messageText = localize('MESSAGE_IS_DELETED');
        if(!defaultStyle){
            defaultStyle = {
                textFont: fontHelper(theme.typography.text2),
                textColor: theme.palette.getAccent400() || ""
            };
        }

        return (
            <TextMessageBubble text={messageText} textStyle={defaultStyle} />
        )
    }

    getGroupActionBubble(message: CometChat.BaseMessage, theme: CometChatTheme, style?: TextBubbleStyle) {
        let defaultStyle = style;
        let messageText = this.getActionMessage(message);
        if(!defaultStyle){
            defaultStyle = {
                textFont: fontHelper(theme.typography.subtitle2),
                textColor: theme.palette.getAccent600() || ""
            }
        }

        return (
            <TextMessageBubble text={messageText} textStyle={defaultStyle} />
        )
    }

    getTextMessageBubbleStyle(alignment: MessageBubbleAlignment, theme: CometChatTheme){
        const isLeftAligned = alignment !== MessageBubbleAlignment.left;

        if(isLeftAligned){
            return {
                textFont: fontHelper(theme.typography.text3),
                textColor: theme.palette.getAccent900("light") || ""
            }
        }else{
            return {
                textFont: fontHelper(theme.typography.text3),
                textColor: theme.palette.getAccent() || ""
            };
        }
    }

    getFormMessageBubbleStyle(theme: CometChatTheme) {
        const textStyle = new InputStyle({
            width: "100%",
            height: "30px",
            border: `1px solid ${theme.palette.getAccent100()}`,
            borderRadius: "3px",
            padding: "0px 0px 0px 5px",
            placeholderTextColor: theme.palette.getAccent400(),
            placeholderTextFont: fontHelper(theme.typography.subtitle2),
            textFont: fontHelper(theme.typography.subtitle2),
            textColor: theme.palette.getAccent(),
            background: theme.palette.getBackground()
        });
        const labelStyle = new LabelStyle({
            textFont: fontHelper(theme.typography.subtitle1),
            textColor: theme.palette.getAccent(),
            background: "transparent"
        });
        const radioButtonStyle = new RadioButtonStyle({
            height: "16px",
            width: "16px",
            border: "none",
            labelTextFont: fontHelper(theme.typography.subtitle2),
            labelTextColor: theme.palette.getAccent600(),
            borderRadius: "4px",
            background: "",
        });
        const checkboxStyle = new CheckboxStyle({
            height: "16px",
            width: "16px",
            border: "none",
            borderRadius: "4px",
            background: "",
            labelTextFont: fontHelper(theme.typography.subtitle2),
            labelTextColor: theme.palette.getAccent600(),
        });
        const dropdownStyle = new DropdownStyle({
            height: "35px",
            width: "100%",
            background: theme.palette.getBackground(),
            border: `1px solid ${theme.palette.getAccent100()}`,
            borderRadius: "12px",
            activeTextFont: fontHelper(theme.typography.subtitle2),
            activeTextColor: theme.palette.getAccent(),
            arrowIconTint: theme.palette.getAccent700(),
            textFont: fontHelper(theme.typography.subtitle2),
            textColor: theme.palette.getAccent(),
            optionBackground: theme.palette.getBackground(),
            optionBorder: `1px solid ${theme.palette.getAccent100()}`,
            optionHoverBorder: `1px solid ${theme.palette.getAccent100()}`,
            hoverTextFont: fontHelper(theme.typography.subtitle2),
            hoverTextColor: theme.palette.getAccent(),
            hoverTextBackground: theme.palette.getAccent100(),
        });
        const buttonGroupStyle = {
            height: "40px",
            width: "100%",
            background: theme.palette.getPrimary(),
            border: `none`,
            borderRadius: "12px",
            buttonTextFont: fontHelper(theme.typography.subtitle2),
            buttonTextColor: theme.palette.getBackground(),
            justifyContent: "center",
        };
        const singleSelectStyle = new SingleSelectStyle({
            height: "100%",
            width: "100%",
            background: theme.palette.getBackground(),
            border: "none",
            borderRadius: "12px",
            activeTextFont: fontHelper(theme.typography.subtitle2),
            activeTextColor: theme.palette.getAccent(),
            activeTextBackground: theme.palette.getAccent100(),
            textFont: fontHelper(theme.typography.subtitle2),
            textColor: theme.palette.getAccent(),
            optionBackground: theme.palette.getBackground(),
            optionBorder: `1px solid ${theme.palette.getAccent100()}`,
            optionBorderRadius: "2px",
            hoverTextFont: fontHelper(theme.typography.subtitle2),
            hoverTextColor: theme.palette.getAccent(),
            hoverTextBackground: theme.palette.getAccent100(),
        });
        const quickViewStyle = new QuickViewStyle({
            background: "transparent",
            height: "fit-content",
            width: "100%",
            titleFont: fontHelper(theme.typography.subtitle2),
            titleColor: theme.palette.getPrimary(),
            subtitleFont: fontHelper(theme.typography.subtitle2),
            subtitleColor: theme.palette.getAccent600(),
            leadingBarTint: theme.palette.getPrimary(),
            leadingBarWidth: "4px",
            borderRadius: "8px",
        });
        return new FormBubbleStyle({
            width: "300px",
            height: "fit-content",
            border: "none",
            background: "transparent",
            wrapperBackground: theme.palette.getBackground(),
            borderRadius: "8px",
            wrapperBorderRadius: "8px",
            textInputStyle: textStyle,
            labelStyle: labelStyle,
            radioButtonStyle: radioButtonStyle,
            checkboxStyle: checkboxStyle,
            dropdownStyle: dropdownStyle,
            buttonStyle: buttonGroupStyle,
            singleSelectStyle: singleSelectStyle,
            quickViewStyle: quickViewStyle,
            titleColor: theme.palette.getAccent(),
            titleFont: fontHelper(theme.typography.title1),
            goalCompletionTextColor: theme.palette.getAccent(),
            goalCompletionTextFont: fontHelper(theme.typography.subtitle1),
            wrapperPadding: "2px",
        });
    };

    getCardMessageBubbleStyle(theme: CometChatTheme) {

        const buttonStyle = {
            height: "40px",
            width: "100%",
            background: "transparent",
            border: `none`,
            borderRadius: "0px",
            buttonTextFont: fontHelper(theme.typography.subtitle2),
            buttonTextColor: `${theme.palette.getPrimary()}`,
            justifyContent: "center",
        };

        return new CardBubbleStyle({
            background: "transparent",
            borderRadius: "8px",
            height: "fit-content",
            width: "300px",
            imageHeight: "auto",
            imageWidth: "100%",
            imageRadius: "8px",
            imageBackgroundColor: "transparent",
            descriptionFontColor: theme.palette.getAccent(),
            descriptionFont: fontHelper(theme.typography.subtitle2),
            buttonStyle: buttonStyle,
            dividerTintColor: theme.palette.getAccent100(),
            wrapperBackground: theme.palette.getBackground(),
            wrapperBorderRadius: "8px",
            wrapperPadding: "2px",
            disabledButtonColor: theme.palette.getAccent600()
        });
    }

    getTextMessageBubble(messageText: string, message: CometChat.TextMessage, alignment: MessageBubbleAlignment, theme: CometChatTheme, style?: TextBubbleStyle): any {
        let defaultStyle = style;
        if(!defaultStyle){
            defaultStyle = this.getTextMessageBubbleStyle(alignment, theme);
        }

        return (
            <TextMessageBubble text={messageText} textStyle={defaultStyle} />
        )
    }

    getAudioMessageBubble(audioUrl: string, message: CometChat.MediaMessage, theme: CometChatTheme, title?: string, style?: BaseStyle): any {
        return (
            <AudioMessageBubble src={audioUrl} audioStyle={style} />
        )
    }

    getFileMessageBubble(fileUrl: string, message: CometChat.MediaMessage, theme: CometChatTheme, title?: string, style?: FileBubbleStyle): any {
        let defaulStyle = style;
        if(!defaulStyle){
            defaulStyle = {
                titleFont: fontHelper(theme.typography.subtitle1),
                titleColor: theme.palette.getAccent(),
                subtitleFont: fontHelper(theme.typography.subtitle2),
                subtitleColor: theme.palette.getAccent600(),
                iconTint: theme.palette.getPrimary(),
            };
        }
        return (
            <FileMessageBubble downloadIconURL={DownloadIcon} subtitle={localize('SHARED_FILE')} title={title} fileURL={fileUrl} fileStyle={defaulStyle} />
        )
        /*
        return (
            <FileMessageBubble downloadIconURL={DownloadIcon} subtitle={localize('SHARED_FILE')} title={title} fileURL={fileUrl} fileStyle={style} />
        )
        */
    }

    getFormMessageBubble(
        message: FormMessage,
        theme: CometChatTheme,
        style?: any,
        onSubmitClick?:Function,
        ): any {
        let defaultStyle: any = style;
        if (!defaultStyle) {
            defaultStyle = this.getFormMessageBubbleStyle(theme);
        }  

const hasCustomCallback = onSubmitClick ? true : false;

        return (
            <FormMessageBubble 
            message={message} 
            formBubbleStyle={defaultStyle} 
            ccSubmitClicked={(e:any)=>{
                if(onSubmitClick) onSubmitClick(e.detail);
            }}
            hasCustomCallback={hasCustomCallback}
            />
        );
    }

    getCardMessageBubble(
        message: CardMessage,
        theme: CometChatTheme,
        style?: CardBubbleStyle,
    ): any {
        let defaultStyle: any = style;
        if (!defaultStyle) {
            defaultStyle = this.getCardMessageBubbleStyle(theme);
        }
        return (
            <CardMessageBubble
                message={message}
                cardBubbleStyle={defaultStyle}
            />
        );
    }

    getImageMessageBubble(imageUrl: string, placeholderImage: string, message: CometChat.MediaMessage, theme: CometChatTheme, onClick?: Function, style?: ImageBubbleStyle) {

        const fullScreenViewer = <FullScreenImageViewer URL={message?.getAttachments()[0]?.getUrl() ?? imageUrl} closeIconURL={Close2xIcon} fullScreenViewerStyle={{closeIconTint:"blue"}} ccCloseClicked={()=>{CometChatUIEvents.ccHideDialog.next()}} />
        return (
            <ImageMessageBubble src={imageUrl} placeholderImage={placeholderImage} imageStyle={style} ccImageClicked={()=>{CometChatUIEvents.ccShowDialog.next({child: fullScreenViewer, confirmCallback: null})}} />
        )
    }

    getVideoMessageBubble(videoUrl: string, message: CometChat.MediaMessage, theme: CometChatTheme, thumbnailUrl?: string, onClick?: Function, style?: BaseStyle): any {
        if(!style){
            style = new BaseStyle({
                height: "130px",
                width: "100%",
                border: "none",
                borderRadius: "8px",
                background: "transparent"
            });
        }
        
        return (
            <VideoMessageBubble src={videoUrl} poster={thumbnailUrl} videoStyle={style} />
        )
    }

    imageAttachmentOption(theme: CometChatTheme): CometChatMessageComposerAction {
        return new CometChatMessageComposerAction({
            id: CometChatUIKitConstants.MessageTypes.image,
            title: localize("ATTACH_IMAGE"),
            iconURL: ImageIcon,
            onClick:null,
            iconTint:theme.palette.getAccent700(),
            titleFont:fontHelper(theme.typography.subtitle1),
            titleColor:theme.palette.getAccent700(),
            borderRadius:"8px",
            background:theme.palette.getAccent100()
        });
    }

    videoAttachmentOption(theme: CometChatTheme): CometChatMessageComposerAction {
        return new CometChatMessageComposerAction({
            id: CometChatUIKitConstants.MessageTypes.video,
            title: localize("ATTACH_VIDEO"),
            iconURL: VideoIcon,
            onClick:null,
            iconTint:theme.palette.getAccent700(),
            titleFont:fontHelper(theme.typography.subtitle1),
            titleColor:theme.palette.getAccent700(),
            borderRadius:"8px",
            background:theme.palette.getAccent100()
        });
    }

    audioAttachmentOption(theme: CometChatTheme): CometChatMessageComposerAction {
        return new CometChatMessageComposerAction({
            id: CometChatUIKitConstants.MessageTypes.audio,
            title: localize("ATTACH_AUDIO"),
            iconURL: AudioIcon,
            onClick:null,
            iconTint:theme.palette.getAccent700(),
            titleFont:fontHelper(theme.typography.subtitle1),
            titleColor:theme.palette.getAccent700(),
            borderRadius:"8px",
            background:theme.palette.getAccent100()
        });
    }

    fileAttachmentOption(theme: CometChatTheme): CometChatMessageComposerAction {
        return new CometChatMessageComposerAction({
            id: CometChatUIKitConstants.MessageTypes.file,
            title: localize("ATTACH_FILE"),
            iconURL: FileIcon,
            onClick:null,
            iconTint:theme.palette.getAccent700(),
            titleFont:fontHelper(theme.typography.subtitle1),
            titleColor:theme.palette.getAccent700(),
            borderRadius:"8px",
            background:theme.palette.getAccent100()
        });
    }

    getAttachmentOptions(theme: CometChatTheme, id?: Map<String, any>): Array<CometChatMessageComposerAction> {
        let actions: Array<CometChatMessageComposerAction> = [
            this.imageAttachmentOption(theme),
            this.videoAttachmentOption(theme),
            this.audioAttachmentOption(theme),
            this.fileAttachmentOption(theme)
        ];

        return actions;
    }

    getLastConversationMessage(conversation: CometChat.Conversation, loggedInUser: CometChat.User): string {
        return ConversationUtils.getLastConversationMessage(conversation, loggedInUser);
    }

    getDefaultDetailsTemplate(loggedInUser: CometChat.User, user: CometChat.User | null, group: CometChat.Group | null, theme: CometChatTheme): CometChatDetailsTemplate[]{
        return DetailsUtils.getDefaultDetailsTemplate(loggedInUser, user, group, theme);
    }

    getAuxiliaryHeaderMenu(user?: CometChat.User, group?: CometChat.Group): any{
        return [];
    }
}