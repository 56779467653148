import { CometChat } from "@cometchat/chat-sdk-javascript";

type Args = {
    searchText : string,
    usersRequestBuilder : CometChat.UsersRequestBuilder | null,
    searchRequestBuilder : CometChat.UsersRequestBuilder | null
};

export class UsersManager {
    private usersRequest : CometChat.UsersRequest;
    private static defaultLimit = 30;

    /**
     * Set `usersRequest` of the instance
     */
    constructor(args : Args) {
        const {
            searchText,
            usersRequestBuilder,
            searchRequestBuilder
        } = args;

        let currentUsersRequestBuilder = usersRequestBuilder;
        if (currentUsersRequestBuilder === null) {
            currentUsersRequestBuilder = new CometChat.UsersRequestBuilder()
                                                      .setLimit(UsersManager.defaultLimit);
        }

        if (searchText.length !== 0) {
            if (searchRequestBuilder === null) {
                currentUsersRequestBuilder.setSearchKeyword(searchText);
            }
            else {
                currentUsersRequestBuilder = searchRequestBuilder.setSearchKeyword(searchText);
            }
        }

        this.usersRequest = currentUsersRequestBuilder.build();
    }

    /**
     * Calls `fetchNext` method of the set `usersRequest`
     */
    fetchNext() {
        return this.usersRequest.fetchNext();
    }

    /**
     * Attaches an SDK user listener
     *
     * @returns Function to call to remove the attached SDK user listener
     */
    static atttachListeners(cb : (user : CometChat.User) => void) {
        const listenerId = "UsersList_" + String(Date.now());
        const userListener = new CometChat.UserListener({onUserOnline: cb, onUserOffline: cb});
        CometChat.addUserListener(listenerId, userListener);
        return () => CometChat.removeUserListener(listenerId);
    }
               /**
     * Attaches an SDK websocket  listener
     *
     * @returns - Function to remove the added SDK websocket listener
     */
               static attachConnestionListener(callback : () => void) {
                const listenerId = "UsersList_connection_" + String(Date.now());
                CometChat.addConnectionListener(
                    listenerId,
                    new CometChat.ConnectionListener({
                    onConnected: () => {
                    console.log("ConnectionListener =>connected");
                     if(callback){
                        callback()
                     }
                    },
                    onDisconnected: () => {
                        console.log("ConnectionListener => On Disconnected");
                      }
                })
              );
                return () => CometChat.removeConnectionListener(listenerId);
            }
}
