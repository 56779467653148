import "@cometchat/uikit-elements";

import { CometChatActionsIcon, CometChatActionsView, MessageBubbleAlignment, Placement } from "@cometchat/uikit-resources";
import { MessageBubbleAlignmentStyles, MessageBubbleAvatarStyles, MessageBubbleTitleStyles, MessageBubbleWrapperStyles, MessageOptionsStyles, menuListStyles } from "./style";
import React, { useContext, useEffect, useState } from 'react';

import { BaseStyle } from '@cometchat/uikit-shared';
import { CometChatContextMenu } from "../CometChatContextMenu";
import { CometChatThemeContext } from "../../../CometChatThemeContext";
import MoreIcon from "./assets/More.svg";

interface IMessageBubbleProps {
  id: any;
  setRef?: (ref: any) => void;
  leadingView: any;
  headerView: any;
  replyView: any;
  contentView: any;
  bottomView: any;
  threadView: any;
  footerView: any;
  options: (CometChatActionsIcon | CometChatActionsView)[];
  alignment: MessageBubbleAlignment;
  messageBubbleStyle: BaseStyle,
  moreIconURL?: string,
  topMenuSize?: number
};

const CometChatMessageBubble = (props: IMessageBubbleProps) => {
  const {
    id,
    leadingView = null,
    headerView = null,
    replyView = null,
    contentView = null,
    bottomView = null,
    threadView = null,
    footerView = null,
    options = [],
    alignment = MessageBubbleAlignment.right,
    messageBubbleStyle = new BaseStyle({
      width: "100%",
      height: "auto",
      background: "",
      borderRadius: "12px",
      border: "none"
    }),
    moreIconURL = MoreIcon,
    topMenuSize = 5,
    setRef
  } = props;

  const contentStyle = {
    borderRadius:'8px'
  };

  const {theme} = useContext(CometChatThemeContext);
  const messageRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (messageRef && messageRef.current && setRef) {
      setRef(messageRef);
    }
  }, [messageRef, setRef]);

  // const menuListStyles = new MenuListStyle({
  //   border:`1px solid ${theme.palette.getAccent200()}`,
  //   borderRadius: "8px",
  //   background: theme.palette.getBackground(),
  //   submenuWidth: "100%",
  //   submenuHeight: "100%",
  //   submenuBorder: `1px solid ${theme.palette.getAccent200()}`,
  //   submenuBorderRadius: "8px",
  //   submenuBackground: theme.palette.getBackground(),
  //   moreIconTint:theme.palette.getAccent600()
  // })
  
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const showHideMessageOptions = () => {
    setIsHovering(!isHovering);
  }

  const getLeadingView = () => {
    if (leadingView && alignment === MessageBubbleAlignment.left) {
      return (

        <div
          className='cc-message-bubble__avatar'
          style={MessageBubbleAvatarStyles()}
        >
          {leadingView}
        </div >
      )
    }
  }

  const getHeaderView = () => {
    if (headerView) {
      return (
        <div
          className='cc-message-bubble__header'
          style={MessageBubbleTitleStyles(alignment, MessageBubbleAlignment)}
        >
          {headerView}
        </div>
      )
    }
  }

  const onOptionClicked = (data : CometChatActionsIcon | CometChatActionsView) => {
    options.forEach((option) => {
      if(option instanceof CometChatActionsIcon){
        if(option.id === data?.id && id){
          option.onClick?.(parseInt(id));
        }
      }
    });
  }

  const getMessageOptions = () => {
    if (options && options.length > 0 && isHovering) {
      return (
        <div className='cc-message-options'
          style={MessageOptionsStyles(alignment, MessageBubbleAlignment, headerView, theme)}
        >
          <CometChatContextMenu 
            moreIconURL={moreIconURL}
            topMenuSize={topMenuSize}
            ContextMenuStyle={menuListStyles(theme)}
            data={options}
            moreIconHoverText={''} 
            onOptionClicked={onOptionClicked}       
            placement={alignment === MessageBubbleAlignment.right ? Placement.left : Placement.right}     
          />
        </div>
      )
    }
  }
  return (
    <div className='cc-message-bubble__wrapper'
      ref={messageRef}
      style={MessageBubbleWrapperStyles(alignment, MessageBubbleAlignment)}
      onMouseEnter={showHideMessageOptions}
      onMouseLeave={showHideMessageOptions}
    >
      
      {/* <div className='cc__messagebubble__container'
      style={MessageBubbleContainerStyles()}
        onMouseEnter={showHideMessageOptions}
        onMouseLeave={showHideMessageOptions}
      > */}
        {getLeadingView()}
        <div className='cc-message-bubble'
          style={MessageBubbleAlignmentStyles(alignment, MessageBubbleAlignment)}
        >
          {getMessageOptions()}
          {getHeaderView()}
          <div
            className='cc-message-bubble__content'
            style={{...messageBubbleStyle, ...contentStyle}}
          >
            {replyView ? replyView : null}
            {contentView ? contentView : null}
            {bottomView ? bottomView : null}
            {threadView ? threadView : null}
          </div>
          {footerView ? footerView : null}
        </div>
      {/* </div> */}
    </div>
  )
}

export { CometChatMessageBubble }