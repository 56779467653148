import { CardMessage, CometChatMessageEvents, CustomInteractiveMessage, FormMessage } from "@cometchat/uikit-resources"

import { CometChat } from "@cometchat/chat-sdk-javascript";

type Args = {
    conversationsRequestBuilder : CometChat.ConversationsRequestBuilder | null
};

export class ConversationsManager {
    private static limit = 30;
    private conversationsRequest : CometChat.ConversationsRequest;

    /**
     * Set `conversationsRequest` of the instance
     */
    constructor(args : Args) {
        const {
            conversationsRequestBuilder
        } = args;
        const convRequestBuilder = conversationsRequestBuilder || new CometChat.ConversationsRequestBuilder().setLimit(ConversationsManager.limit);
        this.conversationsRequest = convRequestBuilder.build();
    }

    /**
     * Calls `fetchNext` method of the set `conversationsRequest`
     */
    fetchNext() {
        return this.conversationsRequest.fetchNext();
    }

    /**
     * Attaches an SDK user listener
     *
     * @returns Function to call to remove the attached SDK user listener
     */
    static attachUserListener(callback : (user : CometChat.User) => void) {
        const listenerId = "ConversationList_User_" + String(Date.now());
        CometChat.addUserListener(
            listenerId,
            new CometChat.UserListener({
                onUserOnline: callback,
                onUserOffline: callback
            })
        );
        return () => CometChat.removeUserListener(listenerId);
    }

    /**
     * Attaches an SDK group listener
     *
     * @returns Function to call to remove the attached SDK group listener
     */
    static attachGroupListener(callback : (message : CometChat.BaseMessage, remove? : boolean) => Promise<void>, loggedInUser : CometChat.User | null) {
        const listenerId = "ConversationList_Group_" + String(Date.now());
        CometChat.addGroupListener(
            listenerId,
            new CometChat.GroupListener({
                onGroupMemberJoined: (message : CometChat.Action) => {
                    callback(message);
                },
                onGroupMemberLeft: (message : CometChat.Action, leavingUser : CometChat.User) => {
                    if (loggedInUser?.getUid() === leavingUser.getUid()) {
                        callback(message, true);
                    }
                    else {
                        callback(message);
                    }
                },
                onGroupMemberKicked: (message : CometChat.Action, kickedUser : CometChat.User) => {
                    if (loggedInUser?.getUid() === kickedUser.getUid()) {
                        callback(message, true);
                    }
                    else {
                        callback(message);
                    }
                },
                onGroupMemberBanned: (message : CometChat.Action, bannedUser : CometChat.User) => {
                    if (loggedInUser?.getUid() === bannedUser.getUid()) {
                        callback(message, true);
                    }
                    else {
                        callback(message);
                    }
                },
                onGroupMemberUnbanned: (message : CometChat.Action) => {
                    callback(message);
                },
                onMemberAddedToGroup: (message : CometChat.Action) => {
                    callback(message);
                },
                onGroupMemberScopeChanged: (message : CometChat.Action) => {
                    callback(message);
                }
            })
        );
        return () => CometChat.removeGroupListener(listenerId);
    }

    /**
     * Attaches an SDK message received listener
     *
     * @returns - Function to remove the added SDK message received listener
     */
    static attachMessageReceivedListener(callback : (message : CometChat.BaseMessage) => Promise<void>) {
        const onTextMessageReceived = CometChatMessageEvents.onTextMessageReceived.subscribe((textMessage : CometChat.TextMessage) => {
            callback(textMessage);
        });

        const onMediaMessageReceived = CometChatMessageEvents.onMediaMessageReceived.subscribe((mediaMessage : CometChat.MediaMessage) => {
            callback(mediaMessage);
        });

        const onCustomMessageReceived = CometChatMessageEvents.onCustomMessageReceived.subscribe((customMessage : CometChat.CustomMessage) => {
            callback(customMessage);
        });

        const onFormMessageReceived = CometChatMessageEvents.onFormMessageReceived.subscribe((formMessage: FormMessage) => {
            callback(formMessage);
        });

        const onCardMessageReceived = CometChatMessageEvents.onCardMessageReceived.subscribe((cardMessage: CardMessage) => {
            callback(cardMessage);
        });

        const onCustomInteractiveMessageReceived = CometChatMessageEvents.onCustomInteractiveMessageReceived.subscribe((customMessage : CustomInteractiveMessage) => {
            callback(customMessage);
        });

        return () => {
            onTextMessageReceived?.unsubscribe();
            onMediaMessageReceived?.unsubscribe();
            onCustomMessageReceived?.unsubscribe();
            onFormMessageReceived?.unsubscribe();
            onCardMessageReceived?.unsubscribe();
            onCustomInteractiveMessageReceived?.unsubscribe();
        };
    }

    /**
     * Attaches an SDK message receipt listener
     *
     * @returns - Function to remove the added SDK message receipt listener
     */
    static attachMessageReceiptListener(callback : (receipt : CometChat.MessageReceipt, updateReadAt : boolean) => void) {
        const onMessagesRead = CometChatMessageEvents.onMessagesRead.subscribe((messageReceipt : CometChat.MessageReceipt) => {
            callback(messageReceipt, true);
        });
        const onMessagesDelivered = CometChatMessageEvents.onMessagesDelivered.subscribe((messageReceipt : CometChat.MessageReceipt) => {
            callback(messageReceipt, false);
        });

        return () => {
            onMessagesRead?.unsubscribe();
            onMessagesDelivered?.unsubscribe();
        };
    }

    /**
     * Attaches an SDK message typing listener
     *
     * @returns - Function to remove the added SDK message typing listener
     */
    static attachMessageTypingListener(callback : (typingIndicator : CometChat.TypingIndicator, typingStarted : boolean) => void) {
        const onTypingStarted = CometChatMessageEvents.onTypingStarted.subscribe((typingIndicator : CometChat.TypingIndicator) => {
            callback(typingIndicator, true);
        });
        const onTypingEnded = CometChatMessageEvents.onTypingEnded.subscribe((typingIndicator : CometChat.TypingIndicator) => {
            callback(typingIndicator, false);
        });
        return () => {
            onTypingStarted?.unsubscribe();
            onTypingEnded?.unsubscribe();
        };
    }

    /**
     * Attaches an SDK message modified listener
     *
     * @returns - Function to remove the added SDK message modified listener
     */
    static attachMessageModifiedListener(callback : (message : CometChat.BaseMessage) => void) {
        const onMessageEdited = CometChatMessageEvents.onMessageEdited.subscribe((message : CometChat.BaseMessage) => {
            callback(message);
        });
        const onMessageDeleted = CometChatMessageEvents.onMessageDeleted.subscribe((message : CometChat.BaseMessage) => {
            callback(message);
        });

        return () => {
            onMessageEdited?.unsubscribe();
            onMessageDeleted?.unsubscribe();
        };
    }

    /**
     * Attaches an SDK call listener
     *
     * @returns - Function to remove the added SDK call listener
     */
    static attachCallListener(callback : (message : CometChat.BaseMessage) => void) {
        const listenerId = "ConversationList_Call_" + String(Date.now());
        CometChat.addCallListener(
            listenerId,
            new CometChat.CallListener({
                onIncomingCallReceived: callback,
                onOutgoingCallAccepted: callback,
                onOutgoingCallRejected: callback,
                onIncomingCallCancelled: callback
            })
        );
        return () => CometChat.removeCallListener(listenerId);
    }
       /**
     * Attaches an SDK websocket  listener
     *
     * @returns - Function to remove the added SDK websocket listener
     */
       static attachConnestionListener(callback : () => void) {
        const listenerId = "ConversationList_connection_" + String(Date.now());
        CometChat.addConnectionListener(
            listenerId,
            new CometChat.ConnectionListener({
            onConnected: () => {
            console.log("ConnectionListener =>connected");
             if(callback){
                callback()
             }
            },
            onDisconnected: () => {
                console.log("ConnectionListener => On Disconnected");
              }
        })
      );
        return () => CometChat.removeConnectionListener(listenerId);
    }
}
