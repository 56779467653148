import {  useState } from 'react';
import backIcon from './assets/backbutton.svg'
import emptyIcon from './assets/ai-empty.svg'
import errorIcon from './assets/ai-error.svg'
import { States, localize } from '@cometchat/uikit-resources';
import loadingIconURL from './assets/loading.svg'
import {  buttonContainerStyle, cardViewStyleObject, containerStyle, contentContainerStyle, defaultViewStyle, emptyLabelStyle, errorLabelStyle, getBackButtonStyle, getTitleStyle, loadingLabelStyle, tabButtonStyle, viewContainerStyle } from './style';
import { CometChatButton } from '../Shared/Views/CometChatButton';
import { AIButtonsStyle, AISmartRepliesStyle, CardViewStyle } from '@cometchat/uikit-shared';
export type CardStyleType = AISmartRepliesStyle & CardViewStyle;

export type Card = {
  title?: string;
  id?: string;
  onClick?: (id: string) => Promise<JSX.Element>;
  style?:CardStyleType;
  errorStateView?:(e?:CometChat.CometChatException)=> JSX.Element;
  emptyStateView?:()=> JSX.Element;
  loadingStateView?:()=> JSX.Element;
  loadingIconURL?:string;
  emptyIconURL?:string;
  errorIconURL?:string;

};

interface CardProps  {
  buttons?: Card[];
  backCallback?:(callback:()=>void) => void;
};

const defaultProps:CardProps = {
  buttons: undefined,
  backCallback:undefined,
}


const CardView = (props:CardProps) => {
  const [currentSection, setCurrentSection] = useState<string | null>(null);
  const [activeButton, setActiveButton] = useState<Card | null>(null);
  const [activeButtonText, setActiveButtonText] = useState<string | null>(null);
  const [messageListState, setMessageListState] = useState<States>(
    States.loading
  );  const [activeView, setActiveView] = useState<JSX.Element| null>(null);


  const handleGoBack = () => {
    setCurrentSection(null);
    setActiveView(null);
    setActiveButtonText(null);
  };
const errorStateText:string = localize("SOMETHING_WRONG");
const emptyStateText:string = localize("NO_REPLIES_FOUND")
const loadingStateText:string = localize("GENERATING_REPLIES")


function fetchButtonContent(button:Card){
  setActiveButton(button)
  setCurrentSection(button.id!)
  setActiveButtonText(button.title!)
  if(props.backCallback){
    props.backCallback(handleGoBack)
  }
  setMessageListState(States.loading)
  if(button && button.onClick){
    button.onClick(button.id!).then((response)=>{
  
 if(response){
  setMessageListState(States.loaded)
  setActiveView(response)
 }
    }).catch((err)=>{
      console.log(err)
      setMessageListState(States.error)

    })
  }
}
  /**
   * Creates a view based on the value of the `state` prop
   */
  function getStateView(): JSX.Element | null {
    let res: JSX.Element | null = null;
    switch (messageListState) {
   
      case States.loading:
        res = getLoadingView();
        break;
      case States.error:
        res = getErrorView();
        break;
      case States.empty:
        res = getEmptyView();
        break;
      case States.loaded:
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const x: never = messageListState;
    }
    return res;
  }
  /**
   * Creates the loading view
   */
  function getLoadingView(): JSX.Element {
    let LoadingView =activeButton?.loadingStateView
    if(LoadingView){
      return <LoadingView/>
    }
   else{
    return (
      <div
        className="cc-list__loading-view-wrapper"
        style={viewContainerStyle(activeButton?.style)}
      >
       <div
          className="cc-list__loading-view cc-list__loading-view--default"
          style={defaultViewStyle(activeButton?.style)}
        >
          <cometchat-loader
            iconURL={ activeButton?.loadingIconURL ??  loadingIconURL}
            loaderStyle={JSON.stringify({iconTint:activeButton?.style!.loadingIconTint})}
          />
          <cometchat-label 
                 text={loadingStateText}
                 labelStyle={JSON.stringify(loadingLabelStyle(activeButton?.style))}
          ></cometchat-label>
        </div>
      </div>
    );
   }
  }

  /**
   * Creates the error view
   */
  function getErrorView(): JSX.Element | null {
    let ErrorView = activeButton?.errorStateView
    if(ErrorView){
      return <ErrorView/>
    }

else{
  return (
    <div className="cc-list__error-view-wrapper" style={viewContainerStyle(activeButton?.style)}>
      <div
        style={defaultViewStyle(activeButton?.style)}
        className="cc-list__error-view cc-list__error-view--default"
      >
             <cometchat-loader
            iconURL={ activeButton?.errorIconURL ??  errorIcon}
            loaderStyle={JSON.stringify({iconTint:activeButton?.style!.errorIconTint})}            />
        <cometchat-label
          text={errorStateText}
          labelStyle={JSON.stringify(errorLabelStyle(activeButton?.style))}
        />
      </div>
    </div>
  );
}
  }

  /**
   * Creates the empty view
   */
  function getEmptyView(): JSX.Element {
    let EmptyView = activeButton?.errorStateView
    if(EmptyView){
      return <EmptyView/>
    }

else{
  return (
    <div className="cc-list__empty-view-wrapper" style={viewContainerStyle(activeButton?.style)}>
           
      <div
        style={defaultViewStyle(activeButton?.style)}
        className="cc-list__empty-view cc-list__empty-view--default"
      >
             <cometchat-loader
            iconURL={ activeButton?.emptyIconURL ??   emptyIcon}
            loaderStyle={JSON.stringify({iconTint:activeButton?.style!.emptyIconTint})}/>
        <cometchat-label
          text={emptyStateText}
          labelStyle={JSON.stringify(emptyLabelStyle(activeButton?.style))}
        />
      </div>
    
    </div>
  );
}
  }
function getButtons():JSX.Element | null{

  return props.buttons ? (
    <div className="buttons-container cc__scroll-view"  style={buttonContainerStyle}>
      {props.buttons.map((buttonObject) => (
        <CometChatButton key={buttonObject.id} onClick={()=>fetchButtonContent(buttonObject)} text={buttonObject.title} buttonStyle={tabButtonStyle(buttonObject.style!)} />
      ))}
    </div>
  ) : null;
}
  return (
    <div className="slideable-container" style={containerStyle}>
           <cometchat-label
              text={activeButtonText ? activeButtonText : localize("AI")}
              labelStyle={JSON.stringify(getTitleStyle(cardViewStyleObject))}
            />
      <div  style={contentContainerStyle}>
        {!currentSection && (
           getButtons()
         
        )}
        {currentSection &&  (
    <div style={{height:"100%", marginTop:"12px", overflow:"hidden auto"}} >
      <CometChatButton iconURL={backIcon}  onClick={()=>handleGoBack()} buttonStyle={getBackButtonStyle(activeButton?.style)}/>
      {   activeView ? activeView : getStateView()}
    
    </div>
        )}
       
      </div>
    </div>
  );
};
CardView.defaultProps = defaultProps
export default CardView;
