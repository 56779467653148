import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { CollaborativeDocumentConfiguration } from "./CollaborativeDocumentConfiguration";
import { CollaborativeDocumentExtensionDecorator } from "./CollaborativeDocumentExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class CollaborativeDocumentExtension extends ExtensionsDataSource {
  private configuration?: CollaborativeDocumentConfiguration;

  constructor(configuration?: CollaborativeDocumentConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable((dataSource: any) => new CollaborativeDocumentExtensionDecorator(dataSource, this.configuration));
  }

  override getExtensionId(): string {
    return ExtensionsId.document;
  }
}
