import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { ThumbnailGenerationExtensionDecorator } from "./ThumbnailGenerationExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class ThumbnailGenerationExtension extends ExtensionsDataSource {
  
  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new ThumbnailGenerationExtensionDecorator(dataSource)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.thumbnailGeneration;
  }
}