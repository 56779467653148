import { useEffect } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatUIKit } from "../../Shared/CometChatUIKit/CometChatUIKit";

function Hooks(
	setLoggedInUser: any,
    sessionID: string,
    startCall: any,
) {
    useEffect(
        () => {
            CometChatUIKit.getLoggedinUser().then(
                (user: CometChat.User | null)=>{
                    if(user){
                        setLoggedInUser(user);
                    }
                }
            )
        }, [setLoggedInUser]
    );

    useEffect(
        () => {
            if(sessionID !== ""){
                startCall();
                
            }
        }, [sessionID, startCall]
    )
}

export { Hooks };