import { CometChatGroupEvents, CometChatUIEvents, CometChatUIKitConstants, IGroupLeft, IGroupMemberAdded, IGroupMemberJoined, IGroupMemberKickedBanned, IOwnershipChanged, localize } from "@cometchat/uikit-resources";
import { CreateGroupConfiguration, GroupsConfiguration, JoinGroupConfiguration, MessageComposerConfiguration, MessageHeaderConfiguration, MessagesConfiguration, WithMessagesStyle } from "@cometchat/uikit-shared";
import { JSX, useCallback, useContext, useRef, useState } from "react";
import { createGroupButtonStyle, getBackdropStyle, getCreateGroupStyle, getCreateGroupWrapperStyle, getEmptyMessageLayoutStyle, getGroupsStyle, getGroupsWrapperStyles, getJoinGroupMessageHederStyle, getJoinGroupStyle, getJoinGroupWrapperStyle, getLabelStyle, getListItemStyle, getMessageComposerStyle, getMessageHeaderSlotStyle, getMessageHeaderStyle, getMessagesStyle, getWithMessagesMainStyle, getWithMessagesSidebarStyle } from "./style";
import { useCometChatErrorHandler, useStateRef } from "../CometChatCustomHooks";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatGroups } from "../CometChatGroups";
import { CometChatMessageHeader } from "../CometChatMessageHeader";
import { CometChatMessages } from "../CometChatMessages";
import { CometChatThemeContext } from "../CometChatThemeContext";
import CreateIcon from "./assets/create-button.svg";
import { Hooks } from "./hooks";

interface IGroupsWithMessagesProps {
    group?: CometChat.Group,
    isMobileView?: boolean,
    messageText?: string,
    groupsWithMessagesStyle?: WithMessagesStyle,
    messagesConfiguration?: MessagesConfiguration,
    groupsConfiguration?: GroupsConfiguration,
    createGroupConfiguration?: CreateGroupConfiguration,
    joinGroupConfiguration?: JoinGroupConfiguration,
    onError?: ((error: CometChat.CometChatException) => void) | null
}

const defaultProps: IGroupsWithMessagesProps = {
    group: undefined,
    isMobileView: false,
    messageText: localize("SELECT__GROUP"),
    groupsWithMessagesStyle: {},
    messagesConfiguration: new MessagesConfiguration({}),
    groupsConfiguration: new GroupsConfiguration({}),
    createGroupConfiguration: new CreateGroupConfiguration({}),
    joinGroupConfiguration: new JoinGroupConfiguration({}),
    onError: (error: CometChat.CometChatException) => { console.log(error) }
};

const CometChatGroupsWithMessages = (props: IGroupsWithMessagesProps) => {
    const { theme } = useContext(CometChatThemeContext);
    const {
        group,
        isMobileView,
        messageText,
        groupsWithMessagesStyle,
        messagesConfiguration,
        groupsConfiguration,
        createGroupConfiguration,
        joinGroupConfiguration,
        onError
    } = props;
    
    const createGroupConfigCreateClick = createGroupConfiguration?.createClick;
    const joinGroupConfigOnError = joinGroupConfiguration?.onError;
    const joinGroupConfigJoinClick = joinGroupConfiguration?.joinClick;

    const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);
    const [createGroupElement, setCreateGroupRef] = useStateRef<JSX.IntrinsicElements["cometchat-create-group"] | null>(null);
    const [joinGroupElement, setJoinGroupRef] = useStateRef<JSX.IntrinsicElements["cometchat-join-group"] | null>(null);
    const createGroupButtonRef = useRef(null);
    const [openCreateGroupPage, setOpenCreateGroupPage] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [protectedGroup, setProtectedGroup] = useState<CometChat.Group | undefined>(undefined);
    const [activeGroup, setActiveGroup] = useState<CometChat.Group | undefined>(group ?? undefined);
    const [activeUser, setActiveUser] = useState<CometChat.User | null>(null);
    const onErrorCallback = useCometChatErrorHandler(onError);

    const onBack = () => {
        setActiveUser(null);
        setActiveGroup(undefined);
    };

    const openCreateGroup = () => setOpenCreateGroupPage(true);
    
    const closeCreateGroup = useCallback(() => {
        setOpenCreateGroupPage(false);
    }, []);

    const closeJoinGroup = () => {
        setOpenPasswordModal(false);
    }

    const onItemClick = (group: CometChat.Group) => {
        try{
            setActiveUser(null);
            setOpenPasswordModal(false);
            if (group.getHasJoined()) {
                setActiveGroup(group);
                return;
            }
            if (group.getType() === CometChatUIKitConstants.GroupTypes.password) {
                setActiveGroup(undefined);
                setProtectedGroup(group);
                setOpenPasswordModal(true);
                return;
            }
            CometChat.joinGroup(group).then(
                (groupJoined: CometChat.Group) => {
                    setActiveGroup(groupJoined);
                }, (error: CometChat.CometChatException) => {
                    onErrorCallback(error);
                }
            );
        }catch(error: any){
            onErrorCallback(error);
        }
    };

    const onGroupJoined = useCallback(
        (event: any) => {
            try{
                let group: CometChat.Group = event?.detail?.response;
                setOpenPasswordModal(false);
                setProtectedGroup(undefined);
                if(group) {
                    setActiveGroup(group);
                }
            }catch(error: any){
                onErrorCallback(error);
            }
        }, [setActiveGroup, setProtectedGroup, setOpenPasswordModal, onErrorCallback]
    )

    const subscribeToEvents = useCallback(
        () => {
            try{
                const ccGroupDeleted = CometChatGroupEvents.ccGroupDeleted.subscribe(
                    (group: CometChat.Group) => {
                        if (activeGroup && activeGroup.getGuid() === group.getGuid()) {
                            setActiveGroup(undefined);
                        }
                    }
                );
                const ccGroupCreated = CometChatGroupEvents.ccGroupCreated.subscribe(
                    (group: CometChat.Group) => {
                        if (group) {
                            setActiveGroup(group);
                            setOpenCreateGroupPage(false);
                        }
                    }
                );
                const ccGroupMemberAdded = CometChatGroupEvents.ccGroupMemberAdded.subscribe(
                    (item: IGroupMemberAdded) => {
                        if (activeGroup && activeGroup.getGuid() === item?.userAddedIn!.getGuid()) {
                            setActiveGroup(item?.userAddedIn);
                            setOpenCreateGroupPage(false);
                        }
                    }
                )
                const ccGroupMemberBanned = CometChatGroupEvents.ccGroupMemberBanned.subscribe(
                    (item: IGroupMemberKickedBanned) => {
                        if (activeGroup && activeGroup.getGuid() === item?.kickedFrom!.getGuid()) {
                            setActiveGroup(item?.kickedFrom);
                        }
                    }
                )
                const ccGroupMemberJoined = CometChatGroupEvents.ccGroupMemberJoined.subscribe(
                    (item: IGroupMemberJoined) => {
                        if (activeGroup && activeGroup.getGuid() === item?.joinedGroup!.getGuid()) {
                            setActiveGroup(item?.joinedGroup);
                        }
                    }
                )
                const ccGroupMemberKicked = CometChatGroupEvents.ccGroupMemberKicked.subscribe(
                    (item: IGroupMemberKickedBanned) => {
                        if (activeGroup && activeGroup.getGuid() === item?.kickedFrom!.getGuid()) {
                            setActiveGroup(item?.kickedFrom);
                        }
                    }
                )
                const ccOwnershipChanged = CometChatGroupEvents.ccOwnershipChanged.subscribe(
                    (item: IOwnershipChanged) => {
                        if (activeGroup && activeGroup.getGuid() === item?.group!.getGuid()) {
                            setActiveGroup(item?.group);
                        }
                    }
                );
                const ccGroupLeft = CometChatGroupEvents.ccGroupLeft.subscribe(
                    (item: IGroupLeft) => {
                        if (activeGroup && activeGroup.getGuid() === item?.leftGroup!.getGuid() && loggedInUser?.getUid() === item?.userLeft?.getUid()) {
                            setActiveGroup(item?.leftGroup);
                        }
                    }
                );
                const ccOpenChat = CometChatUIEvents.ccOpenChat.subscribe((item)=>{
                    const user = item.user                     
                    if (user) {
                        setActiveGroup(undefined);
                        setActiveUser(user);
                    }
                });

                return () => {
                    ccGroupDeleted.unsubscribe();
                    ccGroupMemberAdded.unsubscribe();
                    ccGroupMemberBanned.unsubscribe();
                    ccGroupMemberJoined.unsubscribe();
                    ccGroupMemberKicked.unsubscribe();
                    ccOwnershipChanged.unsubscribe();
                    ccGroupLeft.unsubscribe();
                    ccGroupCreated.unsubscribe();
                    ccOpenChat.unsubscribe();
                }
            }catch(error: any){
                onErrorCallback(error);
            }
        }, [activeGroup, setActiveGroup, setActiveUser, setOpenCreateGroupPage, onErrorCallback, loggedInUser]
    )

    const getJoinGroupHeaderView = useCallback((protectedGroup: CometChat.Group | undefined) => {

        if(!isMobileView) {
            return null;
        }

        let messageHeaderConfiguration = joinGroupConfiguration?.messageHeaderConfiguration;

        return (
            <div slot='headerView' style={getMessageHeaderSlotStyle()}>
                <CometChatMessageHeader
                    group={protectedGroup}
                    subtitleView={messageHeaderConfiguration?.subtitleView}
                    disableUsersPresence={messageHeaderConfiguration?.disableUsersPresence} 
                    protectedGroupIcon={messageHeaderConfiguration?.protectedGroupIcon} 
                    privateGroupIcon={messageHeaderConfiguration?.privateGroupIcon} 
                    menu={messageHeaderConfiguration?.menu} 
                    messageHeaderStyle={getJoinGroupMessageHederStyle(groupsWithMessagesStyle, messageHeaderConfiguration, theme)}
                    backButtonIconURL={messageHeaderConfiguration?.backButtonIconURL} 
                    hideBackButton={messageHeaderConfiguration?.hideBackButton || false} 
                    onError={messageHeaderConfiguration?.onError} 
                    onBack={messageHeaderConfiguration?.onBack || closeJoinGroup} 
                    listItemStyle={getListItemStyle(messageHeaderConfiguration, theme)} 
                    statusIndicatorStyle={messageHeaderConfiguration?.statusIndicatorStyle}
                    avatarStyle={messageHeaderConfiguration?.avatarStyle}
                />
            </div>
        )
    }, [groupsWithMessagesStyle, isMobileView, joinGroupConfiguration?.messageHeaderConfiguration, theme]);

    const getCreateGroupComponent = () => {

        return (
            <cometchat-create-group 
                createGroupStyle={JSON.stringify(getCreateGroupStyle(createGroupConfiguration, theme, isMobileView))}
                closeButtonIconURL={createGroupConfiguration?.closeButtonIconURL}
                ref={setCreateGroupRef}></cometchat-create-group>
        )
    }

    const getCreateGroup = () => {

        if(!isMobileView) {
            return (
                <cometchat-backdrop backdropStyle={JSON.stringify(getBackdropStyle(groupsWithMessagesStyle))}>{getCreateGroupComponent()}</cometchat-backdrop>    
            );
        } else {
            return (
                <div className="cc-withmessages__creategroup" style={getCreateGroupWrapperStyle(isMobileView)}>
                    {getCreateGroupComponent()}
                </div>
            )
        }
    }

    const getJoinGroup = () => {
        return (
            <div className="cc-withmessages__joingroup" style={getJoinGroupWrapperStyle(isMobileView)}>
                <cometchat-join-group 
                    group={protectedGroup} 
                    joinGroupStyle={JSON.stringify(getJoinGroupStyle(joinGroupConfiguration, theme))}
                    ref={setJoinGroupRef}>
                    {getJoinGroupHeaderView(protectedGroup)}
                </cometchat-join-group>
            </div>
        )
    }

    const getMessageHeaderConfiguration = () => {
        return {
            ...messagesConfiguration?.messageHeaderConfiguration,
            onBack: messagesConfiguration?.messageHeaderConfiguration?.onBack || closeJoinGroup,
            hideBackButton: messagesConfiguration?.messageHeaderConfiguration?.hideBackButton || (isMobileView ? false : true),
            messageHeaderStyle: {
                ...getMessageHeaderStyle(groupsWithMessagesStyle, messagesConfiguration, isMobileView),
                ...messagesConfiguration?.messageHeaderConfiguration?.messageHeaderStyle
            },
            
        } as MessageHeaderConfiguration;
    }

    function getMessageComposerConfiguration() {
        return {
            ...messagesConfiguration?.messageComposerConfiguration,
            messageComposerStyle: {
                ...getMessageComposerStyle(groupsWithMessagesStyle, messagesConfiguration, isMobileView),
                ...messagesConfiguration?.messageComposerConfiguration?.messageComposerStyle
            }
        } as MessageComposerConfiguration;
    }

    const getDefaultGroupMenus = useCallback(() =>{
        return (<cometchat-icon-button iconURL={CreateIcon} hoverText={localize("CREATE_GROUP")} buttonStyle={JSON.stringify(createGroupButtonStyle(theme))} ref={createGroupButtonRef}></cometchat-icon-button>);
    }, [theme]);

    const getGroupsComponent = () => {

        return (
            <CometChatGroups
                activeGroup={activeGroup ?? undefined}
                hideSearch={groupsConfiguration?.hideSearch}
                searchIconURL={groupsConfiguration?.searchIconURL}
                searchRequestBuilder={groupsConfiguration?.searchRequestBuilder}
                onItemClick={groupsConfiguration?.onItemClick || onItemClick}
                groupsStyle={getGroupsStyle(groupsConfiguration)}
                subtitleView={groupsConfiguration?.subtitleView}
                options={groupsConfiguration?.options ?? undefined}
                privateGroupIcon={groupsConfiguration?.privateGroupIcon}
                passwordGroupIcon={groupsConfiguration?.protectedGroupIcon}
                groupsRequestBuilder={groupsConfiguration?.groupsRequestBuilder}
                emptyStateView={groupsConfiguration?.emptyStateView}
                onSelect={groupsConfiguration?.onSelect}
                loadingIconURL={groupsConfiguration?.loadingIconURL}
                errorStateView={groupsConfiguration?.errorStateView}
                loadingStateView={groupsConfiguration?.loadingStateView}
                titleAlignment={groupsConfiguration?.titleAlignment}
                listItemView={groupsConfiguration?.listItemView}
                menus={groupsConfiguration?.menu || getDefaultGroupMenus()}
                hideSeparator={groupsConfiguration?.hideSeparator}
                hideError={groupsConfiguration?.hideError}
                selectionMode={groupsConfiguration?.selectionMode}
                listItemStyle={groupsConfiguration?.listItemStyle}
                avatarStyle={groupsConfiguration?.avatarStyle}
                statusIndicatorStyle={groupsConfiguration?.statusIndicatorStyle}
            />
        )
    }

    const getSidebarContent = () => {
        return (
            <div className="cc-groups-with-messages__sidebar" style={getWithMessagesSidebarStyle(groupsWithMessagesStyle, theme, isMobileView, activeGroup)}>
                {getGroupsComponent()}
            </div>
        )
    }

    const getMessagesComponent = () => {
        return (
            <CometChatMessages
                user = {activeUser || undefined}
                group = {activeGroup || undefined}
                messageHeaderConfiguration = {getMessageHeaderConfiguration()}
                messageListConfiguration = {messagesConfiguration?.messageListConfiguration}
                messageComposerConfiguration = {getMessageComposerConfiguration()}
                messagesStyle={getMessagesStyle(groupsWithMessagesStyle)}
                customSoundForIncomingMessages = {messagesConfiguration?.customSoundForIncomingMessages}
                customSoundForOutgoingMessages = {messagesConfiguration?.customSoundForOutgoingMessages}
                detailsConfiguration = {messagesConfiguration?.detailsConfiguration}
                disableSoundForMessages = {messagesConfiguration?.disableSoundForMessages}
                disableTyping = {messagesConfiguration?.disableTyping}
                hideMessageComposer = {messagesConfiguration?.hideMessageComposer}
                hideMessageHeader = {messagesConfiguration?.hideMessageHeader}
                messageComposerView = {messagesConfiguration?.messageComposerView}
                messageHeaderView = {messagesConfiguration?.messageHeaderView}
                messageListView = {messagesConfiguration?.messageListView}
            />
        )
    }

    const getMainContent = () => {
        return (
            (activeGroup || activeUser) ?
                <div className="cc-groups-with-messages__main" style={getWithMessagesMainStyle(groupsWithMessagesStyle, isMobileView, (activeGroup))}>
                    {getMessagesComponent()}
                </div>
            : null
        )
    }

    const getDecoratorMessageContent = () => {
        return (
            <div className="cc-decorator-message--empty" style={getEmptyMessageLayoutStyle(isMobileView, activeGroup)}>
                <cometchat-label text={messageText} labelStyle={JSON.stringify(getLabelStyle(groupsWithMessagesStyle, theme))}></cometchat-label>
            </div>
        );
    }

    const getDecoratorMessage = () => !(activeGroup || activeUser) && !openPasswordModal && !protectedGroup ? getDecoratorMessageContent() : null

    Hooks(
        loggedInUser,
        setLoggedInUser,
        subscribeToEvents,
        onErrorCallback,
        isMobileView,
        joinGroupConfiguration,
        group,
        setActiveGroup,
        messagesConfiguration,
        onBack,
        createGroupElement,
        createGroupButtonRef,
        openCreateGroup,
        closeCreateGroup,
        joinGroupElement,
        onGroupJoined,
        createGroupConfigCreateClick,
        joinGroupConfigOnError,
        joinGroupConfigJoinClick
    );

    return(
        <>
            <div className="cc-groups-with-messages__wrapper" style={getGroupsWrapperStyles(groupsWithMessagesStyle, theme)}>
                {getSidebarContent()}
                {getMainContent()}
                {getDecoratorMessage()}
                {openPasswordModal && !(activeUser || activeGroup) && protectedGroup ? getJoinGroup() : null}
                {openCreateGroupPage ? getCreateGroup() : null}
            </div>
        </>
    );
}

CometChatGroupsWithMessages.defaultProps = defaultProps;
export { CometChatGroupsWithMessages };