import { SmartReplyExtensionDecorator } from "./SmartRepliesExtensionDecorator";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { SmartRepliesConfiguration } from "./SmartRepliesConfiguration";
import { CometChatTheme } from "@cometchat/uikit-resources";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class SmartReplyExtension extends ExtensionsDataSource {
    private configuration?: SmartRepliesConfiguration;
    private theme?: CometChatTheme;

  constructor(configuration?: SmartRepliesConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new SmartReplyExtensionDecorator(dataSource, this.configuration, this.theme)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.smartReply;
  }
}