import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { LinkPreviewConfiguration } from "./LinkPreviewConfiguration";
import { LinkPreviewExtensionDecorator } from "./LinkPreviewExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class LinkPreviewExtension extends ExtensionsDataSource {
  private configuration?: LinkPreviewConfiguration;

  constructor(configuration?: LinkPreviewConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new LinkPreviewExtensionDecorator(dataSource, this.configuration)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.linkPreview;
  }
}