import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { ImageModerationConfiguration } from "./ImageModerationConfiguration";
import { ImageModerationExtensionDecorator } from "./ImageModerationExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class ImageModerationExtension extends ExtensionsDataSource {
  private configuration?: ImageModerationConfiguration;

  constructor(configuration?: ImageModerationConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new ImageModerationExtensionDecorator(dataSource, this.configuration)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.imageModeration
  }
}