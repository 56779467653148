import {  useContext, useEffect, useState } from 'react';
import { CometChatTheme, States, localize } from '@cometchat/uikit-resources';
import loadingIconURL from '../assets/loading.svg'
import emptyIcon from '../assets/ai-empty.svg'
import errorIcon from '../assets/ai-error.svg'
import {   containerStyle, contentContainerStyle, defaultViewStyle, emptyLabelStyle, errorLabelStyle, getCardViewStyle, viewContainerStyle } from './style';
import { CometChatThemeContext } from '../../CometChatThemeContext';
import { AIConversationStarterConfiguration, AIConversationStarterStyle, CardViewStyle } from '@cometchat/uikit-shared';
interface CardProps  {
  onClick?: (theme?:CometChatTheme)=> Promise<JSX.Element>;
  cardViewStyle?:CardViewStyle;
  configuration?:AIConversationStarterConfiguration
};

const defaultProps:CardProps = {
  onClick: undefined,
  cardViewStyle:undefined,
  configuration:undefined
}

const ConversationStarterView = (props:CardProps) => {
  const [messageListState, setMessageListState] = useState<States>(
    States.loading
  );  const [activeView, setActiveView] = useState<JSX.Element| null>(null);
  const theme = useContext(CometChatThemeContext)

const [cardViewStyle, setCardViewStyle] = useState<AIConversationStarterStyle>({})

  const errorStateText:string = localize("SOMETHING_WRONG");
  const emptyStateText:string = localize("NO_MESSAGES_FOUND");
  const loadingStateText:string = localize("GENERATING_ICEBREAKERS");
useEffect(() => {
  
  setCardViewStyle({...getCardViewStyle(theme.theme,props.configuration?.conversationStarterStyle)})
  fetchButtonContent();
}, []);

function fetchButtonContent(){
  setMessageListState(States.loading)
  if(props && props.onClick){
    props.onClick(theme.theme).then((response)=>{
  
 if(response){
  setMessageListState(States.loaded)
  setActiveView(response)
 }
 else{
  setMessageListState(States.empty)
 }
    }).catch((err)=>{
      console.log(err)
      setMessageListState(States.error)

    })
  }

}
  /**
   * Creates a view based on the value of the `state` prop
   */
  function getStateView(): JSX.Element | null {
    let res: JSX.Element | null = null;
    switch (messageListState) {
   
      case States.loading:
        res = getLoadingView();
        break;
      case States.error:
        res = getErrorView();
        break;
      case States.empty:
        res = getEmptyView();
        break;
      case States.loaded:
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const x: never = messageListState;
    }
    return res;
  }
    /**
   * Creates the loading view
   */
    function getLoadingView(): JSX.Element {
      let LoadingView = props.configuration?.LoadingStateView
      if(LoadingView){
        return <LoadingView/>
      }
     else{
      return (
        <div
          className="cc-list__loading-view-wrapper"
          style={viewContainerStyle(cardViewStyle)}
        >
         <div
            className="cc-list__loading-view cc-list__loading-view--default"
            style={defaultViewStyle(cardViewStyle)}
          >
            <cometchat-loader
              iconURL={ props.configuration?.loadingIconURL ??  loadingIconURL}
              loaderStyle={JSON.stringify({iconTint:cardViewStyle.loadingIconTint})}
            />
            <cometchat-label 
                   text={loadingStateText}
                   labelStyle={JSON.stringify(errorLabelStyle(cardViewStyle))}
            ></cometchat-label>
          </div>
        </div>
      );
     }
    }

    /**
     * Creates the error view
     */
    function getErrorView(): JSX.Element | null {
      let ErrorView = props.configuration?.ErrorStateView
      if(ErrorView){
        return <ErrorView/>
      }

  else{
    return (
      <div className="cc-list__error-view-wrapper" style={viewContainerStyle(cardViewStyle)}>
        <div
          style={defaultViewStyle(cardViewStyle)}
          className="cc-list__error-view cc-list__error-view--default"
        >
               <cometchat-loader
              iconURL={ props.configuration?.errorIconURL ??  errorIcon}
              loaderStyle={JSON.stringify({iconTint:cardViewStyle.errorIconTint})}            />
          <cometchat-label
            text={errorStateText}
            labelStyle={JSON.stringify(errorLabelStyle(cardViewStyle))}
          />
        </div>
      </div>
    );
  }
    }
  
    /**
     * Creates the empty view
     */
    function getEmptyView(): JSX.Element {
      let EmptyView = props.configuration?.ErrorStateView
      if(EmptyView){
        return <EmptyView/>
      }

  else{
    return (
      <div className="cc-list__empty-view-wrapper" style={viewContainerStyle(cardViewStyle)}>
             
        <div
          style={defaultViewStyle(cardViewStyle)}
          className="cc-list__empty-view cc-list__empty-view--default"
        >
               <cometchat-loader
              iconURL={ props.configuration?.emptyIconURL ??   emptyIcon}
              loaderStyle={JSON.stringify({iconTint:cardViewStyle.emptyIconTint})}/>
          <cometchat-label
            text={emptyStateText}
            labelStyle={JSON.stringify(emptyLabelStyle(cardViewStyle))}
          />
        </div>
      
      </div>
    );
  }
    }

  return (
    <div className="slideable-container" style={{...containerStyle,...{minHeight:"50px",width:"100%"}}}>
      <div  style={contentContainerStyle}>
      <div style={{height:"100%", marginTop:"12px", overflow:"hidden auto"}} >
      {   messageListState == States.loaded ? activeView : getStateView()}
    
    </div>
       
      </div>
    </div>
  );
};
ConversationStarterView.defaultProps = defaultProps
export default ConversationStarterView;
