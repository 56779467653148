import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";
import { CSSProperties } from "react";
import { CardViewStyle,AIButtonsStyle,CardStyle, AISmartRepliesStyle } from "@cometchat/uikit-shared";
import { PopoverStyle } from "@cometchat/uikit-elements";
import { CardStyleType } from "./CardView";

export function viewContainerStyle(style?:CardViewStyle): CSSProperties {
    return {
      height: "100%",
      width:"100%",
      overflow: "auto",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      position:"absolute",
      bottom:"0px",
      boxSizing:"border-box",
      left:"0px"
    };
  }
  
   export function defaultViewStyle(style?:CardViewStyle): CSSProperties {
    return {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection:"column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      rowGap:"8px"
    };
  }
  

  

  
  
   export function errorLabelStyle(style?:CardViewStyle) {
    return {
      textFont:  style?.errorStateTextFont,
      textColor:  style?.errorStateTextColor,
    };
  }
  
   export function emptyLabelStyle(style?:CardViewStyle) {
    return {
      textFont:  style?.emptyStateTextFont,
      textColor:  style?.emptyStateTextColor,
    };
  }
  export function loadingLabelStyle(style?:CardViewStyle) {
    return {
      textFont:  style?.loadingStateTextFont,
      textColor:  style?.loadingStateTextColor,
    };
  }
  
  export function getTitleStyle(style?:CardViewStyle) {
    return {
      textFont:  style?.titleTextFont,
      textColor:  style?.titleTextColor,
    };
  }

  export function tabButtonStyle(style?:AISmartRepliesStyle):any{
   return  {
        alignItems: "center",
        opacity: "1",
        cursor: "pointer",
        height:style?.buttonHeight,
        width: style?.buttonWidth,
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        padding: "8px",
        border:style?.buttonBorder,
        borderRadius:style?.buttonBorderRadius,
        background:style?.buttonBackground,
        buttonTextFont:style?.buttonTextFont,
        buttonTextColor:style?.buttonTextColor,
      }
  }
  export function getPopoverStyle(style?:CardViewStyle){
    return new PopoverStyle({
        height:style?.height,
        width:style?.width,
        background:style?.background,
        border:style?.border,
        borderRadius:style?.borderRadius,
        boxShadow:style?.boxShadow
    })
  }
  export function getCardViewStyle(theme:CometChatTheme, style?:CardViewStyle){
let defaultStyle = new CardViewStyle({
    titleTextColor:theme?.palette?.getAccent(),
    titleTextFont:fontHelper(theme?.typography.title2),
    loadingIconTint:theme?.palette?.getAccent600(),
    emptyStateTextColor:theme?.palette?.getAccent600(),
    errorStateTextColor:theme?.palette?.getAccent600(),
    emptyStateTextFont:fontHelper(theme?.typography.title2),
    errorStateTextFont:fontHelper(theme?.typography.title2),
    loadingStateTextColor:theme?.palette?.getAccent600(),
    loadingStateTextFont:fontHelper(theme?.typography.title2),
    backIconTint:theme?.palette?.getPrimary(),
    background:theme?.palette?.getBackground(),
    border:theme?.palette.getAccent100(),
    borderRadius:"8px",
    height:"200px",
    width:"320px",
    boxShadow:`0px 0px 0px 1px ${theme?.palette.getAccent100()}`,
    buttonIconTint:theme?.palette?.getAccent600(),
    errorIconTint:theme?.palette?.getAccent600(),
    emptyIconTint:theme?.palette?.getAccent600(),


})
cardViewStyleObject =  {...defaultStyle,...style}
return {...defaultStyle,...style}
  }
  export const containerStyle: React.CSSProperties = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent:"flex-start",
    flexDirection:"column",
    alignItems:"center"
    
  };
  export const contentContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: "transparent",
    overflow: 'hidden',
    width:"100%"
  };
export function getBackButtonStyle(style?:CardViewStyle):any {
return {
  height:"24px",
width:"24px",
  buttonIconTint:style?.backIconTint,
  border:"none",
  borderRadius:"8px",
  position: "absolute",
  top: "0",
  left: "8px",
  background:"transparent",
  zIndex:"2"
}
}

export function getRepliesStyle(theme:CometChatTheme, configStyles?:AIButtonsStyle,listItemStyle?:CardStyle):any{
  return {
    font:configStyles?.repliesTextFont || listItemStyle?.repliesTextFont || fontHelper(theme.typography.text3),
    color:configStyles?.repliesTextColor || listItemStyle?.repliesTextColor || theme.palette.getAccent700(),
    background:"transparent",
     textAlign:"left"
  }
}
export const buttonContainerStyle:React.CSSProperties = {
    rowGap:"12px",
    display:"flex",
    flexDirection:"column",
    padding:"8px",
    boxSizing:"border-box",
    justifyContent:"flex-start",
    overflow:"hidden auto",
    height:"100%",
    background:"transparent"
} 

export function getRepliesWrapperStyle (theme:CometChatTheme, configStyles?:AISmartRepliesStyle,listItemStyle?:CardStyle):any {
return {
  display:"flex",
  alignItems:"center",
  padding:"8px",
  justifyContent:"space-between",
  margin:"4px 0",
  background:configStyles?.repliesTextBackground || listItemStyle?.repliesTextBackground ||theme.palette.getAccent100(),
  border:configStyles?.repliesTextBorder || listItemStyle?.repliesTextBorder || "none",
  borderRadius:configStyles?.repliesTextBorderRadius || listItemStyle?.repliesTextBorderRadius  ||"8px",
  boxSizing: "border-box",
  cursor:"pointer"
}

}

export function getButtonStyles(cardViewStyle:CardViewStyle, style?:AISmartRepliesStyle, listItemStyle?:AISmartRepliesStyle,theme?:CometChatTheme):AISmartRepliesStyle {
  let defaultStyle:AISmartRepliesStyle = {
    buttonHeight:  "35px",
    buttonWidth:  "100%",
    buttonBorder:  `1px solid ${theme?.palette?.getAccent200()}`,
    buttonBackground: theme?.palette?.getAccent100(),
    buttonTextColor:theme?.palette?.getAccent600(),
    buttonTextFont:fontHelper(theme?.typography?.text2!),
    buttonBorderRadius: "8px"

    
  }
  return {...defaultStyle,...cardViewStyle,...listItemStyle,...style }

}


export var cardViewStyleObject:CardStyleType = {}