import { CometChat } from "@cometchat/chat-sdk-javascript";

abstract class ExtensionsDataSource {
  abstract addExtension(): void;
  abstract getExtensionId(): string;

  enable(): void {
    CometChat.isExtensionEnabled(this.getExtensionId()).then(
      (enabled: Boolean) => {
        if (enabled) this.addExtension();
      }, error => {}
    );
  }
}

export { ExtensionsDataSource };
