import { AISmartRepliesConfiguration } from "@cometchat/uikit-shared";
import { DataSource } from "../../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import CometChat from '@cometchat/chat-sdk-javascript'
export class AISmartRepliesExtensionDecorator extends DataSourceDecorator {
  public configuration?:AISmartRepliesConfiguration;
  constructor(dataSource:DataSource,configuration?:AISmartRepliesConfiguration){
  super(dataSource);
  this.configuration  = configuration;
}
  override getId(): string {
    return "aismartreply";
  }
}


