import { CometChat } from "@cometchat/chat-sdk-javascript"
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources"
import { MessageHeaderStyle } from "@cometchat/uikit-shared"
import React, {CSSProperties} from 'react';

export const getMessageHeaderWrapperStyle = (messageHeaderStyle: MessageHeaderStyle, theme: CometChatTheme) => {

    return {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.getAccent50()}`,
        borderRadius: 'inherit',
        background: theme.palette.getBackground(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '8px',
        boxSizing: 'border-box',
        ...messageHeaderStyle
    } as CSSProperties
}

export const MessageHeaderDivStyle = () => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 'inherit'
    } as CSSProperties
}

export const MessageHeaderBackButtonStyle = () => {
    return {
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
}

export const MessageHeaderListItemStyle = () => {
    return {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}

export const MessageHeaderMenuStyle = () => {
    return {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
}

export const CometChatListItemStyle = () => {
    return {
        width: '100%'
    }
}

export const subtitleStyle = (user : CometChat.User | undefined, isTypingRef : React.MutableRefObject<boolean>, messageHeaderStyle : MessageHeaderStyle, theme : CometChatTheme) => {
    // if (user && user.getStatus() === CometChatUIKitConstants.userStatusType.online) {
    //     return {
    //         textFont: messageHeaderStyle?.subtitleTextFont || fontHelper(theme.typography.subtitle2),
    //         textColor: messageHeaderStyle?.onlineStatusColor || theme.palette.getPrimary()
    //     }
    // } else {
    //     return {
    //         textFont: isTypingRef?.current ? getMessageHeaderStyle?.typingIndicatorTextFont : getMessageHeaderStyle?.subtitleTextFont,
    //         textColor: isTypingRef?.current ? getMessageHeaderStyle?.typingIndicatorTextColor : getMessageHeaderStyle?.subtitleTextColor
    //     }
    // }
    if(isTypingRef && isTypingRef.current) {
        return {
            textFont: messageHeaderStyle?.typingIndicatorTextFont || fontHelper(theme.typography.subtitle2),
            textColor: messageHeaderStyle?.typingIndicatorTextColor || theme.palette.getPrimary()
        }
    } else {
        return {
            textFont: messageHeaderStyle?.subtitleTextFont || fontHelper(theme.typography.subtitle2),
            textColor: messageHeaderStyle?.subtitleTextColor || theme.palette.getAccent600()
        }
    }
}

export const defaultAvatarStyle = (theme : CometChatTheme) => {
    return {
        borderRadius: "24px",
        width: "36px",
        height: "36px",
        border: "none",
        backgroundColor: theme.palette.getAccent700(),
        nameTextColor: theme.palette.getAccent900(),
        backgroundSize: "cover",
        nameTextFont: fontHelper(theme.typography.subtitle1),
        outerViewBorder: "",
        outerViewBorderSpacing: ""
    }
}

export const defaultStatusIndicatorStyle = () => {
    return {
        height: "12px",
        width: "12px",
        border: "none",
        borderRadius: "24px"
    }
}

export const defaultListItemStyle = (theme : CometChatTheme) => {
    return {
        height: "45px",
        width: "100%",
        background: "inherit",
        activeBackground: "transparent",
        borderRadius: "0",
        titleFont: fontHelper(theme.typography.title2),
        titleColor: theme.palette.getAccent(),
        border: "none",
        separatorColor: "",
        hoverBackground: "transparent"
    }
}

export const getBackButtonStyle = (theme: CometChatTheme) => {
    return {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: "none",
        background: "transparent",
        buttonIconTint: theme.palette.getPrimary()
    }
}
