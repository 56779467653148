import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";
import { CSSProperties } from "react";
import { CardViewStyle,AIButtonsStyle } from "@cometchat/uikit-shared";
import { PopoverStyle } from "@cometchat/uikit-elements";

export function viewContainerStyle(style:CardViewStyle): CSSProperties {
    return {
      height: "100%",
      width:"100%",
      overflow: "auto",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      // position:"absolute",
      // bottom:"8px",
      boxSizing:"border-box",
      margin:"0 16px"
    };
  }
  export function getCardViewStyle(theme:CometChatTheme, style?:CardViewStyle){
    let defaultStyle = new CardViewStyle({
        titleTextColor:theme?.palette?.getAccent(),
        titleTextFont:fontHelper(theme?.typography.title2),
        loadingIconTint:theme?.palette?.getAccent600(),
        emptyStateTextColor:theme?.palette?.getAccent600(),
        errorStateTextColor:theme?.palette?.getAccent600(),
        emptyStateTextFont:fontHelper(theme?.typography.title2),
        errorStateTextFont:fontHelper(theme?.typography.title2),
        loadingStateTextColor:theme?.palette?.getAccent600(),
        loadingStateTextFont:fontHelper(theme?.typography.title2),
        backIconTint:theme?.palette?.getPrimary(),
        background:theme?.palette?.getBackground(),
        border:theme?.palette.getAccent100(),
        borderRadius:"8px",
        height:"200px",
        width:"320px",
        boxShadow:`0px 0px 0px 1px ${theme?.palette.getAccent100()}`,
        buttonIconTint:theme?.palette?.getAccent600()
    
    
    })
    return {...defaultStyle,...style}
      }
   export function defaultViewStyle(style:CardViewStyle): CSSProperties {
    return {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      textAlign: "center",
      columnGap:"8px"
    };
  }
  

  

  
  
   export function errorLabelStyle(style:CardViewStyle) {
    return {
      textFont:  style?.errorStateTextFont,
      textColor:  style?.errorStateTextColor,
    };
  }
  
   export function emptyLabelStyle(style:CardViewStyle) {
    return {
      textFont:  style?.emptyStateTextFont,
      textColor:  style?.emptyStateTextColor,
    };
  }

  export const containerStyle: React.CSSProperties = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent:"flex-start",
    flexDirection:"column"
    
  };
  export const contentContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "transparent",
    overflow: 'hidden',
  };


export const getSmartReplyStyle = (theme?:CometChatTheme,style?:AIButtonsStyle)=> {
  return {
      replyTextFont: style?.repliesTextFont || fontHelper((theme as CometChatTheme).typography.caption1),
      replyTextColor: style?.repliesTextColor || theme?.palette.getAccent(),
      replyBackground: style?.repliesTextBackground || "inherit",
      boxShadow:  `0px 0px 1px ${theme?.palette.getAccent600()}`,
      background: style?.background || "inherit",
      width: style?.width || "100%",
      height: style?.height || "100%",
      border: style?.repliesTextBorder || "none",
      borderRadius: style?.repliesTextBorderRadius || "8px",
      display: "flex",
      justifyContent: "flex-start"
  }
}