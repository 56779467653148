import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator"; 
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource"; 
import {TextModeratorExtensionDecorator} from "./TextModeratorExtensionDecorator"; 
import { ExtensionsId } from "@cometchat/uikit-shared";
import { CometChat } from "@cometchat/chat-sdk-javascript";

export class TextModeratorExtension extends ExtensionsDataSource {

  override addExtension(): void {
    ChatConfigurator.enable(dataSource => new TextModeratorExtensionDecorator(dataSource));
  }

  override getExtensionId(): string {
    return ExtensionsId.profanityFilter;
  }

  override enable(): void {
    const promise1 = CometChat.isExtensionEnabled(ExtensionsId.profanityFilter);
    const promise2 = CometChat.isExtensionEnabled(ExtensionsId.dataMasking);
    Promise.all([promise1, promise2]).then((values) => {
      if(values.includes(true))
      {
        this.addExtension();
      }
    }).catch(error => {});
  }
 
}