
import { DataSource } from "../Shared/Framework/DataSource";
import { ChatConfigurator } from "./../Shared/Framework/ChatConfigurator";
import { AIEnablerDecorator } from "./AIEnablerDecorator";
import {CometChat} from "@cometchat/chat-sdk-javascript";
import { AISmartRepliesExtension } from "./AISmartReplies/AISmartReplies";
import { AIConversationStarterExtension } from "./AIConversationStarter/AIConversationStarter";
import { AIEnablerConfiguration } from "@cometchat/uikit-shared";
import { AIExtensionDataSource } from "./utils";
export class AIEnabler extends AIExtensionDataSource {
    defaultAIFeatures:AIExtensionDataSource[] | undefined;
    configuration?:AIEnablerConfiguration = {}
    constructor(defaultFeatures?:AIExtensionDataSource[], configuration?:AIEnablerConfiguration){
        super()
        this.configuration = configuration
        this.defaultAIFeatures = defaultFeatures
    }
  override addExtension(): void {

  }
  override getExtensionId(): string {
    return "ai-enabler";
  }
  override enable(): void {
    let features: any = {};
  
    const processFeatures = (feature: AIExtensionDataSource) => {
      return CometChat.isAIFeatureEnabled(feature.getExtensionId())
        .then((response) => {
          if (response) {
       
            if (feature.getExtensionId() !== "conversation-starter") {
              feature?.enable();
              features[feature.getExtensionId()] = {
                enabled: true,
                configuration: feature.getConfiguration(),
                enabler: feature,
              };
            }
            else{
              feature.setConfiguration(this.configuration)
              feature.enable();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
  
    if (this.defaultAIFeatures && this.defaultAIFeatures.length >= 1) {
      const promises = this.defaultAIFeatures.map((feature) =>
        processFeatures(feature)
      );
  
      Promise.all(promises)
        .then(() => {
          if (Object.keys(features).length > 0) {
            ChatConfigurator.enable(
              (dataSource: DataSource) =>
                new AIEnablerDecorator(dataSource, features, this.configuration)
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (this.defaultAIFeatures?.length === 0) {
      return;
    } else {
      const defaultFeatures: AIExtensionDataSource[] = [
        new AISmartRepliesExtension(),
        new AIConversationStarterExtension(),
      ];
  
      const promises = defaultFeatures.map((feature) =>
        processFeatures(feature)
      );
  
      Promise.all(promises)
        .then(() => {
          if (Object.keys(features).length > 0) {
            ChatConfigurator.enable(
              (dataSource: DataSource) =>
                new AIEnablerDecorator(dataSource, features, this.configuration)
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }


}