import { MessageTranslationExtensionDecorator } from "./MessageTranslationExtensionDecorator";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { MessageTranslationConfiguration } from "./MessageTranslationConfiguration";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class MessageTranslationExtension extends ExtensionsDataSource {
  private configuration?: MessageTranslationConfiguration;

  constructor(configuration?: MessageTranslationConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new MessageTranslationExtensionDecorator(dataSource, this.configuration)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.messageTranslation;
  }
}