import {
  AvatarStyle,
  DateStyle,
  ReceiptStyle,
} from "@cometchat/uikit-elements";
import {
  CometChatTheme,
  CometChatUIKitConstants,
  MessageBubbleAlignment,
  MessageListAlignment,
  fontHelper,
} from "@cometchat/uikit-resources";
import {
  ListStyle,
  MessageListStyle,
  StickersConstants,
} from "@cometchat/uikit-shared";

import { CSSProperties } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";

export const MessageListUnreadLabelStyle = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as CSSProperties;
};

export const MessageListHeaderStyle = () => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "auto",
  } as CSSProperties;
};

export const MessageListFooterStyle = () => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "auto",
  } as CSSProperties;
};

export const MessageListDivStyle = () => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    boxSizing: "border-box",
  } as CSSProperties;
};

export const MessageListWrapperStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
  return {
    height: "100%",
    width: "100%",
    flex: "1 1 0",
    order: "2",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.getAccent50()}`,
    borderRadius: "inherit",
    background: theme.palette.getBackground(),
    ...messageListStyle,
  } as CSSProperties;
};

export const MessageThreadViewStyle = (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  loggedInUser: any
) => {
  if (
    (!message.getSender() ||
      message.getSender().getUid() === loggedInUser?.getUid()) &&
    message?.getType() === CometChatUIKitConstants.MessageTypes.text
  ) {
    return {
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "0",
      background: "transparent",
      padding: "8px",
      buttonIconTint: theme.palette.getAccent500(),
      display: "flex",
      flexFlow: "row-reverse",
      alignItems: "center",
      justifyContent: "space-between",
      buttonTextFont: fontHelper(theme.typography.text2),
      buttonTextColor: theme.palette.getAccent("dark"),
      iconWidth: "18px",
      iconHeight: "18px",
    };
  } else {
    return {
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "0",
      background: "transparent",
      padding: "8px",
      buttonIconTint: theme.palette.getAccent500(),
      display: "flex",
      flexFlow: "row-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
      buttonTextColor: theme.palette.getPrimary(),
      iconWidth: "18px",
      iconHeight: "18px",
    };
  }
};

export const dividerStyle = (theme: CometChatTheme) => {
  return {
    height: "1px",
    width: "100%",
    background: theme.palette.getAccent100(),
  };
};

export const unreadMessageStyle = (theme: CometChatTheme) => {
  return {
    height: "100%",
    width: "100%",
    background: theme.palette.getPrimary(),
    display: "flex",
    justifyContent: "center",
    buttonTextFont: fontHelper(theme.typography.text2),
    buttonTextColor: theme.palette.getAccent("dark"),
    border: "none",
    borderRadius: "12px",
  };
};

export const MessageListMessageIndicatorStyle = () => {
  return {
    height: "25px",
    width: "150px",
  };
};

export const MessageBubbleStyle = (
  message: CometChat.BaseMessage,
  theme: CometChatTheme,
  alignment: MessageListAlignment | undefined,
  loggedInUser: any
) => {
  if (message.getDeletedAt()) {
    return {
      background: "transparent",
      border: `1px dashed ${theme.palette.getAccent400()}`,
      borderRadius: "12px",
    };
  } else if (
    message?.getType() === CometChatUIKitConstants.calls.meeting &&
    (!message?.getSender() ||
      message?.getSender().getUid() === loggedInUser.getUid())
  ) {
    return {
      background: theme.palette.getPrimary(),
      border: `none`,
      borderRadius: "12px",
    };
  } else if (message?.getType() === StickersConstants.sticker) {
    return {
      background: "transparent",
      borderRadius: "12px",
    };
  } else if (
    !message.getDeletedAt() &&
    message.getCategory() === CometChatUIKitConstants.MessageCategory.message &&
    message.getType() === CometChatUIKitConstants.MessageTypes.text &&
    (!message.getSender() || loggedInUser?.uid === message.getSender().getUid())
  ) {
    return {
      background:
        alignment === MessageListAlignment.left
          ? theme.palette.getAccent100()
          : theme.palette.getPrimary(),
      borderRadius: "12px",
    };
  } else if (
    !message.getDeletedAt() &&
    message.getCategory() === CometChatUIKitConstants.MessageCategory.message &&
    message.getType() === CometChatUIKitConstants.MessageTypes.audio
  ) {
    return {
      borderRadius: "",
      background: theme.palette.getAccent100(),
    };
  } else if (
    message.getType() === CometChatUIKitConstants.MessageTypes.groupMember ||
    message.getCategory() === CometChatUIKitConstants.MessageCategory.call
  ) {
    return {
      background: "transparent",
      borderRadius: "12px",
      border: `1px solid ${theme.palette.getAccent100()}`,
    };
  } else if (
    !message.getDeletedAt() &&
    message.getCategory() ===
      CometChatUIKitConstants.MessageCategory.interactive
  ) {
    return {
      background: theme.palette.getAccent100(),
      width: "300px"
    }
  }else {
    if (
      message.getSender() &&
      message.getSender().getUid() !== loggedInUser?.uid
    ) {
      return {
        background: theme.palette.getAccent100(),
        borderRadius: "12px",
      };
    } else {
      return {
        background: theme.palette.getAccent100(),
        borderRadius: "12px",
      };
    }
  }
};

export const MessageReceiptStyle = (theme: CometChatTheme) => {
  return new ReceiptStyle({
    waitIconTint: theme.palette.getAccent700(),
    sentIconTint: theme.palette.getAccent600(),
    deliveredIconTint: theme.palette.getAccent600(),
    readIconTint: theme.palette.getPrimary(),
    errorIconTint: theme.palette.getError(),
    height: "20px",
    width: "20px",
  });
};

export const messageFooterViewStyle = (
  alignment: MessageBubbleAlignment
): CSSProperties => {
  let justifyContent = "center";
  if (alignment === MessageBubbleAlignment.right) {
    justifyContent = "flex-end";
  } else if (alignment === MessageBubbleAlignment.left) {
    justifyContent = "flex-start";
  }

  return {
    display: "flex",
    justifyContent: justifyContent,
    alignItems: "center",
  };
};

export const MessageBubbleDateStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
  return {
    textColor:
      messageListStyle?.TimestampTextColor || theme.palette.getAccent600(),
    textFont:
      messageListStyle?.TimestampTextFont ||
      fontHelper(theme.typography.caption2),
    padding: "0",
  };
};

export const MessageLabelStyle = (theme: CometChatTheme) => {
  return {
    textFont: fontHelper(theme.typography.caption2),
    textColor: theme.palette.getAccent600(),
  };
};

export const MessageDateStyle = (
  dateSeparatorStyle: DateStyle | undefined,
  theme: CometChatTheme
) => {
  return new DateStyle({
    textFont:
      dateSeparatorStyle?.textFont || fontHelper(theme.typography.subtitle2),
    textColor: dateSeparatorStyle?.textColor || theme.palette.getAccent600(),
    background: dateSeparatorStyle?.background || "inherit",
    height: dateSeparatorStyle?.height || "100%",
    width: dateSeparatorStyle?.width || "100%",
    border:
      dateSeparatorStyle?.border || `1px solid ${theme.palette.getAccent100()}`,
    borderRadius: dateSeparatorStyle?.borderRadius || "8px",
    // padding: "10px 3px",
  });
};

export const TempMessageListStyle = (theme: CometChatTheme) => {
  return new MessageListStyle({
    background: theme.palette.getBackground(),
    border: `none`,
    emptyStateTextFont: fontHelper(theme.typography.title1),
    emptyStateTextColor: theme.palette.getAccent600(),
    errorStateTextFont: fontHelper(theme.typography.title1),
    errorStateTextColor: theme.palette.getAccent600(),
    loadingIconTint: theme.palette.getAccent600(),
    nameTextFont: fontHelper(theme.typography.title2),
    nameTextColor: theme.palette.getAccent600(),
    threadReplySeparatorColor: theme.palette.getAccent400(),
    threadReplyTextFont: fontHelper(theme.typography.subtitle1),
    threadReplyIconTint: theme.palette.getAccent600(),
    threadReplyTextColor: theme.palette.getAccent600(),
    TimestampTextFont: fontHelper(theme.typography.caption2),
    TimestampTextColor: theme.palette.getAccent600(),
  });
};

export const EmptyViewStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
  let tempMessageListStyle = {
    ...TempMessageListStyle(theme),
    ...messageListStyle,
  };
  return {
    textFont: tempMessageListStyle.emptyStateTextFont,
    textColor: tempMessageListStyle.emptyStateTextColor,
  };
};

export const LoadingViewStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
  let tempMessageListStyle = {
    ...TempMessageListStyle(theme),
    ...messageListStyle,
  };
  return {
    iconTint: tempMessageListStyle.loadingIconTint,
  };
};

export const ErrorViewStyle = (
  messageListStyle: MessageListStyle | undefined,
  theme: CometChatTheme
) => {
  let tempMessageListStyle = {
    ...TempMessageListStyle(theme),
    ...messageListStyle,
  };
  return {
    textFont: tempMessageListStyle.errorStateTextFont,
    textColor: tempMessageListStyle.errorStateTextColor,
  };
};

export const MessageAvatarStyle = (
  avatarStyle: AvatarStyle | undefined,
  theme: CometChatTheme
) => {
  let defaultAvatarStyle = new AvatarStyle({
    borderRadius: "24px",
    width: "28px",
    height: "28px",
    border: "none",
    backgroundColor: theme.palette.getAccent700(),
    nameTextColor: theme.palette.getAccent900(),
    backgroundSize: "cover",
    nameTextFont: fontHelper(theme.typography.subtitle1),
    outerViewBorderWidth: "0",
    outerViewBorderRadius: "0",
    outerViewBorderColor: "",
    outerViewBorderSpacing: "0",
  });
  return { ...defaultAvatarStyle, ...avatarStyle };
};

export const DateBubbleStyle = () => {
  return {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "16px",
  };
};

export const defaultWrapperMessageBubbleStyle = {
  height: "100%",
  width: "100%",
  border: "",
  borderRadius: "",
  background: "",
};

export const defaultAvatarStyle = {
  borderRadius: "24px",
  width: "36px",
  height: "36px",
};

export const defaultDateSeparatorStyle = {
  textFont: "500 16px Inter",
  textColor: "rgba(20, 20, 20, 0.58)",
  background: "",
  height: "",
  width: "",
  border: "1px solid grey",
  borderRadius: "8px",
};

export const defaultMessageListStyle = {
  nameTextFont: "600 15px Inter, sans-serif",
  nameTextColor: "white",
  TimestampTextFont: "",
  TimestampTextColor: "",
  threadReplySeparatorColor: "",
  threadReplyTextFont: "",
  threadReplyIconTint: "",
  threadReplyTextColor: "",
  emptyStateTextFont: "700 22px Inter, sans-serif",
  emptyStateTextColor: "#bcbcbc",
  errorStateTextFont: "700 22px Inter, sans-serif",
  errorStateTextColor: "#bcbcbc",
  loadingIconTint: "grey",
};

export const bubbleStyle = (
  alignment: MessageBubbleAlignment
): CSSProperties => {
  let justifyContent = "center";
  if (alignment === MessageBubbleAlignment.right) {
    justifyContent = "flex-end";
  } else if (alignment === MessageBubbleAlignment.left) {
    justifyContent = "flex-start";
  }

  return {
    display: "flex",
    justifyContent: justifyContent,
    width: "100%",
    marginBottom: "16px",
  };
};

export const defaultMessageListBubbleStyle = {
  padding: "0px 0px 0px 15px",
  // maxHeight: "20em",
  overflow: "scroll",
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "15px",
};

export const getListStyle = () => {
  return new ListStyle({
    height: "100%",
    width: "100%",
    background: "inherit",
  });
};
