
import {CometChat} from "@cometchat/chat-sdk-javascript";
import { CometChatMessageEvents, CometChatTheme, CometChatUIEvents, CometChatUIKitConstants, IActiveChatChanged, PanelAlignment, localize } from "@cometchat/uikit-resources";
import { DataSource } from "../../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import {  CometChatConversationStarter } from "../utils";
import {  getSmartReplyStyle } from "./style";
import {AIConversationStarterConfiguration} from '@cometchat/uikit-shared'
import ConversationStarterView from "./ConversationStarterView";
export class AIConversationStarterDecorator extends DataSourceDecorator {
  public configuration?:AIConversationStarterConfiguration;
  public currentMessage:CometChat.BaseMessage | null = null;
  public loggedInUser!:CometChat.User | null;
  messageListenerId = 'message_' + new Date().getTime();
  public user!: CometChat.User;
  public group!: CometChat.Group;
  public theme:CometChatTheme = new CometChatTheme({});
  private LISTENER_ID: string = "smartreply__listener";

  constructor(dataSource:DataSource, configuration?:AIConversationStarterConfiguration){
  super(dataSource)
  this.configuration = configuration;
 setTimeout(() => {
  this.addMessageListener()
 }, 1000);
}
override getId(): string {
  return "aiconversationstarter";
}
editReply(reply: string) {
this.closePanel();
CometChatUIEvents.ccComposeMessage.next(reply);
}
closePanel = () => {
  CometChatUIEvents.ccHidePanel.next(PanelAlignment.messageListFooter)
}
closeIfMessageReceived(message:CometChat.BaseMessage){
  if(message?.getReceiverId() == this.loggedInUser?.getUid()){
    this.closePanel()
  }
}
getConversationStarter =(theme?:CometChatTheme):Promise<JSX.Element> =>{ 
  this.theme = theme ?? new CometChatTheme({}) 
  return new Promise((resolve, reject) => {
      let receiverId:string = this.user ? this.user?.getUid() : this.group?.getGuid();
      let receiverType:string = this.user ? CometChatUIKitConstants.MessageReceiverType.user : CometChatUIKitConstants.MessageReceiverType.group;
      if(this.configuration?.customView){
        CometChat.getConversationStarter(receiverId,receiverType).then((response:any)=>{         
           this.configuration?.customView!(response).then((res:any)=>{
                  return resolve(res)

           })
           .catch((err:CometChat.CometChatException)=>{
              return reject(err)
           })
   
       
       })
       .catch((err:CometChat.CometChatException)=>{
        return  reject(err)
       })
      }
      else if(this.configuration?.onLoad){
          this.configuration?.onLoad(this.user,this.group).then((response:any)=>{
            return resolve(response)

           })
           .catch((err:CometChat.CometChatException)=>{
             return  reject(err)
           })
          
      }
     else{
      CometChat.getConversationStarter(receiverId,receiverType).then((response:any)=>{
          let repliesArray:string[] = []
       Object.keys(response).forEach((reply)=>{
          if(response[reply] && response[reply] != ""){
            repliesArray.push(response[reply])
          }
        
       })
           
       return resolve(<CometChatConversationStarter 
        smartReplyStyle={getSmartReplyStyle(this.theme,this.configuration?.conversationStarterStyle)} 
        replies={repliesArray}
        ccReplyClicked={(e : any)=>{ this.editReply(e?.detail?.reply)}}
        key={"conversation-starter"}
         closeIconURL=""
    ></CometChatConversationStarter>)
   
       
       })
       .catch((err:CometChat.CometChatException)=>{
        return  reject(err)
       })
     }
          
  })
}
private addMessageListener(): void {
  CometChat.getLoggedinUser().then(
      (user: CometChat.User | null) => {
          if(user){
              this.loggedInUser = user
          }
      }
  );

  CometChatMessageEvents.onTextMessageReceived.subscribe((message: CometChat.TextMessage) => {
    this.closeIfMessageReceived(message)
  });
  CometChatMessageEvents.onCustomMessageReceived.subscribe((message: CometChat.CustomMessage) => {
    this.closeIfMessageReceived(message)
  });
  CometChatMessageEvents.onMediaMessageReceived.subscribe((message: CometChat.MediaMessage) => {
    this.closeIfMessageReceived(message)
  });

  CometChatUIEvents.ccActiveChatChanged.subscribe(
      (data: IActiveChatChanged) => {
        console.log(data)
          this.currentMessage = data.message!;
          this.user = data.user!;
          this.group = data.group!;
         if(!this.currentMessage){
          CometChatUIEvents.ccShowPanel.next({configuration: this.configuration, message: this.currentMessage!, child: <ConversationStarterView configuration={this.configuration} onClick={this.getConversationStarter} />, position:PanelAlignment.messageListFooter});

         }
      }
  )
  CometChatMessageEvents.ccMessageSent.subscribe(
      () => {
        CometChatUIEvents.ccHidePanel.next(PanelAlignment.messageListFooter)
        this.currentMessage = null;
      }
  )
}
}



