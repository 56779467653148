import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useRef, useState, useCallback } from "react";
import {
  localize,
  CometChatCallEvents,
  CallWorkflow,
} from "@cometchat/uikit-resources";
import { CometChatUIKitCalls } from "@cometchat/uikit-shared";
import { CallscreenStyle } from "@cometchat/uikit-elements";
import IncreaseSizeIcon from "./assets/increase-size.svg";
import ReduceSizeIcon from "./assets/reduce-size.svg";
import { Hooks } from "./hooks";
import { callScreenWrapperStyle } from "./style";

interface IOngoingCallProps {
  callSettingsBuilder?: typeof CometChatUIKitCalls.CallSettings;
  sessionID: string;
  ongoingCallStyle?: CallscreenStyle;
  resizeIconHoverText?: string;
  minimizeIconURL?: string;
  maximizeIconURL?: string;
  onError?: Function;
  callWorkflow?: CallWorkflow;
}
const defaultProps: IOngoingCallProps = {
  resizeIconHoverText: localize("RESIZE"),
  sessionID: "",
  minimizeIconURL: ReduceSizeIcon,
  maximizeIconURL: IncreaseSizeIcon,
  callSettingsBuilder: undefined,
  ongoingCallStyle: {
    maxHeight: "100%",
    maxWidth: "100%",
    border: "none",
    borderRadius: "0",
    background: "grey",
    minHeight: "400px",
    minWidth: "400px",
    minimizeIconTint: "white",
    maximizeIconTint: "white",
  },
  onError: (error: CometChat.CometChatException) => {
    console.log(error);
  },
  callWorkflow: CallWorkflow.defaultCalling,
};

const CometChatOngoingCall = (props: IOngoingCallProps) => {
  const [loggedInUser, setLoggedInuser] = useState<CometChat.User | null>(null);
  const callScreenFrameRef = useRef<HTMLDivElement | null>(null);

  const {
    resizeIconHoverText,
    sessionID,
    minimizeIconURL,
    maximizeIconURL,
    ongoingCallStyle,
    callSettingsBuilder,
    onError,
    callWorkflow,
  } = props;

  const onErrorCallback = useCallback(
    (error: any) => {
      if (!(error instanceof CometChat.CometChatException)) {
        let errorModel = {
          code: error?.code,
          name: error?.name,
          message: error?.message,
          details: error?.details,
        };
        let errorObj = new CometChat.CometChatException(errorModel);
        onError?.(errorObj);
      } else {
        onError?.(error);
      }
    },
    [onError]
  );

  const getCallBuilder = useCallback((): any => {
    if (callSettingsBuilder) {
      return callSettingsBuilder;
    } else {
      let audioOnlyCall: boolean = false;
      const callSettings: any = new CometChatUIKitCalls.CallSettingsBuilder()
        .enableDefaultLayout(true)
        .setIsAudioOnlyCall(audioOnlyCall)
        .setCallListener(
          new CometChatUIKitCalls.OngoingCallListener({
            onCallEnded: () => {
              CometChatUIKitCalls.endSession();
              if (callWorkflow === CallWorkflow.defaultCalling) {
                CometChatCallEvents.ccCallEnded.next({} as CometChat.Call);
              }
            },
            onCallEndButtonPressed: () => {
              if (callWorkflow === CallWorkflow.defaultCalling) {
                CometChat.endCall(sessionID)
                  .then((call: CometChat.Call) => {
                    CometChatUIKitCalls.endSession();
                    CometChatCallEvents.ccCallEnded.next(call);
                  })
                  .catch((err: CometChat.CometChatException) => {
                    onErrorCallback(err);
                  });
              } else {
                CometChatCallEvents.ccCallEnded.next({} as CometChat.Call);
                CometChatUIKitCalls.endSession();
              }
            },
            onError: (error: any) => {
              onErrorCallback(error);
            },
          })
        )
        .build();
      return callSettings;
    }
  }, [callSettingsBuilder, callWorkflow, onErrorCallback, sessionID]);

  const startCall = useCallback(() => {
    // const ccCallScreenRef = document.getElementById("cc-callscreen_ref");
    if (loggedInUser) {
      const authToken = loggedInUser!.getAuthToken();

      CometChatUIKitCalls.generateToken(sessionID, authToken).then(
        (res: any) => {
          CometChatUIKitCalls.startSession(
            res?.token,
            getCallBuilder(),
            callScreenFrameRef.current
          );
        },
        (err: any) => {
          onErrorCallback(err);
        }
      );
    } else {
      CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
        const authToken = user!.getAuthToken();

        CometChatUIKitCalls.generateToken(sessionID, authToken).then(
          (res: any) => {
            CometChatUIKitCalls.startSession(
              res?.token,
              getCallBuilder(),
              callScreenFrameRef.current
            );
          },
          (err: any) => {
            onErrorCallback(err);
          }
        );
      });
    }
  }, [sessionID, getCallBuilder, loggedInUser, onErrorCallback]);

  Hooks(setLoggedInuser, sessionID, startCall);
  return sessionID !== "" ? (
    <cometchat-callscreen-wrapper
      callscreenStyle={JSON.stringify(ongoingCallStyle)}
      resizeIconHoverText={resizeIconHoverText}
      minimizeIconURL={minimizeIconURL}
      maximizeIconURL={maximizeIconURL}
      sessionID={sessionID}
    >
      <div
        id="cc-callscreen_ref"
        style={callScreenWrapperStyle()}
        className="cc-callscreen__view"
        ref={callScreenFrameRef}
      ></div>
    </cometchat-callscreen-wrapper>
  ) : (
    <></>
  );
};

CometChatOngoingCall.defaultProps = defaultProps;
export { CometChatOngoingCall };
