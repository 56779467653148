import { CometChatUserEvents, localize } from "@cometchat/uikit-resources";
import { MessageComposerConfiguration, MessageHeaderConfiguration, MessagesConfiguration, UsersConfiguration, WithMessagesStyle } from "@cometchat/uikit-shared";
import { getEmptyMessageLayoutStyle, getLabelStyle, getMessageComposerStyle, getMessageHeaderStyle, getMessagesStyle, getUsersStyle, getUsersWrapperStyles, getWithMessagesMainStyle, getWithMessagesSidebarStyle } from "./style";
import { useCallback, useContext, useState } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatMessages } from "../CometChatMessages";
import { CometChatThemeContext } from "../CometChatThemeContext";
import { CometChatUsers } from "../CometChatUsers";
import { Hooks } from "./hooks";

interface IUsersWithMessagesProps {
    user?: CometChat.User,
    isMobileView?: boolean,
    messageText?: string,
    usersWithMessagesStyle?: WithMessagesStyle,
    messagesConfiguration?: MessagesConfiguration,
    usersConfiguration?: UsersConfiguration,
    onError?: Function,
}

const defaultProps: IUsersWithMessagesProps = {
    user: undefined,
    isMobileView: false,
    messageText: localize("SELECT__USER"),
    usersWithMessagesStyle: {},
    messagesConfiguration: new MessagesConfiguration({}),
    usersConfiguration: new UsersConfiguration({}),
    onError: (error: CometChat.CometChatException) => { console.log(error) },
};

const CometChatUsersWithMessages = (props: IUsersWithMessagesProps) => {
    const { theme } = useContext(CometChatThemeContext);
    const {
        user,
        isMobileView,
        messageText,
        usersWithMessagesStyle,
        messagesConfiguration,
        usersConfiguration,
        onError
    } = props;

    const [activeUser, setActiveUser] = useState<CometChat.User | undefined>(user ?? undefined);

    const onBack = () => setActiveUser(undefined);

    const onErrorCallback = useCallback(
        (error: any) => {
            if (!(error instanceof CometChat.CometChatException)) {
                let errorModel = {
                    code: error?.code,
                    name: error?.name,
                    message: error?.message,
                    details: error?.details
                }
                let errorObj = new CometChat.CometChatException(errorModel);
                onError!(errorObj);
            } else {
                onError!(error);
            }
        }, [onError]
    );

    const onItemClick = (user: CometChat.User) => setActiveUser(user);

    const subscribeToEvents = useCallback(
        () => {
            try {
                const ccUserBlocked = CometChatUserEvents.ccUserBlocked.subscribe(
                    (user: CometChat.User) => {
                        if (activeUser && activeUser.getUid() === user.getUid()) {
                            setActiveUser(user);
                        }
                    }
                );
                const ccUserUnBlocked = CometChatUserEvents.ccUserUnblocked.subscribe(
                    (user: CometChat.User) => {
                        if (activeUser && activeUser.getUid() === user.getUid()) {
                            setActiveUser(user);
                        }
                    }
                );

                return () => {
                    try {
                        ccUserBlocked?.unsubscribe();
                        ccUserUnBlocked?.unsubscribe();
                    } catch (error: any) {
                        onErrorCallback(error);
                    }
                }
            } catch (error: any) {
                onErrorCallback(error);
            }
        }, [activeUser, setActiveUser, onErrorCallback]
    )

    function getMessageHeaderConfiguration() {

        return {
            ...messagesConfiguration?.messageHeaderConfiguration,
            onBack: messagesConfiguration?.messageHeaderConfiguration?.onBack || onBack,
            hideBackButton: messagesConfiguration?.messageHeaderConfiguration?.hideBackButton || (isMobileView ? false : true),
            messageHeaderStyle: {
                ...getMessageHeaderStyle(usersWithMessagesStyle, messagesConfiguration, isMobileView),
                ...messagesConfiguration?.messageHeaderConfiguration?.messageHeaderStyle
            },
        } as MessageHeaderConfiguration;
    }

    function getMessageComposerConfiguration() {
        return {
            ...messagesConfiguration?.messageComposerConfiguration,
            messageComposerStyle: {
                ...getMessageComposerStyle(usersWithMessagesStyle, messagesConfiguration, isMobileView),
                ...messagesConfiguration?.messageComposerConfiguration?.messageComposerStyle
            }
        } as MessageComposerConfiguration;
    }

    const getUsersComponent = () => {
        return (
            <CometChatUsers
                activeUser={activeUser ?? undefined}
                hideSearch={usersConfiguration?.hideSearch}
                searchIconURL={usersConfiguration?.searchIconURL}
                searchRequestBuilder={usersConfiguration?.searchRequestBuilder}
                onItemClick={usersConfiguration?.onItemClick || onItemClick}
                usersStyle={getUsersStyle(usersConfiguration)}
                subtitleView={usersConfiguration?.subtitleView}
                options={usersConfiguration?.options ?? undefined}
                usersRequestBuilder={usersConfiguration?.usersRequestBuilder}
                emptyStateView={usersConfiguration?.emptyStateView}
                onSelect={usersConfiguration?.onSelect}
                loadingIconURL={usersConfiguration?.loadingIconURL}
                errorStateView={usersConfiguration?.errorStateView}
                loadingStateView={usersConfiguration?.loadingStateView}
                tileAlignment={usersConfiguration?.titleAlignment}
                showSectionHeader={usersConfiguration?.showSectionHeader}
                listItemView={usersConfiguration?.listItemView}
                menus={usersConfiguration?.menu}
                hideSeparator={usersConfiguration?.hideSeparator}
                hideError={usersConfiguration?.hideError}
                selectionMode={usersConfiguration?.selectionMode}
                listItemStyle={usersConfiguration?.listItemStyle}
                disableUsersPresence={usersConfiguration?.disableUsersPresence}
                statusIndicatorStyle={usersConfiguration?.statusIndicatorStyle}
                avatarStyle={usersConfiguration?.avatarStyle}
            />
        )
    }

    const getSidebarContent = () => {
        return (
            <div className="cc-users-with-messages__sidebar" style={getWithMessagesSidebarStyle(usersWithMessagesStyle, theme, isMobileView, activeUser)}>
                {getUsersComponent()}
            </div>
        )
    }

    const getMessagesComponent = () => {
        return (
            <CometChatMessages
                user={activeUser}
                messageHeaderConfiguration={getMessageHeaderConfiguration()}
                messageListConfiguration={messagesConfiguration?.messageListConfiguration}
                messageComposerConfiguration={getMessageComposerConfiguration()}
                messagesStyle={getMessagesStyle(usersWithMessagesStyle)}
                customSoundForIncomingMessages={messagesConfiguration?.customSoundForIncomingMessages}
                customSoundForOutgoingMessages={messagesConfiguration?.customSoundForOutgoingMessages}
                detailsConfiguration={messagesConfiguration?.detailsConfiguration}
                disableSoundForMessages={messagesConfiguration?.disableSoundForMessages}
                disableTyping={messagesConfiguration?.disableTyping}
                hideMessageComposer={messagesConfiguration?.hideMessageComposer}
                hideMessageHeader={messagesConfiguration?.hideMessageHeader}
                messageComposerView={messagesConfiguration?.messageComposerView}
                messageHeaderView={messagesConfiguration?.messageHeaderView}
                messageListView={messagesConfiguration?.messageListView}
            />
        )
    }

    const getMainContent = () => {
        return activeUser ? (
            <div className="cc-users-with-messages__main" style={getWithMessagesMainStyle(usersWithMessagesStyle, isMobileView, activeUser)}>
                {getMessagesComponent()}
            </div>
        ) : null;
    }

    const getDecoratorMessageContent = () => {
        return (
            <div className="cc-decorator-message--empty" style={getEmptyMessageLayoutStyle(isMobileView, activeUser)}>
                <cometchat-label text={messageText} labelStyle={JSON.stringify(getLabelStyle(usersWithMessagesStyle, theme))}></cometchat-label>
            </div>
        )
    }

    const getDecoratorMessage = () => !activeUser ? getDecoratorMessageContent() :  null

    Hooks(
        subscribeToEvents,
        user,
        setActiveUser
    );

    return (
        <div className="cc-users-with-messages__wrapper" style={getUsersWrapperStyles(usersWithMessagesStyle, theme)}>
            {getSidebarContent()}
            {getMainContent()}
            <>
                {getDecoratorMessage()}
            </>
        </div>
    );
}

CometChatUsersWithMessages.defaultProps = defaultProps;
export { CometChatUsersWithMessages };