import {  AISmartRepliesConfiguration } from "@cometchat/uikit-shared";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import {  AISmartRepliesExtensionDecorator } from "./AISmartRepliesDecorator";
import { AIExtensionDataSource } from "../utils";
export class AISmartRepliesExtension extends AIExtensionDataSource {
  private configuration?: AISmartRepliesConfiguration;

constructor(configuration?: AISmartRepliesConfiguration) {
  super();
  this.configuration = configuration;
}
  override addExtension(): void {
    ChatConfigurator.enable((dataSource: any) => new AISmartRepliesExtensionDecorator(dataSource,this.configuration));
  }
  override getExtensionId(): string {
    return "smart-replies";
  }
  override enable(): void {
    this.addExtension()
    
  }
  getConfiguration():AISmartRepliesConfiguration{
    return this.configuration!
      }


}
