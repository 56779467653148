import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { PollsConfiguration } from "./PollsConfiguration";
import { PollsExtensionDecorator } from "./PollsExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class PollsExtension extends ExtensionsDataSource {
    private configuration?: PollsConfiguration;
  
    constructor(configuration?: PollsConfiguration) {
      super();
      this.configuration = configuration;
    }
  
    override addExtension(): void {
      ChatConfigurator.enable(
        (dataSource: any) => new PollsExtensionDecorator(dataSource, this.configuration)
      );
    }
  
    override getExtensionId(): string {
      return ExtensionsId.polls;
    }
}