import { AIConversationStarterConfiguration,AIEnablerConfiguration } from "@cometchat/uikit-shared";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import {  AIConversationStarterDecorator } from "./AIConversationStarterDecorator";
import { AIExtensionDataSource } from "../utils";
export class AIConversationStarterExtension extends AIExtensionDataSource {
  configuration?:AIConversationStarterConfiguration;
  constructor(configuration?:AIConversationStarterConfiguration){
    super()
    this.configuration = configuration

  }
  override addExtension(): void {
    ChatConfigurator.enable((dataSource: any) => new AIConversationStarterDecorator(dataSource,this.configuration));
  }
  override getExtensionId(): string {
    return "conversation-starter";
  }
  override enable(): void {
    this.addExtension()
  }
 override getConfiguration():AIConversationStarterConfiguration{
    return this.configuration!
      }
      override setConfiguration(configuration?:AIEnablerConfiguration){
        this.configuration = {...this.configuration,...configuration}
        this.configuration.conversationStarterStyle = {
          ...this.configuration.conversationStarterStyle,
          ...(configuration?.listItemStyle || configuration?.listStyle)
        };

      }

}