import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { CollaborativeWhiteboardConfiguration } from "./CollaborativeWhiteboardConfiguration";
import { CollaborativeWhiteBoardExtensionDecorator } from "./CollaborativeWhiteboardExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class CollaborativeWhiteboardExtension extends ExtensionsDataSource {
  private configuration?: CollaborativeWhiteboardConfiguration;

  constructor(configuration?: CollaborativeWhiteboardConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new CollaborativeWhiteBoardExtensionDecorator(dataSource, this.configuration)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.whiteboard;
  }
}