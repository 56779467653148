import { AIEnablerConfiguration, BaseStyle, SmartReplies } from "@cometchat/uikit-shared";
import { Card } from "./CardView";
import { createComponent } from "@lit-labs/react";
import React from "react";
import { ExtensionsDataSource } from "@cometchat/uikit-shared";

export interface AIConfigurations {
    "smart-replies"?: { enabled: boolean, configuration?: any},
    "conversation-starter"?: { enabled: boolean, configuration?: any}
  }
  export interface ICard {
    smartReply?: Card,
    conversationStarter?: Card
  }


  export const CometChatConversationStarter = createComponent({
    tagName: 'smart-replies',
    elementClass: SmartReplies,
    react: React,
    events: {
        'ccReplyClicked': 'cc-reply-clicked',
        'ccCloseClicked': 'cc-close-clicked'
    }
});


export abstract class AIExtensionDataSource  {
  getConfiguration():any{
   
  };
  setConfiguration(config?:AIEnablerConfiguration):void{
   
  };
  abstract addExtension(): void;
  abstract getExtensionId(): string;
  enable(): void {

}

}