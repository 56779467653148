import { CometChatMessageEvents, CometChatTheme, CometChatUIEvents, CometChatUIKitConstants, localize, MessageStatus, Placement } from "@cometchat/uikit-resources";
import { DataSource } from "../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../Shared/Framework/DataSourceDecorator";
import aiBot from './assets/ai-bot.svg';
import CardView, { Card } from "./CardView";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { AIConversationStarterConfiguration, AIEnablerConfiguration, AISmartRepliesConfiguration, CardViewStyle, CometChatUIKitUtility } from "@cometchat/uikit-shared";
import { CometChatButton } from "../Shared/Views/CometChatButton";
import { getButtonStyles, getCardViewStyle, getPopoverStyle, getRepliesStyle, getRepliesWrapperStyle } from "./style";
import { AIConfigurations } from "./utils";

export class AIEnablerDecorator extends DataSourceDecorator {
    smartReplyConfiguration:AISmartRepliesConfiguration = new AISmartRepliesConfiguration({});
    conversationStarterConfiguration:AIConversationStarterConfiguration = new AIConversationStarterConfiguration({});
    features?:AIConfigurations = {};
    configuration?:AIEnablerConfiguration = new AIEnablerConfiguration({});
    public newDataSource!: DataSource;
    public showAiButton: boolean = false; 
    public theme!: CometChatTheme;
    public id: any;
    public user!: CometChat.User;
    public group!: CometChat.Group;
    public loggedInUser!: CometChat.User | null;
    public cardViewStyle:CardViewStyle = {};
    public buttonRef:any;
    public btnStyle = {
          height:"24px",
          width:"24px",
          buttonIconTint:"#cacaca",
          border:"none",
          borderRadius:"8px",
          background:"transparent",
          marginLeft:"5px"
        }
    onButtonClick = (id:string):Promise<JSX.Element> => {
        return new Promise((resolve, reject) => {
            if(id == "ai-smart-replies"){
                this.getSmartReplies().then((replies)=>{
                    return resolve(replies)
                    
                })
                .catch((err)=>{
                    return reject(err)
                })
            }

                
        })
      }
    public cardsData:ICard = {}

    public callBackOfChild:any;
constructor(dataSource:DataSource, features?:AIConfigurations,configuration?:AIEnablerConfiguration){
    super(dataSource);
  if(configuration){
    this.configuration = configuration;
    
  }

    this.newDataSource = dataSource;
    this.features = features;
    CometChat.getLoggedinUser().then(
        (user: CometChat.User | null) => {
            if(user){
                this.loggedInUser = user
            }
        }
    );
}
getDataSource() {
    return this.newDataSource;
}
override getId(): string {
    return "aienabler";
  }
  editReply(reply:string){
    if(this.callBackOfChild){
        this.callBackOfChild()
        if(this.buttonRef){
            this.buttonRef?.click()
        }
    }
CometChatUIEvents.ccComposeMessage.next(reply)
  }
  closePopover = () => {
    if(this.buttonRef){
        this.buttonRef?.click()
    }
  }

   getSmartReplies():Promise<JSX.Element>{
    return new Promise((resolve, reject) => {
        let receiverId:string = this.user ? this.user?.getUid() : this.group?.getGuid();
        let receiverType:string = this.user ? CometChatUIKitConstants.MessageReceiverType.user : CometChatUIKitConstants.MessageReceiverType.group;
        if(this.smartReplyConfiguration?.customView){
            CometChat.getSmartReplies(receiverId,receiverType).then((response)=>{
             this.smartReplyConfiguration?.customView!(response,this.closePopover).then((res:any)=>{
              return resolve(res)

             })
             .catch((err:CometChat.CometChatException)=>{
                return  reject(err)
             })
            })
            .catch((err:CometChat.CometChatException)=>{
                return  reject(err)
            })
        }
        else if(this.smartReplyConfiguration?.onClick){
            this.smartReplyConfiguration?.onClick(this.user,this.group,this.closePopover).then((response:any)=>{
              return resolve(response)
             
             })
             .catch((err:CometChat.CometChatException)=>{
               return  reject(err)
             })
            
        }
     else{
        CometChat.getSmartReplies(receiverId,receiverType).then((response:any)=>{
            let view:JSX.Element[] = [] 
            Object.keys(response).forEach((reply)=>{
                if(response[reply] && response[reply] != ""){
                 view.push(<div style={getRepliesWrapperStyle(this.theme, this.smartReplyConfiguration?.smartRepliesStyle,this.configuration?.listItemStyle)}  onClick={()=>this.editReply(response[reply])}>
                    <span style={getRepliesStyle(this.theme, this.smartReplyConfiguration?.smartRepliesStyle,this.configuration?.listItemStyle)} >{response[reply]} </span>
            
                 </div>)
                }
                
             })
             return resolve(<div className='cc__scroll-view' style={{overflow:"hidden auto", height:"100%",background:"inherit",border:"inherit",borderRadius:"inherit"}}>{view}</div>)
         
         })
         .catch((err:CometChat.CometChatException)=>{
           return  reject(err)
         })
     }
            
    })

  }

override getAuxiliaryOptions(id: Map<String, any>, theme: CometChatTheme, user?: CometChat.User, group?: CometChat.Group) {
    this.cardViewStyle = getCardViewStyle(theme, this.configuration?.listStyle)

    this.cardsData =  {
        smartReply:{
           title:localize("SUGGEST_A_REPLY"),
           onClick:this.onButtonClick,
           id:"ai-smart-replies",
           style:getButtonStyles(this.cardViewStyle,this.smartReplyConfiguration?.smartRepliesStyle,{...this.configuration?.listItemStyle,...this.configuration?.listStyle},this.theme || new CometChatTheme({})),
           loadingIconURL:this.smartReplyConfiguration?.loadingIconURL,
           errorIconURL:this.smartReplyConfiguration?.errorIconURL,
           emptyIconURL:this.smartReplyConfiguration?.emptyIconURL,
           emptyStateView:this.smartReplyConfiguration?.EmptyStateView,
           errorStateView:this.smartReplyConfiguration?.ErrorStateView,
           loadingStateView:this.smartReplyConfiguration?.LoadingStateView,
    
        }
    };
    this.id = id;
    this.user = user!;
    this.theme = theme
    this.group = group!;
    let auxiliaryOptions:JSX.Element[] = super.getAuxiliaryOptions(id, theme, user, group);
    if(!this.id.parentMessageId){
        auxiliaryOptions.unshift(this.getAIAuxiliaryButton(id, theme, user, group));
    }
    return auxiliaryOptions;
}
 getChildFunction = (someCallback:any)=> {
this.callBackOfChild = someCallback;
}

 childRefCallback = (childRef: React.RefObject<typeof CometChatButton>):void => {
    this.buttonRef = childRef
 };


getAIAuxiliaryButton(id: Map<String, any>, theme: CometChatTheme, user?: CometChat.User, group?: CometChat.Group){
    let cards:Card[] = []
    if (this.features && Object.keys(this.features).length > 0) {

        if (this.features?.["smart-replies"]?.enabled) {
  
          this.smartReplyConfiguration = this.features?.["smart-replies"]?.configuration ?? new AISmartRepliesConfiguration({})
          cards.push(this.cardsData.smartReply!)
        }
      }


 
    let buttonStyle: any = {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: "0",
        buttonIconTint:  this.cardViewStyle.buttonIconTint ||  theme.palette.getAccent600(),
        background: "transparent",
        cursor:"pointer"
    }
    let openIconURL = this.configuration?.buttonIconURL ?   this.configuration?.buttonIconURL :  aiBot;

    return (
        <cometchat-popover placement={Placement.top} popoverStyle={JSON.stringify(getPopoverStyle(this.cardViewStyle))} key='ai-popover'>
            <div slot = "children"> 
            <CometChatButton hoverText="AI" childRefCallback={this.childRefCallback} iconURL={   openIconURL } buttonStyle={buttonStyle} onClick={()=>{  if(this.callBackOfChild){
        this.callBackOfChild()
    }}}/>
            </div>
            <div slot = "content" style={{overflow: "hidden",
    height: "100%",
    padding: "8px",
    boxSizing: "border-box"}}>
               <CardView  backCallback={this.getChildFunction} buttons={cards}/>
            </div>
        </cometchat-popover>
    )
}

}


export interface ICard {
    smartReply?:Card,
    conversationStarter?:Card     
}




