import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { StickersConfiguration } from "./StickersConfiguration";
import { StickersExtensionDecorator } from './StickersExtensionDecorator';
import { ExtensionsId } from "@cometchat/uikit-shared";

export class StickersExtension extends ExtensionsDataSource {
  private configuration?: StickersConfiguration;

  constructor(configuration?: StickersConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new StickersExtensionDecorator(dataSource, this.configuration)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.stickers;
  }
}