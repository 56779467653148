import {
  CometChatMessageTemplate,
  CometChatTheme,
  CometChatUIKitConstants,
  MessageBubbleAlignment
} from "@cometchat/uikit-resources";

export class MessageUtils {
  messageBubbleStyle(
    message: CometChat.BaseMessage,
    theme: CometChatTheme,
    alignment: MessageBubbleAlignment,
    currentUser: any
  ) {
    if (message.getDeletedAt()) {
      return {
        background: "transparent",
        border: `1px dashed ${theme.palette.getAccent400()}`,
        borderRadius: "12px",
      };
    } else if (
      !message.getDeletedAt() &&
      message.getCategory() ===
        CometChatUIKitConstants.MessageCategory.message &&
      message.getType() === CometChatUIKitConstants.MessageTypes.text &&
      (!message.getSender() ||
        currentUser?.getUid() === message.getSender().getUid())
    ) {
      return {
        background:
          alignment === MessageBubbleAlignment.left
            ? theme.palette.getAccent100()
            : theme.palette.getPrimary(),
        borderRadius: "12px",
      };
    } else if (
      !message.getDeletedAt() &&
      message.getCategory() ===
        CometChatUIKitConstants.MessageCategory.message &&
      message.getType() === CometChatUIKitConstants.MessageTypes.audio
    ) {
      return {
        borderRadius: "",
        background: theme.palette.getAccent100(),
      };
    } else if (
      message.getType() === CometChatUIKitConstants.MessageTypes.groupMember
    ) {
      return {
        background: "transparent",
        borderRadius: "12px",
        border: `1px solid ${theme.palette.getAccent100()}`,
      };
    } else {
      if (
        message.getSender() &&
        message.getSender().getUid() !== currentUser?.getUid()
      ) {
        return {
          background: theme.palette.getAccent100(),
          borderRadius: "12px",
        };
      } else {
        return {
          background: theme.palette.getAccent100(),
          borderRadius: "12px",
        };
      }
    }
  }

  getContentView(
    message: CometChat.BaseMessage,
    template: CometChatMessageTemplate
  ) {
    let view;
    const messageTypesMap: { [key: string]: CometChatMessageTemplate } = {};
    messageTypesMap[`${template.category}_${template.type}`] = template;

    if (
      messageTypesMap[`${message?.getCategory()}_${message?.getType()}`] &&
      messageTypesMap[`${message?.getCategory()}_${message?.getType()}`]
        ?.contentView
    ) {
      view = messageTypesMap[
        `${message?.getCategory()}_${message?.getType()}`
      ]?.contentView(message, "left");
      // default would be html string using lit components
      if (typeof view === "string") {
        return {
          html: view,
        };
      }
      return view;
    } else {
      return null;
    }
  }

  getBubbleWrapper(
    message: CometChat.BaseMessage,
    template: CometChatMessageTemplate
  ) {
    let view;
    const messageTypesMap: { [key: string]: CometChatMessageTemplate } = {};
    messageTypesMap[`${template.category}_${template.type}`] = template;

    if (
      messageTypesMap &&
      messageTypesMap[`${message?.getCategory()}_${message?.getType()}`] &&
      messageTypesMap[`${message?.getCategory()}_${message?.getType()}`]
        .bubbleView
    ) {
      view =
        messageTypesMap[`${message?.getCategory()}_${message?.getType()}`]
          .bubbleView(message);
      return view;
    } else {
      view = null;
      return view;
    }
  }

  getMessageBubble(
    baseMessage: CometChat.BaseMessage,
    template: CometChatMessageTemplate,
    messageBubbleStyle: any, 
    alignment: MessageBubbleAlignment
  ) {
    return this.getBubbleWrapper(baseMessage, template)
      ? this.getBubbleWrapper(baseMessage, template)
      : {
          componentName: "CometChatMessageBubble",
          props: {
            id: baseMessage?.getId() || baseMessage?.getMuid(),
            messageBubbleStyle: messageBubbleStyle,
            alignment: alignment,
            replyView: null,
            threadView: null,
            contentView: this.getContentView(baseMessage, template),
          },
        };
  }
}