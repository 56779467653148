import {
  BaseStyle,
  ContactsStyle,
  GroupsConfiguration,
  TabItemStyle,
  UsersConfiguration,
} from "@cometchat/uikit-shared";
import {
  CometChatTabItem,
  CometChatTheme,
  TabAlignment,
  TabsVisibility,
  fontHelper,
  localize,
} from "@cometchat/uikit-resources";
import {
  closeBtnStyle,
  contactsContentStyle,
  contactsHeaderStyle,
  getContactsStyle,
  contactsWrapperStyle,
  getTabsStyle,
  getTabItemStyling,
} from "./style";

import CloseIcon from "./assets/close2x.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../Shared/Views/CometChatButton";
import { CometChatGroups } from "../CometChatGroups";
import { CometChatTabs } from "../Shared/Views/CometChatTabs/index";
import { CometChatThemeContext } from "../CometChatThemeContext";
import { CometChatUsers } from "../CometChatUsers";
import { useContext } from "react";
import React from "react";

interface ContactsProps {
  title?: string;
  usersTabTitle?: string;
  groupsTabTitle?: string;
  usersConfiguration?: UsersConfiguration;
  groupsConfiguration?: GroupsConfiguration;
  // onSubmitButtonClick?: (users? : CometChat.User[],groups?:CometChat.Group[]) => void,
  closeIconURL?: string;
  onClose?: () => void;
  onItemClick?: (user?: CometChat.User, group?: CometChat.Group) => void;
  onError: ((error: CometChat.CometChatException) => void) | null;
  // submitButtonText?: string,
  // hideSubmitButton?: Boolean
  // selectionLimit?: SelectionMode,
  tabVisibility?: TabsVisibility;
  contactsStyle: ContactsStyle;
}

const defaultProps: ContactsProps = {
  title: localize("NEW_CHAT"),
  usersTabTitle: localize("USERS"),
  groupsTabTitle: localize("GROUPS"),
  groupsConfiguration: new GroupsConfiguration({}),
  usersConfiguration: new UsersConfiguration({}),
  onError: (error: CometChat.CometChatException) => {
    console.log(error);
  },
  tabVisibility: TabsVisibility.usersAndGroups,
  contactsStyle: {}
};

const CometChatContacts = (props: ContactsProps) => {

  const [tabs, setTabs] = React.useState<CometChatTabItem[]>([]);
  const [isActiveUsersTab, setIsActiveUsersTab] = React.useState<boolean>(true);
  const [isActiveGroupsTab, setIsActiveGroupsTab] = React.useState<boolean>(false);
  const usersTabRef = React.useRef<CometChatTabItem>();
  const groupsTabRef = React.useRef<CometChatTabItem>();
  const { theme } = useContext(CometChatThemeContext);
  const {
    title,
    usersTabTitle,
    groupsTabTitle,
    closeIconURL,
    onClose,
    usersConfiguration,
    groupsConfiguration,
    onItemClick,
    tabVisibility,
    contactsStyle
  } = props;

  React.useEffect(() => {

    const usersRequestBuilder = new CometChat.UsersRequestBuilder()
    .setLimit(30)
    .hideBlockedUsers(true);
    const usersSearchRequestBuilder = new CometChat.UsersRequestBuilder()
      .setLimit(30)
      .hideBlockedUsers(true);
  
    const groupsRequestBuilder = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true);
    const groupsSearchRequestBuilder = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true);

    usersTabRef.current = new CometChatTabItem({
      id: "users",
      title: usersTabTitle,
      style: getTabItemStyling(theme, tabVisibility, isActiveUsersTab),
      childView: (
        // <div style={{marginTop: "50px"}}>
        <CometChatUsers
          title=""
          activeUser={undefined}
          hideSearch={usersConfiguration?.hideSearch || false}
          searchIconURL={usersConfiguration?.searchIconURL}
          searchRequestBuilder={
            usersConfiguration?.searchRequestBuilder || usersSearchRequestBuilder
          }
          onItemClick={usersConfiguration?.onItemClick || onUserClicked}
          usersStyle={usersConfiguration?.usersStyle}
          subtitleView={usersConfiguration?.subtitleView}
          options={usersConfiguration?.options ?? undefined}
          usersRequestBuilder={
            usersConfiguration?.usersRequestBuilder || usersRequestBuilder
          }
          emptyStateView={usersConfiguration?.emptyStateView}
          onSelect={usersConfiguration?.onSelect}
          loadingIconURL={usersConfiguration?.loadingIconURL}
          errorStateView={usersConfiguration?.errorStateView}
          loadingStateView={usersConfiguration?.loadingStateView}
          tileAlignment={usersConfiguration?.titleAlignment}
          showSectionHeader={usersConfiguration?.showSectionHeader}
          listItemView={usersConfiguration?.listItemView}
          menus={usersConfiguration?.menu}
          hideSeparator={usersConfiguration?.hideSeparator}
          hideError={usersConfiguration?.hideError}
          selectionMode={usersConfiguration?.selectionMode}
          listItemStyle={usersConfiguration?.listItemStyle}
          disableUsersPresence={usersConfiguration?.disableUsersPresence}
          statusIndicatorStyle={usersConfiguration?.statusIndicatorStyle}
          avatarStyle={usersConfiguration?.avatarStyle}
        />
        // </div>
      ),
      isActive: isActiveUsersTab,
    });

    groupsTabRef.current = new CometChatTabItem({
      id: "groups",
      title: groupsTabTitle,
      style: getTabItemStyling(theme, tabVisibility, isActiveGroupsTab),
      childView: (
        <CometChatGroups
          title=""
          activeGroup={undefined}
          hideSearch={groupsConfiguration?.hideSearch || false}
          searchIconURL={groupsConfiguration?.searchIconURL}
          searchRequestBuilder={
            groupsConfiguration?.searchRequestBuilder ||
            groupsSearchRequestBuilder
          }
          onItemClick={onGroupClicked}
          groupsStyle={groupsConfiguration?.groupsStyle}
          subtitleView={groupsConfiguration?.subtitleView}
          options={groupsConfiguration?.options ?? undefined}
          privateGroupIcon={groupsConfiguration?.privateGroupIcon}
          passwordGroupIcon={groupsConfiguration?.protectedGroupIcon}
          groupsRequestBuilder={
            groupsConfiguration?.groupsRequestBuilder || groupsRequestBuilder
          }
          emptyStateView={groupsConfiguration?.emptyStateView}
          onSelect={groupsConfiguration?.onSelect}
          loadingIconURL={groupsConfiguration?.loadingIconURL}
          errorStateView={groupsConfiguration?.errorStateView}
          loadingStateView={groupsConfiguration?.loadingStateView}
          titleAlignment={groupsConfiguration?.titleAlignment}
          listItemView={groupsConfiguration?.listItemView}
          menus={groupsConfiguration?.menu}
          hideSeparator={groupsConfiguration?.hideSeparator}
          hideError={groupsConfiguration?.hideError}
          selectionMode={groupsConfiguration?.selectionMode}
          listItemStyle={groupsConfiguration?.listItemStyle}
        />
      ),
      isActive: isActiveGroupsTab,
    });

    setTabs([usersTabRef.current, groupsTabRef.current]);

  }, []);

  React.useEffect(() => {

    if (tabVisibility === TabsVisibility.groups) {
      setIsActiveGroupsTab(true);
      setIsActiveUsersTab(false);
      setTabs([groupsTabRef.current!]);
    } else if (tabVisibility === TabsVisibility.users) {
      setIsActiveGroupsTab(false);
      setIsActiveUsersTab(true);
      setTabs([usersTabRef.current!]);
    } else if (tabVisibility === TabsVisibility.usersAndGroups) {
      setIsActiveGroupsTab(false);
      setIsActiveUsersTab(true);
      setTabs([usersTabRef.current!, groupsTabRef.current!]);
    }

  }, [tabVisibility])

  const onUserClicked = (user: CometChat.User) => {
    if (onItemClick) {
      onItemClick(user, undefined);
    }
  };

  const onGroupClicked = (group: CometChat.Group) => {
    if (onItemClick) {
      onItemClick(undefined, group);
    }
  };

  function getCloseBtnView() {
    if (closeIconURL === "") return <></>;
    return (
      <CometChatButton
        iconURL={closeIconURL}
        hoverText={localize("CLOSE")}
        buttonStyle={closeBtnStyle(contactsStyle, theme)}
        onClick={onClose}
      />
    );
  }

  return tabs.length ? (
    <div className="cc-contacts-wrapper" style={contactsWrapperStyle(contactsStyle, theme)}>
      <div className="cc-contacts" style={getContactsStyle(contactsStyle, theme)}>
        <div className="cc-contacts-header" style={contactsHeaderStyle(contactsStyle, theme)}>{title}</div>
        <div className="cc-contacts-content" style={contactsContentStyle()}>
          <CometChatTabs
            tabAlignment={TabAlignment.right}
            tabs={tabs}
            tabsStyle={getTabsStyle()}
            // isDraggable={false}
            keepAlive={true}
          />
          {getCloseBtnView()}
        </div>
      </div>
    </div>
  ) : <></>;
};

CometChatContacts.defaultProps = defaultProps;
export { CometChatContacts };
