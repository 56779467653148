import "@cometchat/uikit-elements";

import { AvatarStyle, DateStyle } from "@cometchat/uikit-elements";
import {
  CometChatActionsIcon,
  CometChatActionsView,
  CometChatCallEvents,
  CometChatGroupEvents,
  CometChatMessageEvents,
  CometChatMessageTemplate,
  CometChatUIEvents,
  CometChatUIKitConstants,
  DatePatterns,
  IDialog,
  IGroupLeft,
  IGroupMemberAdded,
  IGroupMemberKickedBanned,
  IGroupMemberScopeChanged,
  IMessages,
  IPanel,
  IShowOngoingCall,
  MessageBubbleAlignment,
  MessageListAlignment,
  MessageStatus,
  States,
  TimestampAlignment,
  localize,
} from "@cometchat/uikit-resources";
import {
  CometChatSoundManager,
  InteractiveMessageUtils,
  MessageInformationConfiguration,
  MessageListStyle,
  MessageReceiptUtils,
} from "@cometchat/uikit-shared";
import {
  DateBubbleStyle,
  EmptyViewStyle,
  ErrorViewStyle,
  LoadingViewStyle,
  MessageAvatarStyle,
  MessageBubbleDateStyle,
  MessageBubbleStyle,
  MessageDateStyle,
  MessageLabelStyle,
  MessageListDivStyle,
  MessageListFooterStyle,
  MessageListHeaderStyle,
  MessageListMessageIndicatorStyle,
  MessageListUnreadLabelStyle,
  MessageListWrapperStyle,
  MessageReceiptStyle,
  MessageThreadViewStyle,
  bubbleStyle,
  defaultAvatarStyle,
  defaultMessageListBubbleStyle,
  defaultMessageListStyle,
  dividerStyle,
  getListStyle,
  messageFooterViewStyle,
  unreadMessageStyle,
} from "./style";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import {
  useCometChatErrorHandler,
  useRefSync,
  useStateRef,
} from "../CometChatCustomHooks";

import { ChatConfigurator } from "../Shared/Framework/ChatConfigurator";
import CloseIcon from "./assets/close2x.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatList } from "../Shared/Views/CometChatList";
import { CometChatMessageBubble } from "../Shared/Views/CometChatMessageBubble";
import { CometChatMessageInformation } from "../CometChatMessageInformation";
import { CometChatThemeContext } from "../CometChatThemeContext";
import DeliveredIcon from "./assets/message-delivered.svg";
import { Hooks } from "./hooks";
import LoadingIcon from "./assets/Spinner.svg";
import { MessageListManager } from "./controller";
import ReadIcon from "./assets/message-read.svg";
import RightArrowIcon from "./assets/side-arrow.svg";
import SentIcon from "./assets/message-sent.svg";
import WaitIcon from "./assets/wait.svg";
import WarningIcon from "./assets/warning-small.svg";

interface IMessageListProps {
  parentMessageId?: number;
  user?: CometChat.User;
  group?: CometChat.Group;
  emptyStateText?: string;
  errorStateText?: string;
  emptyStateView?: any;
  errorStateView?: any;
  loadingStateView?: any;
  disableReceipt?: boolean;
  disableSoundForMessages?: boolean;
  customSoundForMessages?: string;
  readIcon?: string;
  deliveredIcon?: string;
  sentIcon?: string;
  waitIcon?: string;
  errorIcon?: string;
  loadingIconURL?: string;
  alignment?: MessageListAlignment;
  showAvatar?: boolean;
  datePattern?: DatePatterns;
  timestampAlignment?: TimestampAlignment;
  DateSeparatorPattern?: DatePatterns;
  templates?: CometChatMessageTemplate[];
  messagesRequestBuilder?: CometChat.MessagesRequestBuilder;
  newMessageIndicatorText?: string;
  scrollToBottomOnNewMessages?: boolean;
  thresholdValue?: number;
  onThreadRepliesClick?: Function;
  headerView?: any;
  footerView?: any;
  avatarStyle?: AvatarStyle;
  dateSeparatorStyle?: DateStyle;
  messageListStyle?: MessageListStyle;
  onError?: ((error: CometChat.CometChatException) => void) | null;
  hideError?: boolean;
  messageInformationConfiguration?: MessageInformationConfiguration;
}

const defaultProps: IMessageListProps = {
  parentMessageId: 0,
  user: undefined,
  group: undefined,
  emptyStateText: localize("NO_MESSAGES_FOUND"),
  errorStateText: localize("SOMETHING_WRONG"),
  emptyStateView: null,
  errorStateView: null,
  loadingStateView: null,
  disableReceipt: false,
  disableSoundForMessages: false,
  customSoundForMessages: "",
  readIcon: ReadIcon,
  deliveredIcon: DeliveredIcon,
  sentIcon: SentIcon,
  waitIcon: WaitIcon,
  errorIcon: WarningIcon,
  loadingIconURL: LoadingIcon,
  alignment: MessageListAlignment.standard,
  showAvatar: true,
  datePattern: DatePatterns.time,
  timestampAlignment: TimestampAlignment.bottom,
  DateSeparatorPattern: DatePatterns.DayDate,
  templates: [],
  messagesRequestBuilder: undefined,
  newMessageIndicatorText: "",
  scrollToBottomOnNewMessages: false,
  thresholdValue: 1000,
  onThreadRepliesClick: () => {},
  headerView: null,
  footerView: null,
  avatarStyle: defaultAvatarStyle,
  dateSeparatorStyle: undefined,
  messageListStyle: defaultMessageListStyle,
  onError: (error: CometChat.CometChatException) => {
    console.log(error);
  },
  hideError: false,
  messageInformationConfiguration: new MessageInformationConfiguration({}),
};

const CometChatMessageList = (props: IMessageListProps) => {
  const {
    parentMessageId,
    user,
    group,
    emptyStateText,
    errorStateText,
    emptyStateView,
    errorStateView,
    loadingStateView,
    disableReceipt,
    disableSoundForMessages,
    customSoundForMessages,
    readIcon,
    deliveredIcon,
    sentIcon,
    waitIcon,
    errorIcon,
    loadingIconURL,
    alignment,
    showAvatar,
    datePattern,
    timestampAlignment,
    DateSeparatorPattern,
    templates,
    messagesRequestBuilder,
    newMessageIndicatorText,
    scrollToBottomOnNewMessages,
    thresholdValue,
    onThreadRepliesClick,
    headerView,
    footerView,
    avatarStyle,
    dateSeparatorStyle,
    messageListStyle,
    onError,
    hideError,
    messageInformationConfiguration,
  } = props;

  const [messageList, setMessageList] = useState<any[]>([]);
  const [unreadMessageLabel, showUnreadMessageLabel] = useState(false);
  const [scrollListToBottom, setScrollListToBottom] = useState(true);
  const [messageListState, setMessageListState] = useState<States>(
    States.loading
  );
  const [showOngoingCall, setShowOngoingCall] = useState(false);
  const [showMessageInfoPopup, setShowMessageInfoPopup] = useState(false);
  const [activeMessageInfo, setActiveMessageInfo] =
    useState<CometChat.BaseMessage | null>(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSmartReply, setShowSmartReply] = useState(false);
  const [ongoingCallView, setOngoingCallView] = useState(null);
  const [unreadCountBtnElement, setUnreadCountBtnRef] = useStateRef<
    JSX.IntrinsicElements["cometchat-button"] | null
  >(null);
  const itemRefs = useRef<any>({});
  const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);
  const messageListManager = useRef<any>(null);
  const messageIdRef = useRef({ prevMessageId: 0, nextMessageId: 0 });
  const messagesCountRef = useRef(0);
  const UnreadCountRef = useRef<CometChat.BaseMessage[]>([]);
  const newMessageCountRef = useRef<string>("");
  const unreadMessageLabelRef = useRef(false);
  const { theme } = useContext(CometChatThemeContext);
  const imageModerationDialogRef = useRef(null);
  const userRef = useRefSync(user);
  const groupRef = useRefSync(group);
  const smartReplyViewRef = useRef(null);
  const onErrorCallback = useCometChatErrorHandler(onError);
  const [isFirstReload, setIsFirstReload] = useState<boolean>(false);
  // const firstReload =  useRef<boolean>(false);
  const isConnectionReestablished = useRef<boolean>(false);
  const isOnBottom = useRef(false);
  let keepRecentMessages: boolean = true,
    timestampEnum: any = TimestampAlignment,
    isFetchingPreviousMessages = false,
    threadedAlignment: MessageBubbleAlignment = MessageBubbleAlignment.left,
    chatChanged = true;

  const messagesTemplate = useMemo(() => {
    return templates && templates.length > 0
      ? templates
      : ChatConfigurator.getDataSource().getAllMessageTemplates(theme);
  }, [templates, theme]);

  const messagesTypesMap = useMemo(() => {
    let messagesTypesArray: { [key: string]: CometChatMessageTemplate } = {};
    messagesTemplate.forEach((el: CometChatMessageTemplate) => {
      messagesTypesArray[el.category + "_" + el.type] = el;
    });
    return messagesTypesArray;
  }, [messagesTemplate]);

  const checkIfMessageBelongsToCurrentChat = useCallback(
    (message: CometChat.BaseMessage) => {
      const receiverType = message.getReceiverType();
      const receiverId = message.getReceiverId();
      const senderId = message.getSender().getUid();

      if (receiverType === CometChatUIKitConstants.MessageReceiverType.user) {
        return (
          (receiverId === userRef?.current?.getUid() &&
            senderId === loggedInUser?.getUid()) ||
          (senderId === userRef?.current?.getUid() &&
            receiverId === loggedInUser?.getUid())
        );
      } else {
        return groupRef?.current?.getGuid() === receiverId;
      }
    },
    [loggedInUser, userRef, groupRef]
  );

  const openThreadView = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        isOnBottom.current = false;
        setScrollListToBottom(false);
        if (onThreadRepliesClick) {
          onThreadRepliesClick(message, getThreadedMessageBubble);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onThreadRepliesClick, onErrorCallback, isOnBottom]
  );

  const onMessageInfoClick = (message: CometChat.BaseMessage) => {
    setActiveMessageInfo(message);
    setShowMessageInfoPopup(true);
  };
  const openMessageInfoView = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        isOnBottom.current = false;
        setScrollListToBottom(false);
        onMessageInfoClick(message);
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback, isOnBottom]
  );

  const getMessageFromList = useCallback(
    (id: number) => {
      try {
        const messageObject = messageList.find(
          (m: CometChat.BaseMessage) => m?.getId()?.toString() === id?.toString()
        );
        return messageObject;
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [messageList, onErrorCallback]
  );

  const threadCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.BaseMessage = getMessageFromList(id);
        openThreadView(messageObject);
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [getMessageFromList, openThreadView, onErrorCallback]
  );

  const messageInfoCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.BaseMessage = getMessageFromList(id);

        openMessageInfoView(messageObject);
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [getMessageFromList, openMessageInfoView, onErrorCallback]
  );
  const scrolledUpCallback = useCallback(
    (boolean?: boolean) => {
      if (boolean !== undefined) {
        isOnBottom.current = boolean;
        if (unreadCountBtnElement !== undefined && boolean) {
          unreadCountBtnElement?.click();
        }
      }
    },
    [isOnBottom, unreadCountBtnElement]
  );
  const sendPrivateMessageCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.BaseMessage = getMessageFromList(id);
        let user: CometChat.User = messageObject.getSender();
        CometChatUIEvents.ccOpenChat.next({
          user: user,
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [getMessageFromList, onErrorCallback]
  );

  const replaceMessage = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map(
            (m: CometChat.BaseMessage, id) => {
              if (m?.getId() === message?.getId()) {
                return message;
              } else {
                return m;
              }
            }
          );
          return messages;
        });

        if (!message.getParentMessageId()) {
          messagesCountRef.current -= 1;
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback, messagesCountRef]
  );

  const deleteMessage = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        const messageId: any = message.getId();
        CometChat.deleteMessage(messageId).then(
          (deletedMessage: CometChat.BaseMessage) => {
            replaceMessage(deletedMessage);
            CometChatMessageEvents.ccMessageDeleted.next(deletedMessage);
          },
          (error: CometChat.CometChatException) => {
            onErrorCallback(error);
          }
        );
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [replaceMessage, onErrorCallback]
  );

  const deleteCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.BaseMessage = getMessageFromList(id);
        if (messageObject) {
          deleteMessage(messageObject);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback, deleteMessage, getMessageFromList]
  );

  const onEditMessage = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        CometChatMessageEvents.ccMessageEdited.next({
          message: message,
          status: MessageStatus.inprogress,
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const editCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.BaseMessage = getMessageFromList(id);
        if (messageObject) {
          onEditMessage(messageObject);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [getMessageFromList, onEditMessage, onErrorCallback]
  );

  const onCopyMessage = useCallback(
    (message: CometChat.TextMessage) => {
      try {
        navigator?.clipboard?.writeText(message.getText());
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const copyCallback = useCallback(
    (id: number) => {
      try {
        let messageObject: CometChat.TextMessage = getMessageFromList(id);
        if (messageObject) {
          onCopyMessage(messageObject);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [getMessageFromList, onCopyMessage, onErrorCallback]
  );

  const messageSent = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map((m: CometChat.BaseMessage) => {
            if (m.getMuid() === message.getMuid()) {
              return message;
            } else {
              return m;
            }
          });
          return messages;
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const updateEditedMessage = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        if (!message || message.getId() === parentMessageId) {
          return;
        }
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map((m: CometChat.BaseMessage) => {
            if (m?.getId() === message?.getId()) {
              return message;
            } else {
              return m;
            }
          });
          return messages;
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [parentMessageId, onErrorCallback]
  );

  const updateMessage = useCallback(
    (message: CometChat.BaseMessage, muid: boolean = false) => {
      try {
        if (muid) {
          setScrollListToBottom(true);
          messageSent(message);
        } else {
          setScrollListToBottom(false);
          updateEditedMessage(message);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [
      messageSent,
      updateEditedMessage,
      onErrorCallback,
      setScrollListToBottom,
      isOnBottom,
    ]
  );

  const setOptionsCallback = useCallback(
    (options: (CometChatActionsIcon | CometChatActionsView)[]) => {
      try {
        options.forEach(
          (element: CometChatActionsIcon | CometChatActionsView) => {
            switch (element.id) {
              case CometChatUIKitConstants.MessageOption.deleteMessage:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick = deleteCallback;
                }
                break;
              case CometChatUIKitConstants.MessageOption.editMessage:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick = editCallback;
                }
                break;
              case CometChatUIKitConstants.MessageOption.copyMessage:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick = copyCallback;
                }
                break;
              case CometChatUIKitConstants.MessageOption.replyInThread:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick = threadCallback;
                }
                break;
              case CometChatUIKitConstants.MessageOption.messageInformation:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick =
                    messageInfoCallback;
                }
                break;
              case CometChatUIKitConstants.MessageOption.sendMessagePrivately:
                if (!(element as CometChatActionsIcon).onClick) {
                  (element as CometChatActionsIcon).onClick =
                    sendPrivateMessageCallback;
                }
                break;
              default:
                break;
            }
          }
        );
        return options;
      } catch (error: any) {
        onErrorCallback(error);
        return options;
      }
    },
    [
      onErrorCallback,
      deleteCallback,
      editCallback,
      threadCallback,
      copyCallback,
      messageInfoCallback,
      sendPrivateMessageCallback,
    ]
  );

  const setMessageOptions = useCallback(
    (
      msgObject: CometChat.BaseMessage
    ): (CometChatActionsIcon | CometChatActionsView)[] => {
      let options: (CometChatActionsIcon | CometChatActionsView)[] = [];
      if (!msgObject.getId()) {
        return options;
      }
      try {
        if (
          messagesTemplate &&
          messagesTemplate.length > 0 &&
          !msgObject.getDeletedAt() &&
          msgObject.getType() !==
            CometChatUIKitConstants.MessageTypes.groupMember &&
          msgObject?.getCategory() !==
            CometChatUIKitConstants.MessageCategory.call
        ) {
          messagesTemplate.forEach((element: any) => {
            if (
              element.type === msgObject.getType() &&
              element.category === msgObject.getCategory()
            ) {
              options = setOptionsCallback(
                element?.options?.(loggedInUser, msgObject, theme, group)
              );
            }
          });
        }
        return options;
      } catch (error: any) {
        onErrorCallback(error);
        return options;
      }
    },
    [
      setOptionsCallback,
      onErrorCallback,
      loggedInUser,
      group,
      messagesTemplate,
      theme,
    ]
  );

  const setBubbleAlignment = useCallback(
    (item: any) => {
      let bubbleAlignment = MessageBubbleAlignment.center;
      try {
        if (alignment === MessageListAlignment.left) {
          bubbleAlignment = MessageBubbleAlignment.left;
        } else {
          if (
            item.type === CometChatUIKitConstants.MessageTypes.groupMember ||
            item?.getCategory() == CometChatUIKitConstants.MessageCategory.call
          ) {
            bubbleAlignment = MessageBubbleAlignment.center;
          } else if (
            !item.sender ||
            (item?.sender?.uid === loggedInUser?.getUid() &&
              item.type !== CometChatUIKitConstants.MessageTypes.groupMember)
          ) {
            bubbleAlignment = MessageBubbleAlignment.right;
          } else {
            bubbleAlignment = MessageBubbleAlignment.left;
          }
        }
        return bubbleAlignment;
      } catch (error: any) {
        onErrorCallback(error);
        return bubbleAlignment;
      }
    },
    [alignment, onErrorCallback, loggedInUser]
  );

  const getContentView = useCallback(
    (item: any) => {
      try {
        let _alignment = setBubbleAlignment(item);
        if (
          messagesTypesMap[item.category + "_" + item.type] &&
          messagesTypesMap[item.category + "_" + item.type]?.contentView
        ) {
          return messagesTypesMap[item.category + "_" + item.type]?.contentView(
            item,
            _alignment
          );
        }
        return null;
      } catch (error: any) {
        onErrorCallback(error);
        return null;
      }
    },
    [messagesTypesMap, onErrorCallback, setBubbleAlignment]
  );

  const getBottomView = useCallback(
    (item: any) => {
      try {
        let _alignment = setBubbleAlignment(item);
        if (
          messagesTypesMap[item.category + "_" + item.type] &&
          messagesTypesMap[item.category + "_" + item.type]?.bottomView
        ) {
          return messagesTypesMap[item.category + "_" + item.type]?.bottomView(
            item,
            _alignment
          );
        }
        return null;
      } catch (error: any) {
        onErrorCallback(error);
        return null;
      }
    },
    [messagesTypesMap, onErrorCallback, setBubbleAlignment]
  );

  const getHeaderView = useCallback(
    (item: any) => {
      try {
        let view: any = null;
        if (
          messagesTypesMap[item.category + "_" + item.type] &&
          messagesTypesMap[item.category + "_" + item.type]?.headerView
        ) {
          view =
            messagesTypesMap[item.category + "_" + item.type]?.headerView(item);
        }
        return view;
      } catch (error: any) {
        onErrorCallback(error);
        return null;
      }
    },
    [messagesTypesMap, onErrorCallback]
  );

  const getFooterView = useCallback(
    (item: any) => {
      try {
        let view: any = null;
        if (
          messagesTypesMap[item.category + "_" + item.type] &&
          messagesTypesMap[item.category + "_" + item.type]?.footerView
        ) {
          view =
            messagesTypesMap[item.category + "_" + item.type]?.footerView(item);
        }
        return view;
      } catch (error: any) {
        onErrorCallback(error);
        return null;
      }
    },
    [messagesTypesMap, onErrorCallback]
  );

  const getBubbleWrapper = useCallback(
    (item: any) => {
      let view: any = null;
      try {
        if (
          messagesTypesMap[item.category + "_" + item.type] &&
          messagesTypesMap[item.category + "_" + item.type].bubbleView
        ) {
          view =
            messagesTypesMap[item.category + "_" + item.type].bubbleView(item);
        }
        return view;
      } catch (error: any) {
        onErrorCallback(error);
        return view;
      }
    },
    [messagesTypesMap, onErrorCallback]
  );

  const reinitializeMessagesRequestBuilder = useCallback(() => {
    try {
      if (keepRecentMessages) {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.slice(-30);
          return messages;
        });
      } else {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.slice(0, 30);
          return messages;
        });
      }
    } catch (error: any) {
      onErrorCallback(error);
    }
  }, [onErrorCallback, keepRecentMessages]);

  const prependMessages = useCallback(
    (messages: CometChat.BaseMessage[]) => {
      return new Promise((resolve, reject) => {
        try {
          setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
            const updatedMessageList = [...messages, ...prevMessageList];
            return updatedMessageList;
          });
          messagesCountRef.current = messagesCountRef.current + messages.length;
          setMessageListState(States.loaded);
          if (messagesCountRef.current > thresholdValue!) {
            keepRecentMessages = false;
            reinitializeMessagesRequestBuilder();
          }
          if (chatChanged) {
            console.log("changed")
            CometChatUIEvents.ccActiveChatChanged.next({
              user: user,
              group: group,
              message: messages[messages?.length - 1],
            });
            chatChanged = false;
          }
          resolve(true);
        } catch (error: any) {
          if (messageList?.length <= 0) {
            setMessageListState(States.error);
          }
          onErrorCallback(error);
          reject(error);
        }
      });
    },
    [
      thresholdValue,
      reinitializeMessagesRequestBuilder,
      onErrorCallback,
      messagesCountRef,
    ]
  );

  const fetchPreviousMessages = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        if (!isFetchingPreviousMessages) {
          isFetchingPreviousMessages = true;
          if (!messageListManager.current.previous) {
            messageListManager.current.previous = new MessageListManager(
              messagesRequestBuilder,
              userRef.current,
              groupRef.current,
              messageIdRef.current.prevMessageId
            );
          }
          setMessageListState(States.loading);
          messageListManager?.current.previous.fetchPreviousMessages().then(
            (messagesList: any) => {
              if (messagesList.length === 0 && messagesCountRef.current === 0  && !props?.parentMessageId) {
                CometChatUIEvents.ccActiveChatChanged.next({
                  user: user,
                  group: group,
                  message: undefined,
                });
              }
              if (messagesList && messagesList.length > 0) {
                messagesList = messagesList.map((message: CometChat.BaseMessage) => {
                  if(message.getCategory() === CometChatUIKitConstants.MessageCategory.interactive){
                    return  InteractiveMessageUtils.convertInteractiveMessage(message as CometChat.InteractiveMessage);
                  }else{
                    return message;
                  }
                })  
              }            
              if (isFirstReload) {
  
                setIsFirstReload(false);
                MessageListManager.attachConnestionListener(() => {
                  isConnectionReestablished.current = true;
                  fetchActionMessages().then(() => {
                    fetchNextMessages()
                      .then(
                        (success) => {
                          resolve(success);
                          isConnectionReestablished.current = false;
                        },
                        (error) => {
                          reject(error);
                        }
                      )
                      .catch((error: CometChat.CometChatException) => {
                        if (props.onError) {
                          props.onError(error);
                        }
                      });
                  });
                });
            
              }

              isFetchingPreviousMessages = false;
              if (messagesList && messagesList.length > 0) {
                let lastMessage: CometChat.BaseMessage =
                  messagesList[messagesList.length - 1];
                if (!lastMessage.getDeliveredAt() && !disableReceipt) {
                  CometChat.markAsDelivered(lastMessage).then(() => {
                    messagesList.forEach((m: CometChat.BaseMessage) => {
                      if (
                        m?.getId() <= lastMessage?.getId() &&
                        m.getSender().getUid() !== loggedInUser?.getUid() &&
                        !m.getDeliveredAt()
                      ) {
                        m.setDeliveredAt(new Date().getTime());
                      }
                      return m;
                    });
                  });
                }
                if (!lastMessage.getReadAt()) {
                  if (!disableReceipt) {
                    CometChat.markAsRead(lastMessage).then(() => {
                      messagesList.forEach((m: CometChat.BaseMessage) => {
                        if (
                          m?.getId() <= lastMessage?.getId() &&
                          m.getSender().getUid() !== loggedInUser?.getUid() &&
                          !m.getReadAt()
                        ) {
                          m.setReadAt(new Date().getTime());
                        }
                        return m;
                      });
                      CometChatMessageEvents.ccMessageRead.next(lastMessage);
                    });
                  } else {
                    UnreadCountRef.current = [];
                  }
                }

                prependMessages(messagesList).then(
                  (success) => {
                    resolve(success);
                  },
                  (error) => {
                    reject(error);
                  }
                );
              } else {
                if (messagesList.length === 0) {
                  if (messagesCountRef.current === 0) {
                    setMessageListState(States.loaded);
                  }
                }
                resolve(true);
              }
            },
            (error: CometChat.CometChatException) => {
              isFetchingPreviousMessages = false;
              if (messageList?.length <= 0) {
                setMessageListState(States.error);
              }
              onErrorCallback(error);
              reject(error);
            }
          );
        } else {
          resolve(true);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    });
  }, [
    disableReceipt,
    onErrorCallback,
    prependMessages,
    loggedInUser,
    messagesCountRef,
    UnreadCountRef,
    isFirstReload,
  ]);

  const appendMessages = useCallback(
    (messages: CometChat.BaseMessage[]) => {
      return new Promise((resolve, reject) => {
        try {
          setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
            const updatedMessageList = [
              ...prevMessageList,
              ...(typeof messages === "string"
                ? Array.from(messages)
                : messages),
            ];
            return updatedMessageList;
          });
          messagesCountRef.current = messagesCountRef.current + messages.length;
          let id = messages[messages.length - 1]?.getId();
          if (id && messageIdRef.current.prevMessageId !== id) {
            messageIdRef.current.nextMessageId = id;
          }

          if (scrollToBottomOnNewMessages) {
            setTimeout(() => {
              setScrollListToBottom(true);
              isOnBottom.current = true;
            }, 100);
          } else {
            if (isConnectionReestablished.current) {
              setScrollListToBottom(isOnBottom.current);
              let lastMessage: CometChat.BaseMessage =
                messages[messages?.length - 1];
              if (
                isOnBottom.current &&
                lastMessage &&
                lastMessage.getSender().getUid() != loggedInUser?.getUid() &&
                !lastMessage.getReadAt()
              ) {
                CometChat.markAsRead(lastMessage).then(() => {
                  UnreadCountRef.current = [];
                  CometChatMessageEvents.ccMessageRead.next(lastMessage);
                });
              }
            } else {
              setScrollListToBottom(false);
            }
            let countText = newMessageIndicatorText
              ? newMessageIndicatorText
              : UnreadCountRef.current.length > 1
              ? localize("NEW_MESSAGES")
              : localize("NEW_MESSAGE");
            UnreadCountRef.current.push(...messages);
            newMessageCountRef.current =
              " ↓ " + UnreadCountRef.current.length + " " + countText;
            unreadMessageLabelRef.current = true;
          }
          setMessageListState(States.loaded);
          if (messagesCountRef.current > thresholdValue!) {
            keepRecentMessages = true;
            reinitializeMessagesRequestBuilder();
          }
          resolve(true);
        } catch (error: any) {
          if (messageList?.length <= 0) {
            setMessageListState(States.error);
          }
          onErrorCallback(error);
          reject(error);
        }
      });
    },
    [
      thresholdValue,
      reinitializeMessagesRequestBuilder,
      onErrorCallback,
      messagesCountRef,
      isOnBottom,
    ]
  );

  const fetchActionMessages = () => {
    return new Promise((resolve, reject) => {
      let requestBuilder: CometChat.MessagesRequest;
      if (user) {
        requestBuilder = new CometChat.MessagesRequestBuilder()
          .setUID(user.getUid())
          .setType(CometChatUIKitConstants.MessageCategory.message)
          .setCategory(CometChatUIKitConstants.MessageCategory.action)
          .setMessageId(messageIdRef.current.nextMessageId)
          .setLimit(30)
          .build();
      } else if (group) {
        requestBuilder = new CometChat.MessagesRequestBuilder()
          .setGUID(group.getGuid())
          .setType(CometChatUIKitConstants.MessageCategory.message)
          .setMessageId(messageIdRef.current.nextMessageId)
          .setLimit(30)
          .setCategory(CometChatUIKitConstants.MessageCategory.action)
          .build();
      }
      requestBuilder!
        .fetchNext()
        .then((messages) => {
          if (messages && messages.length > 0) {
            messages.forEach((message: CometChat.BaseMessage) => {
              messageEdited(
                (
                  message as CometChat.Action
                ).getActionOn() as CometChat.BaseMessage
              );
            });
            return resolve(messageList);
          } else {
            return resolve(true);
          }
        })
        .catch((error: CometChat.CometChatException) => {
          if (onError) {
            onError(error);
          }
          if (messageList?.length <= 0) {
            setMessageListState(States.error);
          }
          return reject(error);
        });
    });
  };

  const fetchNextMessages = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        if (messageIdRef.current.nextMessageId) {
          if (!messageListManager.current.next) {
            messageListManager.current.next = new MessageListManager(
              messagesRequestBuilder,
              userRef.current,
              groupRef.current,
              messageIdRef.current.nextMessageId
            );
          }
          setMessageListState(States.loading);
          messageListManager?.current.next.fetchNextMessages().then(
            (messagesList: any) => {
              if (messagesList && messagesList.length > 0) {
                messagesList = messagesList.map((message: CometChat.BaseMessage) => {
                  if(message.getCategory() === CometChatUIKitConstants.MessageCategory.interactive){
                    return  InteractiveMessageUtils.convertInteractiveMessage(message as CometChat.InteractiveMessage);
                  }else{
                    return message;
                  }
                })  
              } 
              if (messagesList) {
                if (messagesList.length === 0) {
                  messagesCountRef.current === 0
                    ? setMessageListState(States.loaded)
                    : setMessageListState(States.loaded);
                  resolve(true);
                } else {
                  appendMessages(messagesList).then(
                    (success) => {
                      resolve(success);
                    },
                    (error) => {
                      reject(error);
                    }
                  );
                }
              } else {
                resolve(true);
              }
            },
            (error: any) => {
              if (messageList?.length <= 0) {
                setMessageListState(States.error);
              }
              onErrorCallback(error);
              reject(error);
            }
          );
        } else {
          resolve(true);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    });
  }, [
    appendMessages,
    onErrorCallback,
    messagesCountRef,
    groupRef,
    messageList?.length,
    messagesRequestBuilder,
    userRef,
  ]);

  const updateReplyCount = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map((m: CometChat.BaseMessage) => {
            if (m?.getId() === message.getParentMessageId()) {
              if (m.getReplyCount()) {
                m.setReplyCount(m.getReplyCount() + 1);
              } else {
                m.setReplyCount(1);
              }
              return m;
            } else {
              return m;
            }
          });
          return messages;
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const addMessage = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        messagesCountRef.current += 1;
        setMessageListState((prevState: any) => {
          if (prevState === States.loaded) {
            return States.loaded;
          } else {
            return prevState;
          }
        });
        if (!message.getParentMessageId() || parentMessageId) {
          setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
            const messages = [...prevMessageList, message];
            return messages;
          });
        }
        if (messagesCountRef.current > thresholdValue!) {
          keepRecentMessages = true;
          reinitializeMessagesRequestBuilder();
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [thresholdValue, onErrorCallback, parentMessageId, messagesCountRef]
  );

  const getMessageReceipt = useCallback(
    (message: any) => {
      try {
        return MessageReceiptUtils.getReceiptStatus(message);
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const markMessageAsDelivered = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        if (
          !disableReceipt &&
          message.getSender().getUid() !== loggedInUser?.getUid() &&
          !message.getDeliveredAt()
        ) {
          CometChat.markAsDelivered(message).then(
            () => {},
            (error: CometChat.CometChatException) => {
              onErrorCallback(error);
            }
          );
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [disableReceipt, onErrorCallback, loggedInUser]
  );

  const playAudio = useCallback(() => {
    try {
      // CometChatSoundManager.pause();
      if (!disableSoundForMessages) {
        if (customSoundForMessages) {
          CometChatSoundManager.play(
            CometChatSoundManager.Sound.incomingMessage,
            customSoundForMessages
          );
        } else {
          CometChatSoundManager.play(
            CometChatSoundManager.Sound.incomingMessage
          );
        }
      }
    } catch (error: any) {
      onErrorCallback(error);
    }
  }, [disableSoundForMessages, customSoundForMessages, onErrorCallback]);

  const messageReceivedHandler = useCallback(
    (message: CometChat.BaseMessage) => {
      try {
        markMessageAsDelivered(message);
        if (checkIfMessageBelongsToCurrentChat(message)) {
          if (message.getParentMessageId()) {
            updateReplyCount(message);
          } else {
            addMessage(message);
          }
          if (
            !message.getParentMessageId() ||
            (message.getParentMessageId() &&
              parentMessageId &&
              parentMessageId === message.getParentMessageId())
          ) {
            if (!isOnBottom.current) {
              if (scrollToBottomOnNewMessages) {
                setTimeout(() => {
                  setScrollListToBottom(true);
                  if (
                    !disableReceipt &&
                    message.getSender().getUid() !== loggedInUser?.getUid()
                  ) {
                    CometChat.markAsRead(message).then(
                      () => {
                        CometChatMessageEvents.ccMessageRead.next(message);
                      },
                      (error: unknown) => {
                        onErrorCallback(error);
                      }
                    );
                  } else {
                    UnreadCountRef.current = [];
                  }
                }, 100);
              } else {
                setScrollListToBottom(false);
                let countText = newMessageIndicatorText
                  ? newMessageIndicatorText
                  : UnreadCountRef.current.length > 1
                  ? localize("NEW_MESSAGES")
                  : localize("NEW_MESSAGE");
                UnreadCountRef.current.push(message);
                newMessageCountRef.current =
                  " ↓ " + UnreadCountRef.current.length + " " + countText;
                unreadMessageLabelRef.current = true;
              }
            } else {
              setScrollListToBottom(true);
              isOnBottom.current = true;
              if (
                !disableReceipt &&
                message.getSender().getUid() !== loggedInUser?.getUid()
              ) {
                CometChat.markAsRead(message).then(
                  () => {
                    CometChatMessageEvents.ccMessageRead.next(message);
                  },
                  (error: unknown) => {
                    onErrorCallback(error);
                  }
                );
              } else {
                UnreadCountRef.current = [];
              }
            }
          }
        }
        playAudio();
      } catch (error) {
        onErrorCallback(error);
      }
    },
    [
      markMessageAsDelivered,
      updateReplyCount,
      parentMessageId,
      disableReceipt,
      addMessage,
      scrollToBottomOnNewMessages,
      playAudio,
      onErrorCallback,
      loggedInUser,
      isOnBottom,
      UnreadCountRef,
      newMessageCountRef,
      unreadMessageLabelRef,
    ]
  );

  const markAllMessagAsDelivered = useCallback(
    (message: CometChat.MessageReceipt) => {
      try {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map((m: CometChat.BaseMessage) => {
            if (
              parseInt(m?.getId()?.toString()) <=
                parseInt(message.getMessageId()) &&
              m.getSender().getUid() === loggedInUser?.getUid() &&
              !m.getDeliveredAt()
            ) {
              m.setDeliveredAt(message.getDeliveredAt());
            }
            return m;
          });
          return messages;
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback, loggedInUser]
  );

  const markAllMessageAsRead = useCallback(
    (message: CometChat.MessageReceipt) => {
      try {
        setMessageList((prevMessageList: CometChat.BaseMessage[]) => {
          const messages = prevMessageList.map((m: CometChat.BaseMessage) => {
            if (
              parseInt(m?.getId()?.toString()) <=
                parseInt(message.getMessageId()) &&
              m.getSender().getUid() === loggedInUser?.getUid() &&
              !m.getReadAt()
            ) {
              m.setReadAt(message.getReadAt());
              if (
                parseInt(m?.getId()?.toString()) ===
                parseInt(message.getMessageId())
              ) {
                CometChatMessageEvents.ccMessageRead.next(m);
              }
            }
            return m;
          });
          return messages;
        });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback, loggedInUser]
  );

  const messageReadAndDelivered = useCallback(
    (messageReceipt: CometChat.MessageReceipt) => {
      try {
        if (
          messageReceipt.getReceiverType() ===
            CometChatUIKitConstants.MessageReceiverType.user &&
          messageReceipt.getSender().getUid() === userRef.current?.getUid() &&
          messageReceipt.getReceiver() === loggedInUser?.getUid()
        ) {
          messageReceipt.getReceiptType() === "delivery"
            ? markAllMessagAsDelivered(messageReceipt)
            : markAllMessageAsRead(messageReceipt);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [
      markAllMessagAsDelivered,
      markAllMessageAsRead,
      onErrorCallback,
      loggedInUser,
      userRef,
    ]
  );

  const messageEdited = useCallback(
    (editedMessage: CometChat.BaseMessage) => {
      try {
        if (checkIfMessageBelongsToCurrentChat(editedMessage)) {
          updateEditedMessage(editedMessage);
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [updateEditedMessage, onErrorCallback, checkIfMessageBelongsToCurrentChat]
  );

  const updateInteractiveMessage = useCallback(
    (receipt: CometChat.InteractionReceipt) => {
      if((loggedInUser!.getUid() === receipt.getSender().getUid())){
        setMessageList((previousMessage)=>{
          return previousMessage.map((message)=>{
            if(String(message.getId()) === String(receipt.getMessageId())){
              const interaction = receipt.getInteractions();
              (message as CometChat.InteractiveMessage).setInteractions(interaction)
              return InteractiveMessageUtils.convertInteractiveMessage(message);
            }else{
              return message;
            }
          })
        })
      }
    },
    [loggedInUser]
  );

  const customMessageReceivedHandler = useCallback(
    (customMessage: CometChat.CustomMessage) => {
      try {
        markMessageAsDelivered(customMessage);
        if (checkIfMessageBelongsToCurrentChat(customMessage)) {
          if (customMessage.getParentMessageId()) {
            updateReplyCount(customMessage);
          } else {
            addMessage(customMessage);
          }
          if (
            !customMessage.getParentMessageId() ||
            (customMessage.getParentMessageId() &&
              parentMessageId &&
              parentMessageId === customMessage.getParentMessageId())
          ) {
            if (!isOnBottom.current) {
              if (scrollToBottomOnNewMessages) {
                setTimeout(() => {
                  setScrollListToBottom(true);
                  if (
                    !disableReceipt &&
                    customMessage.getSender().getUid() !==
                      loggedInUser?.getUid()
                  ) {
                    CometChat.markAsRead(customMessage).then(
                      () => {
                        CometChatMessageEvents.ccMessageRead.next(
                          customMessage
                        );
                      },
                      (error: unknown) => {
                        onErrorCallback(error);
                      }
                    );
                  } else {
                    UnreadCountRef.current = [];
                  }
                }, 100);
              } else {
                setScrollListToBottom(false);
                let countText = newMessageIndicatorText
                  ? newMessageIndicatorText
                  : UnreadCountRef.current.length > 1
                  ? localize("NEW_MESSAGES")
                  : localize("NEW_MESSAGE");
                UnreadCountRef.current.push(customMessage);
                newMessageCountRef.current =
                  " ↓ " + UnreadCountRef.current.length + " " + countText;
                unreadMessageLabelRef.current = true;
              }
            } else {
              if (
                !disableReceipt &&
                customMessage.getSender().getUid() !== loggedInUser?.getUid()
              ) {
                CometChat.markAsRead(customMessage).then(
                  () => {
                    CometChatMessageEvents.ccMessageRead.next(customMessage);
                  },
                  (error: unknown) => {
                    onErrorCallback(error);
                  }
                );
              } else {
                UnreadCountRef.current = [];
              }
            }
          }
        }
        playAudio();
      } catch (error) {
        onErrorCallback(error);
      }
    },
    [
      markMessageAsDelivered,
      updateReplyCount,
      parentMessageId,
      disableReceipt,
      addMessage,
      scrollToBottomOnNewMessages,
      playAudio,
      onErrorCallback,
      loggedInUser,
      isOnBottom,
      UnreadCountRef,
      newMessageCountRef,
      unreadMessageLabelRef,
      checkIfMessageBelongsToCurrentChat,
      newMessageIndicatorText,
    ]
  );

  const groupActionMessageReceived = useCallback(
    (actionMessage: CometChat.Action | any, group: CometChat.Group) => {
      try {
        if (group?.getGuid() === groupRef?.current?.getGuid()) {
          addMessage(actionMessage);
          if (!isOnBottom.current) {
            if (scrollToBottomOnNewMessages) {
              setTimeout(() => {
                setScrollListToBottom(true);
              }, 100);
            } else {
              setScrollListToBottom(false);
              let countText = newMessageIndicatorText
                ? newMessageIndicatorText
                : UnreadCountRef.current.length > 1
                ? localize("NEW_MESSAGES")
                : localize("NEW_MESSAGE");
              UnreadCountRef.current.push(actionMessage);
              newMessageCountRef.current =
                " ↓ " + UnreadCountRef.current.length + " " + countText;
              unreadMessageLabelRef.current = true;
            }
          }
        }
      } catch (error) {
        onErrorCallback(error);
      }
    },
    [
      addMessage,
      scrollToBottomOnNewMessages,
      onErrorCallback,
      isOnBottom,
      UnreadCountRef,
      newMessageCountRef,
      unreadMessageLabelRef,
      groupRef,
      newMessageIndicatorText,
    ]
  );

  const callActionMessageReceived = useCallback(
    (callMessage: CometChat.Call) => {
      try {
        if (
          checkIfMessageBelongsToCurrentChat(callMessage) &&
          ChatConfigurator.names.includes("calling")
        ) {
          addMessage(callMessage);
          if (!isOnBottom.current) {
            if (scrollToBottomOnNewMessages) {
              setTimeout(() => {
                setScrollListToBottom(true);
              }, 100);
            } else {
              setScrollListToBottom(false);
              let countText = newMessageIndicatorText
                ? newMessageIndicatorText
                : UnreadCountRef.current.length > 1
                ? localize("NEW_MESSAGES")
                : localize("NEW_MESSAGE");
              UnreadCountRef.current.push(callMessage);
              newMessageCountRef.current =
                " ↓ " + UnreadCountRef.current.length + " " + countText;
              unreadMessageLabelRef.current = true;
            }
          }
        }
      } catch (error) {
        onErrorCallback(error);
      }
    },
    [
      checkIfMessageBelongsToCurrentChat,
      addMessage,
      scrollToBottomOnNewMessages,
      onErrorCallback,
      isOnBottom,
      UnreadCountRef,
      newMessageCountRef,
      unreadMessageLabelRef,
      newMessageIndicatorText,
    ]
  );

  const handleRealTimeMessages = useCallback(
    (
      key: string = "",
      message:
        | CometChat.MessageReceipt
        | CometChat.BaseMessage
        | CometChat.TransientMessage
        | any
        | CometChat.Action
        | CometChat.Call = null,
      group: CometChat.Group | null = null
    ) => {
      try {
        switch (key) {
          case CometChatUIKitConstants.messages.TEXT_MESSAGE_RECEIVED:
          case CometChatUIKitConstants.messages.MEDIA_MESSAGE_RECEIVED: {
            messageReceivedHandler(message);
            break;
          }
          case CometChatUIKitConstants.messages.MESSAGE_DELIVERED:
          case CometChatUIKitConstants.messages.MESSAGE_READ: {
            if (!disableReceipt) {
              messageReadAndDelivered(message);
            }
            break;
          }
          case CometChatUIKitConstants.messages.MESSAGE_DELETED:
          case CometChatUIKitConstants.messages.MESSAGE_EDITED: {
            messageEdited(message);
            break;
          }
          case CometChatUIKitConstants.groupMemberAction.SCOPE_CHANGE:
          case CometChatUIKitConstants.groupMemberAction.JOINED:
          case CometChatUIKitConstants.groupMemberAction.LEFT:
          case CometChatUIKitConstants.groupMemberAction.ADDED:
          case CometChatUIKitConstants.groupMemberAction.KICKED:
          case CometChatUIKitConstants.groupMemberAction.BANNED:
          case CometChatUIKitConstants.groupMemberAction.UNBANNED: {
            if (group) {
              groupActionMessageReceived(message, group);
            }
            break;
          }
          case CometChatUIKitConstants.messages.CUSTOM_MESSAGE_RECEIVED:
          case CometChatUIKitConstants.messages.INTERACTIVE_MESSAGE_RECEIVED: {
            customMessageReceivedHandler(message);
            break;
          }
          case CometChatUIKitConstants.messages.TRANSIENT_MESSAGE_RECEIVED: {
            let transientMessage: CometChat.TransientMessage =
              message as CometChat.TransientMessage;
            let liveReaction: any = transientMessage.getData();

            if (
              (transientMessage.getReceiverType() ===
                CometChatUIKitConstants.MessageReceiverType.user &&
                userRef.current &&
                transientMessage?.getSender().getUid() ===
                  userRef.current.getUid() &&
                transientMessage.getReceiverId() === loggedInUser?.getUid()) ||
              (transientMessage.getReceiverType() ===
                CometChatUIKitConstants.MessageReceiverType.group &&
                groupRef.current &&
                transientMessage.getReceiverId() ===
                  groupRef.current.getGuid() &&
                transientMessage?.getSender().getUid() !==
                  loggedInUser?.getUid())
            ) {
              CometChatMessageEvents.ccLiveReaction.next(
                liveReaction["reaction"]
              );
            }
            break;
          }
          case CometChatUIKitConstants.messages.INTERACTION_GOAL_COMPLETED:
            updateInteractiveMessage(message);
          break;
          case "incoming":
          case "cancelled":
          case "rejected":
          case "accepted": {
            callActionMessageReceived(message);
            break;
          }
        }
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [
      messageReceivedHandler,
      messageReadAndDelivered,
      messageEdited,
      groupActionMessageReceived,
      customMessageReceivedHandler,
      loggedInUser,
      callActionMessageReceived,
      disableReceipt,
      userRef,
      groupRef,
      onErrorCallback,
    ]
  );

  const onBottomCallback = useCallback(() => {
    if (unreadCountBtnElement) {
      unreadCountBtnElement?.click();
    }

    return new Promise((resolve, reject) => {
      try {
        setScrollListToBottom(false);
        isOnBottom.current = true;
        if (messageListManager.current && messageListManager.current.previous) {
          messageListManager.current.previous = null;
        }

        fetchNextMessages().then(
          (success) => {
            resolve(success);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (error: any) {
        onErrorCallback(error);
      }
    });
  }, [
    disableReceipt,
    unreadMessageLabel,
    fetchNextMessages,
    onErrorCallback,
    isOnBottom,
    UnreadCountRef,
    newMessageCountRef,
    unreadMessageLabelRef,
    unreadCountBtnElement,
  ]);

  const onTopCallback = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        setScrollListToBottom(false);
        isOnBottom.current = false;
        if (messageListManager.current && messageListManager.current.next) {
          messageListManager.current.next = null;
        }
        fetchPreviousMessages().then(
          (success) => {
            resolve(success);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (error: any) {
        onErrorCallback(error);
      }
    });
  }, [fetchPreviousMessages, onErrorCallback, isOnBottom]);

  const updateView =  useCallback(
    (message: CometChat.BaseMessage) => {
      itemRefs.current[message.getId()].current?.scrollIntoView({
        block:"center"
        });
    }, [itemRefs]
  )

  const scrollToBottom = useCallback(() => {
    try {
      isOnBottom.current = true;
      setScrollListToBottom(true);
      const lastMessage: CometChat.BaseMessage =
        UnreadCountRef.current[UnreadCountRef.current.length - 1];
      if (!disableReceipt && lastMessage) {
        CometChat.markAsRead(lastMessage).then(() => {
          UnreadCountRef.current = [];
          CometChatMessageEvents.ccMessageRead.next(lastMessage);
        });
      } else {
        UnreadCountRef.current = [];
      }

      if (newMessageCountRef.current) {
        newMessageCountRef.current = "";
      }

      if (unreadMessageLabelRef.current) {
        unreadMessageLabelRef.current = false;
      }
    } catch (error: any) {
      onErrorCallback(error);
    }
  }, [
    disableReceipt,
    unreadMessageLabel,
    onErrorCallback,
    isOnBottom,
    UnreadCountRef,
    newMessageCountRef,
    unreadMessageLabelRef,
    isOnBottom,
  ]);

  const subscribeToEvents = useCallback(() => {
    try {
      const ccShowOngoingCall = CometChatUIEvents.ccShowOngoingCall.subscribe(
        (data: IShowOngoingCall) => {
          setShowOngoingCall(true);
          setOngoingCallView(data.child);
        }
      );
      const ccCallEnded = CometChatCallEvents.ccCallEnded.subscribe(
        (call: CometChat.Call) => {
          setShowOngoingCall(false);
          setOngoingCallView(null);
          callActionMessageReceived(call);
        }
      );
      const ccCallRejected = CometChatCallEvents.ccCallRejected.subscribe(
        (call: CometChat.Call) => {
          callActionMessageReceived(call);
        }
      );
      const ccOutgoingCall = CometChatCallEvents.ccOutgoingCall.subscribe(
        (call: CometChat.Call) => {
          callActionMessageReceived(call);
        }
      );
      const ccCallAccepted = CometChatCallEvents.ccCallAccepted.subscribe(
        (call: CometChat.Call) => {
          callActionMessageReceived(call);
        }
      );
      const ccShowDialog = CometChatUIEvents.ccShowDialog.subscribe(
        (data: IDialog) => {
          imageModerationDialogRef.current = data.child;
          setShowConfirmDialog(true);
        }
      );
      const ccHideDialog = CometChatUIEvents.ccHideDialog.subscribe(() => {
        imageModerationDialogRef.current = null;
        setShowConfirmDialog(false);
      });
      const ccShowPanel = CometChatUIEvents.ccShowPanel.subscribe(
        (data: IPanel) => {
          smartReplyViewRef.current = data.child;
          setShowSmartReply(true);
        }
      );
      const ccHidePanel = CometChatUIEvents.ccHidePanel.subscribe(() => {
        smartReplyViewRef.current = null;
        setShowSmartReply(false);
        unreadMessageLabelRef.current = false;
      });
      const ccGroupMemberAdded =
        CometChatGroupEvents.ccGroupMemberAdded.subscribe(
          (item: IGroupMemberAdded) => {
            item.messages.map((message) => {
              groupActionMessageReceived(message, item.userAddedIn);
            });
          }
        );
      const ccGroupMemberBanned =
        CometChatGroupEvents.ccGroupMemberBanned.subscribe(
          (item: IGroupMemberKickedBanned) => {
            groupActionMessageReceived(item.message, item.kickedFrom);
          }
        );
      const ccGroupMemberKicked =
        CometChatGroupEvents.ccGroupMemberKicked.subscribe(
          (item: IGroupMemberKickedBanned) => {
            groupActionMessageReceived(item.message, item.kickedFrom);
          }
        );
      const ccGroupMemberScopeChanged =
        CometChatGroupEvents.ccGroupMemberScopeChanged.subscribe(
          (item: IGroupMemberScopeChanged) => {
            groupActionMessageReceived(item.message, item.group);
          }
        );
      const ccGroupLeft = CometChatGroupEvents.ccGroupLeft.subscribe(
        (item: IGroupLeft) => {
          groupActionMessageReceived(item.message, item.leftGroup);
        }
      );
      const ccMessageEdit = CometChatMessageEvents.ccMessageEdited.subscribe(
        (obj: IMessages) => {
          if (obj?.status === MessageStatus.success) {
            updateMessage(obj.message, false);
          }
        }
      );

      const ccMessageTranslated = CometChatMessageEvents.ccMessageTranslated.subscribe(
        (obj: IMessages) => {
          if (obj?.status === MessageStatus.success) {
            updateMessage(obj.message, false);
            setTimeout(() => {
              updateView(obj.message);
            }, 100);
          }
        }
      );

      const ccMessageSent = CometChatMessageEvents.ccMessageSent.subscribe(
        (obj: IMessages) => {
          let { message, status } = obj;
          switch (status) {
            case MessageStatus.inprogress: {
              setScrollListToBottom(true);
              addMessage(message);
              break;
            }
            case MessageStatus.success: {
              if (message.getParentMessageId()) {
                updateReplyCount(message);
              }
              updateMessage(message, true);
              break;
            }
          }
        }
      );

      return () => {
        try {
          ccMessageEdit?.unsubscribe();
          ccMessageSent?.unsubscribe();
          ccGroupMemberAdded?.unsubscribe();
          ccGroupMemberBanned?.unsubscribe();
          ccGroupMemberKicked?.unsubscribe();
          ccGroupMemberScopeChanged?.unsubscribe();
          ccGroupLeft?.unsubscribe();
          ccShowOngoingCall?.unsubscribe();
          ccOutgoingCall?.unsubscribe();
          ccCallEnded?.unsubscribe();
          ccCallRejected?.unsubscribe();
          ccCallAccepted?.unsubscribe();
          ccShowDialog?.unsubscribe();
          ccHideDialog?.unsubscribe();
          ccShowPanel?.unsubscribe();
          ccHidePanel?.unsubscribe();
          ccMessageTranslated?.unsubscribe();
        } catch (error: any) {
          onErrorCallback(error);
        }
      };
    } catch (error: any) {
      onErrorCallback(error);
    }
  }, [
    setShowSmartReply,
    setScrollListToBottom,
    groupActionMessageReceived,
    updateMessage,
    addMessage,
    playAudio,
    updateReplyCount,
    parentMessageId,
    onErrorCallback,
    smartReplyViewRef.current,
    setShowConfirmDialog,
    callActionMessageReceived,
    unreadMessageLabelRef,
    updateView
  ]);

  Hooks(
    itemRefs,
    loggedInUser,
    setLoggedInUser,
    messageListManager,
    fetchPreviousMessages,
    handleRealTimeMessages,
    messagesRequestBuilder,
    user,
    group,
    subscribeToEvents,
    messageIdRef,
    messagesCountRef,
    messageList,
    onErrorCallback,
    setMessageList,
    setScrollListToBottom,
    smartReplyViewRef,
    setShowSmartReply,
    isOnBottom,
    isFirstReload,
    setIsFirstReload
  );

  const isDateDifferent = useCallback(
    (firstDate: number | undefined, secondDate: number | undefined) => {
      try {
        let firstDateObj: Date, secondDateObj: Date;
        firstDateObj = new Date(firstDate! * 1000);
        secondDateObj = new Date(secondDate! * 1000);
        return (
          firstDateObj.getDate() !== secondDateObj.getDate() ||
          firstDateObj.getMonth() !== secondDateObj.getMonth() ||
          firstDateObj.getFullYear() !== secondDateObj.getFullYear()
        );
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [onErrorCallback]
  );

  const getLoaderHtml = useMemo(() => {
    if (loadingStateView) {
      return (
        <div className="cc__messagelist__loadingview">
          <span className="cc__messagelist__customview--loading">
            {loadingStateView}
          </span>
        </div>
      );
    } else {
      return (
        <div className="cc__messagelist__loadingview">
          <cometchat-loader
            iconURL={loadingIconURL}
            loaderStyle={JSON.stringify(
              LoadingViewStyle(messageListStyle, theme)
            )}
          ></cometchat-loader>
        </div>
      );
    }
  }, [loadingStateView, loadingIconURL, messageListStyle, theme]);

  const getErrorHtml = useMemo(() => {
    if (errorStateView) {
      return (
        <div className="cc__messagelist__errorview">
          <span className="cc__messagelist__customview--error">
            {errorStateView}
          </span>
        </div>
      );
    } else {
      return (
        <div className="cc__messagelist__errorview">
          <cometchat-label
            labelStyle={JSON.stringify(ErrorViewStyle(messageListStyle, theme))}
            text={errorStateText}
          ></cometchat-label>
        </div>
      );
    }
  }, [errorStateView, errorStateText, messageListStyle, theme]);

  const getEmptyHtml = useMemo(() => {
    if (emptyStateView) {
      return (
        <div className="cc__messagelist__emptyview">
          <span className="cc__messagelist__customview--empty">
            {emptyStateView}
          </span>
        </div>
      );
    } else {
      return (
        <div className="cc__messagelist__emptyview">
          <cometchat-label
            labelStyle={JSON.stringify(EmptyViewStyle(messageListStyle, theme))}
            text={emptyStateText}
          ></cometchat-label>
        </div>
      );
    }
  }, [emptyStateView, emptyStateText, messageListStyle, theme]);

  const showHeaderTitle = useCallback(
    (message: CometChat.BaseMessage) => {
      if (alignment === MessageListAlignment.left) {
        return true;
      } else {
        if (
          groupRef.current &&
          message?.getCategory() !==
            CometChatUIKitConstants.MessageCategory.action &&
          message?.getSender() &&
          message?.getSender()?.getUid() !== loggedInUser?.getUid() &&
          alignment === MessageListAlignment.standard
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    [alignment, loggedInUser, groupRef]
  );

  const getBubbleLeadingView = useCallback(
    (item: CometChat.BaseMessage) => {
      if (
        item?.getCategory() !==
          CometChatUIKitConstants.MessageCategory.action &&
        item?.getCategory() !== CometChatUIKitConstants.MessageCategory.call &&
        showAvatar &&
        showHeaderTitle(item)
      ) {
        return (
          // <div>
          <cometchat-avatar
            name={item?.getSender()?.getName()}
            avatarStyle={JSON.stringify(MessageAvatarStyle(avatarStyle, theme))}
            image={item?.getSender()?.getAvatar()}
          ></cometchat-avatar>
          // </div>
        );
      } else {
        return null;
      }
    },
    [showAvatar, avatarStyle, showHeaderTitle, theme]
  );

  const getBubbleHeaderDate = useCallback(
    (item: CometChat.BaseMessage) => {
      return (
        <>
          <cometchat-date
            timestamp={item.getSentAt()}
            dateStyle={JSON.stringify(
              MessageBubbleDateStyle(messageListStyle, theme)
            )}
            pattern={datePattern}
          ></cometchat-date>
        </>
      );
    },
    [messageListStyle, datePattern, theme]
  );

  const getBubbleHeaderTitle = useCallback(
    (item: CometChat.BaseMessage) => {
      return (
        <>
          <cometchat-label
            text={item?.getSender()?.getName()}
            labelStyle={JSON.stringify(MessageLabelStyle(theme))}
          ></cometchat-label>
        </>
      );
    },
    [theme]
  );

  const getBubbleHeader = useCallback(
    (item: CometChat.BaseMessage) => {
      if (getHeaderView(item)) {
        return getHeaderView(item);
      } else {
        if (
          item?.getCategory() !==
            CometChatUIKitConstants.MessageCategory.action &&
          item?.getCategory() !== CometChatUIKitConstants.MessageCategory.call
        ) {
          if (
            !showHeaderTitle(item) &&
            timestampAlignment !== timestampEnum.top
          ) {
            return null;
          } else {
            return (
              <>
                {showHeaderTitle(item) ? getBubbleHeaderTitle(item) : null}
                {timestampAlignment === timestampEnum.top
                  ? getBubbleHeaderDate(item)
                  : null}
              </>
            );
          }
        }
      }

      return null;
    },
    [
      timestampAlignment,
      getBubbleHeaderDate,
      showHeaderTitle,
      getHeaderView,
      getBubbleHeaderTitle,
      timestampEnum.top,
    ]
  );

  const getBubbleFooterDate = useCallback(
    (item: CometChat.BaseMessage) => {
      if (
        timestampAlignment === timestampEnum.bottom &&
        item?.getCategory() !==
          CometChatUIKitConstants.MessageCategory.action &&
        item?.getCategory() !== CometChatUIKitConstants.MessageCategory.call
      ) {
        return (
          // <div className="cc__messagelist__bubbledate">
          <cometchat-date
            timestamp={item.getSentAt()}
            dateStyle={JSON.stringify(
              MessageBubbleDateStyle(messageListStyle, theme)
            )}
            pattern={datePattern}
          ></cometchat-date>
          // </div>
        );
      } else {
        return null;
      }
    },
    [
      timestampAlignment,
      messageListStyle,
      datePattern,
      theme,
      timestampEnum.bottom,
    ]
  );

  const getBubbleFooterReceipt = useCallback(
    (item: CometChat.BaseMessage) => {
      if (
        !disableReceipt &&
        (!item?.getSender() ||
          loggedInUser?.getUid() === item?.getSender()?.getUid()) &&
        item?.getCategory() !==
          CometChatUIKitConstants.MessageCategory.action &&
        item?.getCategory() !== CometChatUIKitConstants.MessageCategory.call
      ) {
        return (
          // <div className="cc__messagelist__receipt">
          <cometchat-receipt
            receiptStyle={JSON.stringify(MessageReceiptStyle(theme))}
            receipt={getMessageReceipt(item)}
            waitIcon={waitIcon}
            sentIcon={sentIcon}
            deliveredIcon={deliveredIcon}
            readIcon={readIcon}
            errorIcon={errorIcon}
          ></cometchat-receipt>
          // </div>
        );
      } else {
        return null;
      }
    },
    [
      disableReceipt,
      waitIcon,
      sentIcon,
      deliveredIcon,
      readIcon,
      errorIcon,
      getMessageReceipt,
      loggedInUser,
      theme,
    ]
  );

  const getBubbleFooterView = useCallback(
    (item: any) => {
      if (getFooterView(item)) {
        return (
          <div
            className="cc-message-bubble__footer"
            style={messageFooterViewStyle(setBubbleAlignment(item))}
          >
            {getFooterView(item)}
          </div>
        );
      } else {
        return (
          <div
            className="cc-message-bubble__footer"
            style={messageFooterViewStyle(setBubbleAlignment(item))}
          >
            {getBubbleFooterDate(item)}
            {getBubbleFooterReceipt(item)}
          </div>
        );
      }
    },
    [
      getBubbleFooterDate,
      getBubbleFooterReceipt,
      getFooterView,
      setBubbleAlignment,
    ]
  );

  const getBubbleThreadView = useCallback(
    (item: CometChat.BaseMessage) => {
      if (item?.getReplyCount() && !item?.getDeletedAt()) {
        return (
          <div className="cc-message-bubble__thread-replies">
            <cometchat-divider
              dividerStyle={JSON.stringify(dividerStyle(theme))}
            ></cometchat-divider>
            <cometchat-icon-button
              text={getThreadCount(item)}
              hoverText={getThreadCount(item)}
              buttonStyle={JSON.stringify(
                MessageThreadViewStyle(item, theme, loggedInUser)
              )}
              iconURL={RightArrowIcon}
              onClick={() => {
                openThreadView(item);
              }}
            ></cometchat-icon-button>
          </div>
        );
      }
    },
    [openThreadView, loggedInUser, theme]
  );

  const getMessageBubbleItem = useCallback(
    (item: CometChat.BaseMessage, i: any) => {
      return (
        <CometChatMessageBubble
          setRef={(ref) => { itemRefs.current[item.getId()] = ref}}
          leadingView={getBubbleLeadingView(item)}
          headerView={getBubbleHeader(item)}
          footerView={getBubbleFooterView(item)}
          contentView={getContentView(item)}
          bottomView={getBottomView(item)}
          id={item?.getId() || item?.getMuid()}
          options={setMessageOptions(item)}
          messageBubbleStyle={MessageBubbleStyle(
            item,
            theme,
            alignment,
            loggedInUser
          )}
          alignment={setBubbleAlignment(item)}
          replyView={null}
          threadView={getBubbleThreadView(item)}
        ></CometChatMessageBubble>
      );
    },
    [
      getBubbleLeadingView,
      getBubbleHeader,
      getBubbleFooterView,
      getContentView,
      alignment,
      setBubbleAlignment,
      getBubbleThreadView,
      setMessageOptions,
      loggedInUser,
      getBottomView,
      theme,
    ]
  );

  const getMessageBubbleDate = useCallback(
    (item: CometChat.BaseMessage, i: number) => {
      if (i === 0) {
        return (
          // <div className="cc-message-list__bubble" key={`${item.getId()}-${item.getSentAt()}`}>
          <div
            className="cc-message-list__date-container"
            key={`${item.getId()}-${item.getSentAt()}`}
            style={DateBubbleStyle()}
          >
            {/* <span className="cc__messagelist__date"> */}
            <cometchat-date
              timestamp={item.getSentAt()}
              pattern={DateSeparatorPattern}
              dateStyle={JSON.stringify(
                MessageDateStyle(dateSeparatorStyle, theme)
              )}
            ></cometchat-date>
            {/* </span> */}
          </div>
          // </div>
        );
      } else {
        if (
          isDateDifferent(messageList[i - 1]?.getSentAt(), item?.getSentAt())
        ) {
          return (
            // <div className="cc-message-list__bubble" key={`${item.getId()}-${item.getSentAt()}`}>
            <div
              className="cc-message-list__date-container"
              key={`${item.getId()}-${item.getSentAt()}`}
              style={DateBubbleStyle()}
            >
              {/* <span className="cc__messagelist__date"> */}
              <cometchat-date
                timestamp={item.getSentAt()}
                pattern={DateSeparatorPattern}
                dateStyle={JSON.stringify(
                  MessageDateStyle(dateSeparatorStyle, theme)
                )}
              ></cometchat-date>
              {/* </span> */}
            </div>
            // </div>
          );
        } else {
          return null;
        }
      }
    },
    [
      DateSeparatorPattern,
      messageList,
      dateSeparatorStyle,
      isDateDifferent,
      theme,
    ]
  );

  const getMessageBubble = useCallback(
    (m: CometChat.BaseMessage, i: any) => {
      let _alignment = setBubbleAlignment(m);

      return (
        <>
          {getMessageBubbleDate(m, i)}
          <div
            className="cc-message-list__bubble"
            key={m.getId()}
            style={bubbleStyle(_alignment)}
            id="juhi"
          >
            {getBubbleWrapper(m)
              ? getBubbleWrapper(m)
              : getMessageBubbleItem(m, i)}
          </div>
        </>
      );
    },
    [
      getBubbleWrapper,
      getMessageBubbleDate,
      getMessageBubbleItem,
      setBubbleAlignment,
    ]
  );

  const getMessageBubbleViewItem = useCallback(
    (item: CometChat.BaseMessage, i: any) => {
      return (
        <CometChatMessageBubble
          leadingView={getBubbleLeadingView(item)}
          headerView={getBubbleHeader(item)}
          footerView={null}
          contentView={getContentView(item)}
          bottomView={null}
          id={item?.getId() || item?.getMuid()}
          options={[]}
          messageBubbleStyle={MessageBubbleStyle(
            item,
            theme,
            alignment,
            loggedInUser
          )}
          alignment={setBubbleAlignment(item)}
          replyView={null}
          threadView={null}
        ></CometChatMessageBubble>
      );
    },
    [
      getBubbleLeadingView,
      getBubbleHeader,
      getContentView,
      alignment,
      setBubbleAlignment,
      loggedInUser,
      theme,
    ]
  );

  const getBubbleView = useCallback(
    (m: CometChat.BaseMessage, i: any) => {
      return (
        <div
          className="cc__messagelist__bubble"
          key={m.getId()}
          style={defaultMessageListBubbleStyle}
        >
          {getBubbleWrapper(m)
            ? getBubbleWrapper(m)
            : getMessageBubbleViewItem(m, i)}
        </div>
      );
    },
    [
      getBubbleWrapper,
      getMessageBubbleDate,
      getMessageBubbleItem,
      getMessageBubbleViewItem,
    ]
  );

  const getMessageListFooter = useCallback(() => {
    return (
      <div>
        {smartReplyViewRef.current ? smartReplyViewRef.current : null}
        {footerView && !smartReplyViewRef.current ? footerView : null}
      </div>
    );
  }, [
    smartReplyViewRef.current,
    footerView,
    unreadMessageLabel,
    scrollToBottom,
  ]);

  const getThreadCount = (message: CometChat.BaseMessage) => {
    const replyCount = message?.getReplyCount() || 0;
    const suffix = replyCount === 1 ? localize("REPLY") : localize("REPLIES");
    return `${localize("VIEW")} ${replyCount} ${suffix}`;
  };

  const getThreadedMessageBubble = useCallback(
    (item: CometChat.BaseMessage) => {
      return (
        <>
          {getBubbleWrapper(item) ? (
            getBubbleWrapper(item)
          ) : (
            <CometChatMessageBubble
              leadingView={getBubbleLeadingView(item)}
              headerView={getBubbleHeader(item)}
              footerView={getBubbleFooterView(item)}
              contentView={getContentView(item)}
              bottomView={getBottomView(item)}
              id={item?.getId() || item?.getMuid()}
              messageBubbleStyle={MessageBubbleStyle(
                item,
                theme,
                alignment,
                loggedInUser
              )}
              alignment={threadedAlignment}
              replyView={null}
              threadView={null}
              options={[]}
            ></CometChatMessageBubble>
          )}
        </>
      );
    },
    [
      getBubbleLeadingView,
      getBubbleHeader,
      getBubbleFooterView,
      getContentView,
      alignment,
      setMessageOptions,
      getBubbleWrapper,
      loggedInUser,
      getBottomView,
      theme,
      threadedAlignment,
    ]
  );

  const getListItem = useMemo(() => {
    return function (message: CometChat.BaseMessage, index: number): any {
      return getMessageBubble(message, index);
    };
  }, [getMessageBubble]);

  const getCurrentMessageListState = useCallback(() => {
    return messageListState === States.loaded && messagesCountRef.current === 0
      ? States.loaded
      : messageListState;
  }, [messageListState, messagesCountRef]);

  const hideMessageInformation = () => {
    setShowMessageInfoPopup(false);
  };

  const getMessageTemplate = (selectedMessage: CometChat.BaseMessage) => {
    return messagesTypesMap[
      `${selectedMessage?.getCategory() + "_" + selectedMessage?.getType()}`
    ];
  };

  return (
    <>
      <div
        className="cc-message-list__wrapper"
        style={MessageListWrapperStyle(messageListStyle, theme)}
      >
        <div
          className="cc-message-list__header-view"
          style={MessageListHeaderStyle()}
        >
          {headerView ? headerView : null}
        </div>
        <div className="cc-message-list" style={MessageListDivStyle()}>
          <CometChatList
            scrolledUpCallback={scrolledUpCallback}
            title={""}
            hideSearch={true}
            showSectionHeader={false}
            list={messageList}
            listItem={getListItem}
            onScrolledToBottom={onBottomCallback}
            onScrolledToTop={onTopCallback}
            listItemKey="getMuid"
            state={getCurrentMessageListState()}
            loadingView={getLoaderHtml}
            loadingIconURL={loadingIconURL}
            hideError={hideError}
            errorStateView={getErrorHtml}
            errorStateText={errorStateText}
            emptyStateView={getEmptyHtml}
            emptyStateText={emptyStateText}
            scrollToBottom={scrollListToBottom}
            theme={theme}
            listStyle={getListStyle()}
          />
          {unreadMessageLabelRef.current &&
          UnreadCountRef.current &&
          UnreadCountRef.current.length > 0 &&
          !isOnBottom.current ? (
            <div style={MessageListUnreadLabelStyle()}>
              <div
                className="cc-message-list__message-indicator"
                style={MessageListMessageIndicatorStyle()}
              >
                <cometchat-button
                  ref={setUnreadCountBtnRef}
                  text={newMessageCountRef.current}
                  buttonStyle={JSON.stringify(unreadMessageStyle(theme))}
                  onClick={scrollToBottom}
                ></cometchat-button>
              </div>
            </div>
          ) : null}
        </div>

        <div
          className="cc-message-list__footer-view"
          style={MessageListFooterStyle()}
        >
          {getMessageListFooter()}
        </div>
      </div>

      {showOngoingCall ? ongoingCallView : null}
      {showConfirmDialog && imageModerationDialogRef.current
        ? imageModerationDialogRef.current
        : null}
      {showMessageInfoPopup && activeMessageInfo !== null && (
        <CometChatMessageInformation
          message={activeMessageInfo}
          bubbleView={
            messageInformationConfiguration?.bubbleView || getBubbleView
          }
          onClose={
            messageInformationConfiguration?.onClose || hideMessageInformation
          }
          template={getMessageTemplate(activeMessageInfo)}
          loadingStateView={messageInformationConfiguration?.loadingStateView}
          errorStateView={messageInformationConfiguration?.errorStateView}
          listItemStyle={messageInformationConfiguration?.listItemStyle}
          emptyStateView={messageInformationConfiguration?.emptyStateView}
          loadingIconURL={messageInformationConfiguration?.loadingIconURL}
          readIcon={messageInformationConfiguration?.readIcon}
          deliveredIcon={messageInformationConfiguration?.deliveredIcon}
          onError={messageInformationConfiguration?.onError}
          subtitleView={messageInformationConfiguration?.subtitleView}
          listItemView={messageInformationConfiguration?.listItemView}
          messageInformationStyle={
            messageInformationConfiguration?.messageInformationStyle
          }
          closeIconURL={
            messageInformationConfiguration?.closeIconURL || CloseIcon
          }
          receiptDatePattern={
            messageInformationConfiguration?.receiptDatePattern
          }
        />
      )}
    </>
  );
};

CometChatMessageList.defaultProps = defaultProps;
export { CometChatMessageList };
