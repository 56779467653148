import { CometChatTheme } from "@cometchat/uikit-resources";
import { ChatConfigurator } from "../../Shared/Framework/ChatConfigurator";
import { ExtensionsDataSource } from "../../Shared/Framework/ExtensionsDataSource";
import { ReactionConfiguration } from "./ReactionConfiguration";
import { ReactionExtensionDecorator } from "./ReactionExtensionDecorator";
import { ExtensionsId } from "@cometchat/uikit-shared";

export class ReactionExtension extends ExtensionsDataSource {
    private configuration?: ReactionConfiguration;
    private theme?: CometChatTheme;

  constructor(configuration?: ReactionConfiguration) {
    super();
    this.configuration = configuration;
  }

  override addExtension(): void {
    ChatConfigurator.enable(
      (dataSource: any) => new ReactionExtensionDecorator(dataSource, this.configuration, this.theme)
    );
  }

  override getExtensionId(): string {
    return ExtensionsId.reactions;
  }
}