import { Action, ActionOnClickType, ContentToDisplay } from ".";
import { CometChatMessageEvents, CometChatUIEvents, IMessages, IModal, MessageStatus } from "@cometchat/uikit-resources";
import React, { JSX, useEffect } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";

type Args = {
    dispatch : React.Dispatch<Action>,
    messageInputRef : React.MutableRefObject<JSX.IntrinsicElements["cometchat-message-input"] | null>,
    liveReactionBtnElement : JSX.IntrinsicElements["cometchat-button"] | null,
    LiveReactionIconURL : string,
    mySetAddToMsgInputText : (text : string) => void,
    actionSheetElement : JSX.IntrinsicElements['cometchat-action-sheet'] | null,
    mediaFilePickerRef : React.MutableRefObject<HTMLInputElement | null>,
    secondaryBtnElement : JSX.IntrinsicElements["cometchat-button"] | null,
    textMessageEditPreviewElement : JSX.IntrinsicElements["cometchat-preview"] | null,
    auxiliaryBtnElement : JSX.IntrinsicElements["cometchat-button"] | null,
    voiceRecordingBtnElement : JSX.IntrinsicElements["cometchat-button"] | null,
    emojiKeyboardElement : JSX.IntrinsicElements["cometchat-emoji-keyboard"] | null,
    voiceRecordingElement : JSX.IntrinsicElements["cometchat-emoji-keyboard"] | null,
    auxiliaryPopoverElement : JSX.IntrinsicElements["cometchat-popover"] | null,
    attachmentPopoverElement : JSX.IntrinsicElements["cometchat-popover"] | null,
    text : string,
    handleSendButtonClick : (text: string) => Promise<void>,
    primaryBtnElement : JSX.IntrinsicElements["cometchat-button"] | null,
    onTextChange? : (text : string) => void,
    actionIdToActionOnClick : React.MutableRefObject<Map<string, ActionOnClickType>>,
    handleTyping : () => void,
    errorHandler : (error : unknown) => void,
    getReceiverDetails : () => {receiverId : string, receiverType : string},
    contentToDisplay : ContentToDisplay,
    createPollViewRef : any,
    handleSendVoiceMessage : (blob : Blob) => Promise<void>
};

export function Hooks(args : Args) {
    const {
        dispatch,
        messageInputRef,
        liveReactionBtnElement,
        LiveReactionIconURL,
        mySetAddToMsgInputText,
        actionSheetElement,
        mediaFilePickerRef,
        secondaryBtnElement,
        textMessageEditPreviewElement,
        auxiliaryBtnElement,
        voiceRecordingBtnElement,
        emojiKeyboardElement,
        voiceRecordingElement,
        auxiliaryPopoverElement,
        attachmentPopoverElement,
        text,
        handleSendButtonClick,
        primaryBtnElement,
        onTextChange,
        actionIdToActionOnClick,
        handleTyping,
        errorHandler,
        getReceiverDetails,
        contentToDisplay,
        createPollViewRef,
        handleSendVoiceMessage
    } = args;

    useEffect(
        /**
         * Subscribes to message edited Message UI event
         */
        () => {
            const subMessageEdited = CometChatMessageEvents.ccMessageEdited.subscribe((object : IMessages) => {
                if (object.status === MessageStatus.inprogress && object.message instanceof CometChat.TextMessage) {
                    dispatch({type: "setTextMessageToEdit", textMessageToEdit: object.message});
                    messageInputRef.current?.emptyInputField();
                    mySetAddToMsgInputText(object.message.getText());
                }
            });
            const subComposeMessage = CometChatUIEvents.ccComposeMessage.subscribe((text:string)=>{
                mySetAddToMsgInputText(text)

            })
            return () => {
                subMessageEdited.unsubscribe();
                 subComposeMessage.unsubscribe(); 
            }
    }, [mySetAddToMsgInputText, dispatch, messageInputRef]);

    useEffect(
        /**
         * Add `cc-button-clicked` event listener to the live reaction button element
         */
        () => {
            if (!liveReactionBtnElement) {
                return;
            }
            async function handleEvent() {
                try {
                    const reactionURL = LiveReactionIconURL; 
                    const { receiverId, receiverType } = getReceiverDetails();
                    const data = {type: "reaction", reactionURL};
                    CometChat.sendTransientMessage(new CometChat.TransientMessage(receiverId, receiverType, data));
                    CometChatMessageEvents.ccLiveReaction.next(reactionURL);
                }
                catch(error) {
                    errorHandler(error);
                }
            }
            const eventName = "cc-button-clicked";
            liveReactionBtnElement.addEventListener(eventName, handleEvent);
            return () => {
                liveReactionBtnElement.removeEventListener(eventName, handleEvent);
            };
    }, [LiveReactionIconURL, liveReactionBtnElement, errorHandler, getReceiverDetails]);

    useEffect(
        /**
         * Add `cc-actionsheet-clicked` event listener to the action sheet element
         */
        () => {
            if (!actionSheetElement || !mediaFilePickerRef.current) {
                return;
            }
            function handleEvent(e : CustomEvent) {
                const { action } = e.detail;
                // Hide the secondary content view
                secondaryBtnElement?.click();
                dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
                const actionOnClick = actionIdToActionOnClick.current.get(`${action.id}`); 
                if (typeof actionOnClick === "function") {
                    actionOnClick();
                }
                else {
                    // Open the correct file picker
                    mediaFilePickerRef.current!.accept = `${action.id}/*`;
                    mediaFilePickerRef.current!.click();
                }
            }
            const eventName = "cc-actionsheet-clicked";
            actionSheetElement.addEventListener(eventName, handleEvent);
            return () => {
                actionSheetElement.removeEventListener(eventName, handleEvent);
            };
    }, [secondaryBtnElement, actionSheetElement, dispatch, actionIdToActionOnClick, mediaFilePickerRef]);

    useEffect(
        /**
         * Add `cc-preview-close-clicked` event listener to the preview element
         */
        () => {
            if (!textMessageEditPreviewElement) {
                return;
            }
            function onPreviewCloseClick() {
                dispatch({type: "setTextMessageToEdit", textMessageToEdit: null});
                // Empty the text in the message composer
                dispatch({type: "setText", text: ""}); 
                messageInputRef.current?.emptyInputField();
            }
            const eventName = "cc-preview-close-clicked";
            textMessageEditPreviewElement.addEventListener(eventName, onPreviewCloseClick);
            return () => {
                textMessageEditPreviewElement.removeEventListener(eventName, onPreviewCloseClick);
            };
    }, [textMessageEditPreviewElement, dispatch, messageInputRef]);

    useEffect(
        /**
         * Add `cc-button-clicked` event listener to the secondary button element
         */
        () => {
            if (!secondaryBtnElement) {
                return;
            }
            function onSecondaryBtnClick() {
                switch(contentToDisplay) {
                    case "attachments":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
                        break;
                    case "emojiKeyboard":
                        auxiliaryBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "attachments"});
                        break;
                    case "voiceRecording":
                        voiceRecordingBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "attachments"});
                        break;
                    case "none":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "attachments"});
                        break;
                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const x : never = contentToDisplay;
                    }
                }
            }

            function onAttachmentPopoverOutsideClick() {
                dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
            }

            const eventName = "cc-button-clicked";
            const outsideClickEventName = "cc-popover-outside-clicked";
            secondaryBtnElement.addEventListener(eventName, onSecondaryBtnClick);
            attachmentPopoverElement.addEventListener(outsideClickEventName, onAttachmentPopoverOutsideClick);
            return () => {
                secondaryBtnElement.removeEventListener(eventName, onSecondaryBtnClick);
                attachmentPopoverElement.removeEventListener(outsideClickEventName, onAttachmentPopoverOutsideClick);
            };
    }, [contentToDisplay, secondaryBtnElement,attachmentPopoverElement, auxiliaryBtnElement, voiceRecordingBtnElement, dispatch]);

    useEffect(
        /**
         * Add `cc-button-clicked` event listener to the auxiliary button element
         */
        () => {
            if (!auxiliaryBtnElement || !voiceRecordingBtnElement) {
                return;
            }
            function onAuxiliaryBtnClick() {
                switch(contentToDisplay) {
                    case "attachments":
                        secondaryBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "emojiKeyboard"});
                        break;
                    case "voiceRecording":
                        voiceRecordingBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "emojiKeyboard"});
                        break;
                    case "emojiKeyboard":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
                        break;
                    case "none":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "emojiKeyboard"});
                        break;
                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const x : never = contentToDisplay;
                    }
                }
            }
            function onVoiceRecordingBtnClick() {                
                switch(contentToDisplay) {
                    case "attachments":
                        secondaryBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "voiceRecording"});
                        break;
                    case "emojiKeyboard":
                        auxiliaryBtnElement?.click();
                        dispatch({type: "setContentToDisplay", contentToDisplay: "voiceRecording"});
                        break;
                    case "voiceRecording":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
                        break;
                    case "none":
                        dispatch({type: "setContentToDisplay", contentToDisplay: "voiceRecording"});
                        break;
                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const x : never = contentToDisplay;
                    }
                }
            }
            const eventName = "cc-button-clicked";
            auxiliaryBtnElement.addEventListener(eventName, onAuxiliaryBtnClick);
            voiceRecordingBtnElement.addEventListener(eventName, onVoiceRecordingBtnClick);
            return () => {
                auxiliaryBtnElement.removeEventListener(eventName, onAuxiliaryBtnClick);
                voiceRecordingBtnElement.removeEventListener(eventName, onVoiceRecordingBtnClick);
            };
    }, [contentToDisplay, secondaryBtnElement, voiceRecordingBtnElement ,auxiliaryBtnElement, dispatch]);

    useEffect(
        /**
         * Add `cc-emoji-clicked` event listener to the emoji keyboard element
         */
        () => {
            if (!emojiKeyboardElement) {
                return;
            }
            function onEmojiClicked(e : CustomEvent) {
                const emoji = e.detail.id;
                if (typeof emoji === "string")
                    mySetAddToMsgInputText(emoji);
            }

            function onEmojiKeyboardClose(e: CustomEvent) {                
                dispatch({type: "setContentToDisplay", contentToDisplay: "none"});
            }

            const eventName = "cc-emoji-clicked";
            const outsideClickEventName = "cc-popover-outside-clicked";
            emojiKeyboardElement.addEventListener(eventName, onEmojiClicked);
            auxiliaryPopoverElement.addEventListener(outsideClickEventName, onEmojiKeyboardClose);
            return () => {
                emojiKeyboardElement.removeEventListener(eventName, onEmojiClicked);
                auxiliaryPopoverElement.removeEventListener(outsideClickEventName, onEmojiKeyboardClose);
            };
    }, [mySetAddToMsgInputText,auxiliaryPopoverElement, emojiKeyboardElement, dispatch]);

    useEffect(
        /**
         * Add `cc-media-recorder-closed` event listener to the media recorder element
         * Add `cc-media-recorder-submitted` event listener to the media recorder element
         */
        () => {
            if (!voiceRecordingElement) {
                return;
            }
            function onCloseTriggered(e: CustomEvent) {                
                const customEvent = new CustomEvent("cc-button-clicked", {});
                voiceRecordingBtnElement?.click();
                voiceRecordingBtnElement?.dispatchEvent(customEvent);
            }
            function sendVoiceMessage($event: CustomEvent) {
                const blob = $event.detail.file;
                onCloseTriggered($event);
                handleSendVoiceMessage(blob);
            }
            const closeEvent = "cc-media-recorder-closed";
            const submitEvent = "cc-media-recorder-submitted";
            voiceRecordingElement.addEventListener(closeEvent, onCloseTriggered);
            voiceRecordingElement.addEventListener(submitEvent, sendVoiceMessage);            
            return () => {
                voiceRecordingElement.removeEventListener(
                    closeEvent,
                    onCloseTriggered
                );
                voiceRecordingElement.removeEventListener(
                    submitEvent,
                    sendVoiceMessage
                );
            };
        },
        [
            voiceRecordingElement,
            voiceRecordingBtnElement,
            dispatch,
            handleSendVoiceMessage,
        ]
    );

    useEffect(
        /**
         * Add `cc-button-clicked` event listener to the primary button element
         */
        () => {
            if (!primaryBtnElement) {
                return;
            }
            function handleEvent() {
                handleSendButtonClick(text);
            }
            const eventName = "cc-button-clicked";
            primaryBtnElement.addEventListener(eventName, handleEvent);
            return () => {
                primaryBtnElement.removeEventListener(eventName, handleEvent);
            };
    }, [handleSendButtonClick, text, primaryBtnElement]);

    useEffect(
        /**
         * Add `cc-message-input-entered` event listener to the message input element
         */
        () => {
            const messageInputElement = messageInputRef.current;
            if (!messageInputElement) {
                return;
            }
            function onMessageInputEnter(e : CustomEvent) {
                const textToSend = e.detail.value;
                if (typeof textToSend === "string") 
                    handleSendButtonClick(textToSend);
            }
            const eventName = "cc-message-input-entered";
            messageInputElement.addEventListener(eventName, onMessageInputEnter);
            return () => {
                messageInputElement.removeEventListener(eventName, onMessageInputEnter);
            };
    }, [handleSendButtonClick, messageInputRef]);

    useEffect(
        /**
         * Add `cc-message-input-changed` event listener to the message input element
         */
        () => {
            const messageInputElement = messageInputRef.current;
            if (!messageInputElement) {
                return;
            }
            function onMessageInputChange(e : CustomEvent) {
                const newText = e.detail.value;
                if (typeof newText === "string") {
                    handleTyping();
                    dispatch({type: "setText", text: newText});
                    if (onTextChange !== undefined)
                        onTextChange(newText);
                }
            }
            const eventName = "cc-message-input-changed";
            messageInputElement.addEventListener(eventName, onMessageInputChange);
            return () => {
                messageInputElement.removeEventListener(eventName, onMessageInputChange);
            };
    }, [onTextChange, handleTyping, dispatch, messageInputRef]);

    useEffect(
        /**
         * Subscribes to showModal & hideModal UI event to show & hide the Polls UI.
         */
        () => {
            const subShowModal = CometChatUIEvents.ccShowModal.subscribe((data: IModal) => {
                dispatch({type: "setShowPoll", showPoll: true});
                createPollViewRef.current = data.child;
            });

            const subHideModal = CometChatUIEvents.ccHideModal.subscribe(() => {
                dispatch({type: "setShowPoll", showPoll: false});
                createPollViewRef.current = null;

            });
            return () => {
                subShowModal.unsubscribe();
                subHideModal.unsubscribe();
            }
    }, [createPollViewRef, dispatch]);
}
