import { CometChatTheme, fontHelper, TabsVisibility } from "@cometchat/uikit-resources";
import { ContactsStyle, TabItemStyle } from "@cometchat/uikit-shared";

import { CSSProperties } from "react";
import { TabsStyle } from "../Shared/Views/CometChatTabs/TabsStyle";

type ButtonStyle = CSSProperties & {buttonIconTint? : string};
type HeaderStyle = CSSProperties
type WrapperStyle = CSSProperties
type ContentStyle = CSSProperties

export function closeBtnStyle(contactsStyle: ContactsStyle, theme : CometChatTheme) : ButtonStyle {
    return {
        height: "24px",
        width: "24px",
        border: "none",
        borderRadius: "0",
        buttonIconTint: contactsStyle?.closeIconTint || theme.palette.getPrimary(),
        background: "transparent",
        position: "absolute",
        top: "8px",
        right: "12px"
    }; 
}

export function contactsHeaderStyle(contactsStyle: ContactsStyle, theme:CometChatTheme) : HeaderStyle {
    return {
        height: "30px",
        textAlign: "center", 
        font: contactsStyle?.titleTextFont || fontHelper(theme.typography.title1),
        padding: "10px 0px",
        color:  contactsStyle?.titleTextColor || theme.palette.getAccent()
    }; 
}

export function contactsWrapperStyle(contactsStyle: ContactsStyle, theme: CometChatTheme) : WrapperStyle {
    return {
        height: contactsStyle?.height || "100%",
        width: contactsStyle?.width || "100%",
        background: contactsStyle?.background || theme.palette.getBackground(),
        border: contactsStyle?.border || "none",
        borderRadius: contactsStyle?.borderRadius || "none",
        boxShadow: contactsStyle?.boxShadow || "0",
        padding: contactsStyle?.padding || "0",
        display: "flex"
    }; 
}

export function getContactsStyle(contactsStyle: ContactsStyle, theme: CometChatTheme) : CSSProperties {
    return {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
        //margin: "0 auto", 
        //height:"100%"
    }; 
}

export function contactsContentStyle() : ContentStyle {
    return {
        height: "calc(100% - 50px)",
    }; 
}

export function getTabsStyle() {
    return {
        height: "100%",
        width: "100%",
        tabListHeight: "30px",
        tabListBackground: "#ececec",
        tabListPadding: "2px 2px",
        tabListBorderRadius: "8px",
        tabPaneWidth: "100%",
        tabPaneHeight: "calc(100% - 34px)"
    } as TabsStyle
}

export const getTabItemStyling = (theme: CometChatTheme, tabVisibility: TabsVisibility | undefined, isActive: boolean) => {
    
    return {
        width: tabVisibility === TabsVisibility.usersAndGroups ? "50%" : "100%",
        height: "100%",
        borderRadius: "0",
        border: "none",
        titleTextFont: fontHelper(theme.typography.text2),
        titleTextColor: theme.palette.getAccent(),
        activeTitleTextFont: fontHelper(theme.typography.text2),
        activeTitleTextColor: theme.palette.getAccent(),
        activeBackground: theme.palette.getBackground(),
        activeBorderRadius: "8px",
        background: "#ececec",
        boxShadow: "0px 3px 8px rgba(20, 20, 20, 0.12), 0 3px 1px rgba(20, 20, 20, 0.04)"
    } as TabItemStyle
} 